import { Badge, Button, Dropdown, Flex } from "antd";
import NavCommunity from "./NavCommunity";
import { UserOutlined,ShoppingCartOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import { useStore } from "../../../root-store-context";
import { Link } from "react-router-dom";
import { RouteElement } from "../../../stores/appStores/ApplicationStore";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

const NavBar: React.FC =  observer(() => {
  const auth = useAuth();
  const location = useLocation();
  const { applicationStore,apiStore } = useStore();
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint({ mobile: 0, desktop: 992 });

  const onLogOut = () => {
    const args = {
      extraQueryParams: {
        post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI as string,
      },
    };
    void auth.signoutRedirect(args);
  };

  const items: MenuProps["items"] = [
    // {
    //   onClick: ()=>{navigate("/eStatements")},
    //   label: <span style={{ padding: "0px 16px" }}>Electronic Communication</span>,
    //   key: "1",
    // },
    // {
    //   onClick: ()=>{navigate("/ContactInfoChange")},
    //   label: <span style={{ padding: "0px 16px" }}>Contact Info Change</span>,
    //   key: "2",
    // },
    // {
    //   onClick: ()=>{navigate("/AccountContacts")},
    //   label: <span style={{ padding: "0px 16px" }}>Account Contacts</span>,
    //   key: "3",
    // },
    {
      onClick: ()=>{
        window.open(`${process.env.REACT_APP_IDP}profile/updatepassword?redurectUrl=${window.location.href}`,"_self")
      },
      label: <span style={{ padding: "0px 16px" }}>Change Password</span>,
      key: "4",
    },
    // {
    //   onClick: ()=>{
    //     window.open(`${process.env.REACT_APP_IDP}profile/changeemail?redurectUrl=${window.location.href}`,"_self")
    //   },
    //   label: <span style={{ padding: "0px 16px" }}>Change Email</span>,
    //   key: "3",
    // },
    {
      onClick: onLogOut,
      label: <span style={{ padding: "0px 16px" }}>Log Out</span>,
      key: "5",
    },

  ];
useEffect(()=>{
  FetchCount();
},[])
useEffect(()=>{
  if(applicationStore?.CardItemPayment){
    FetchCount();
    applicationStore.setCardItemPayment(false)
  }
},[applicationStore?.CardItemPayment])
const FetchCount = ()=>{
  let residentKey=apiStore?.configApi?.community?.residentKey?.toString();

  // apiStore.FormsApiClient.GetCountAddToCartArch([residentKey])
  // .then((res) => {
  //   let count = res?.data;
  //   if(res?.status == 200){
  //     applicationStore.setCount(count?.totalCount)
  //   }
  //   console.log(res, "GetArchForm");
  // })
  // .catch(() => console.log("Error in GetArchForm"));
}
  return (
    <Flex
      justify="space-between"
      align="center"
      gap="large"
      style={{ height: "100%", zIndex: "1" }}
    >
      <NavCommunity />
      {breakpoint !== "mobile" && (
          <b
            style={{
              color: "#5a7890",
              fontSize: 18,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              minWidth: 300,
              display: "flex",
              justifyContent: "flex-end",
              maxWidth: 400,
              flex: "0 1 400px"
            }}
          >
            <Link
              to={"https://www.associatedasset.com/"}
              style={{ marginLeft: 10, fontWeight: 700, display: "flex", fontSize: "14px" }}
            >
              Return to associatedasset.com
            </Link>
          </b>
      )}
  
      <Dropdown
        trigger={["click"]}
        placement="bottomLeft"
        arrow={{ pointAtCenter: true }}
        menu={{ items: items }}
      >
        <UserOutlined
          style={{
            fontSize: "20px",
            background: "rgba(242, 246, 249, 1)",
            padding: 10,
            borderRadius: "50%",
          }}
        />
      </Dropdown>
    </Flex>
  );
});

export default NavBar;
