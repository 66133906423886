import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ReactDraftWysiwyg = forwardRef(({ value, setForm, formInfo, edit }, ref) => {
  const [editorState, setEditorState] = useState(() => {
    if (edit && formInfo?.templateId) {
      const contentBlock = htmlToDraft(formInfo?.emailText || "");
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    } else {
      return EditorState.createEmpty();
    }
  });

  useEffect(() => {
    console.log(formInfo,"formInfo")
    // if (edit && formInfo?.templateId) {
    //   const contentBlock = htmlToDraft(formInfo?.emailText || "");
    //   const contentState = ContentState.createFromBlockArray(
    //     contentBlock.contentBlocks);
    //   setEditorState(EditorState.createWithContent(contentState));
    // } 
    // else {
    //   // If formInfo is empty, set an empty editor state
    //   setEditorState(EditorState.createEmpty());
    // }
    if(formInfo== undefined ||formInfo?.emailText==undefined){
      setEditorState(EditorState.createEmpty());
    }
  }, [edit, formInfo?.templateId, formInfo?.emailText]);

  const onEditorStateChange = (editor) => {
    setEditorState(editor);

    const rawContentState = convertToRaw(editor.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    setForm({ ...formInfo, emailText: markup });
  };

  useImperativeHandle(ref, () => ({
    resetEditorState() {
      // Allow parent to reset editor state manually
      setEditorState(EditorState.createEmpty());
    }
  }));
  // const toolbarOptions = {
  //   options: ["inline", "list"], 
  //   inline: {
  //     options: ["bold", "italic", "underline","strikethrough"], 
  //   },
  //   list: {
  //     options: ["unordered", "ordered"], 
  //   },
  // };
  const toolbarOptions = {
    options: [] 
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbar={toolbarOptions}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
    />
  );
});

export default ReactDraftWysiwyg;