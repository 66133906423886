import { useEffect, useState, useRef, useMemo } from "react";
import Swal from "sweetalert2";
import { Tag, Popover, Button, Form, Tooltip } from "antd";
import TextInput from "../../../components/TextInput/TextInput";
import TextAreaInput from "../../../components/TextArea/TextArea";
import UploadButton from "../../../components/UploadButton/RectangleUploadButton";
import Modal from "../../../components/Modal/Modal"
import { useNavigate } from "react-router-dom";
import { ReactComponent as ViewIcon } from "../../../svg/ViewIcon.svg";
import { useStore } from "../../../root-store-context";
import { observer } from "mobx-react-lite";
import { formatDate } from "../../../Common";
import SideBar from "../../../components/sideBar/Sidebar";
import Spinner from "../../../components/Spinner/Spiner";
import DynamicTable from '../../../components/Table/Table';
import EditFormSubmission from "./ViewForm";
import Validation from "../../../utils/Validation";

const ArchitecturalRequestList = observer(() => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { apiStore, applicationStore } = useStore();
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const formRef = useRef(null);
    const [viewFormData, setViewFormData] = useState();
    const [formDetail, setFormDetail] = useState();
    const [copyopen, setCopyOpen] = useState(false);
    const [showPaymentSection, setShowPaymentSection] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [fileList, setFileList] = useState([]);

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 25,
        total: 0,
    });
    const debounceTimer = useRef(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [appealOpen, setAppealOpen] = useState(false);
    const [selectedSubmissionDetail, setSelectedSubmissionDetail] = useState({});
    const [appealDetails, setAppealDetails] = useState({});
    const validationRules = Validation.getValidation([
        "reason",
        "dayTimePhone"
    ]);
    let residents = {
        103096: "145011",
        1200782: "5012102",
        112192: "155269",
        1195639: "445445",
    };
    const getSubmissionById = (submissionId) => {
        apiStore.FormsApiClient.GetSubmissionDetails(submissionId)
            .then((res) => {
                let obj = {};
                if (res?.data) {
                    let requestDetails = res?.data;
                    obj.form = JSON.parse(res?.data?.formData);
                    var formData = JSON.parse(res?.data?.formData)
                    setFormDetail(formData);
                    setViewFormData(res?.data);
                }

            })
            .catch(() => console.log("error in GetArchForm"));
    };

    const handleAppeal = (data) => {
        setAppealOpen(true)
        setSelectedSubmissionDetail(data);
    }

    const renderAction = (user) => {
        return (
            <>
                <div className="d-flex">
                    <Popover placement="topLeft" title={"View Form"}>
                        <Button
                            onClick={() => handleView(user)}
                            className="icon-wrap"
                            shape={"circle"}
                            icon={
                                <ViewIcon />
                            }
                        ></Button>
                    </Popover>

                </div>
                {user.isAbleToAppeal &&
                    <Button onClick={() => handleAppeal(user)}>Appeal</Button>
                }

            </>
        );
    };
    const columns = useMemo(
        () => [
            {
                title: "Request Date",
                dataIndex: "submittedDate",
                render: (value) => formatDate(value),
                sorter: (a, b) => new Date(a.submittedDate) - new Date(b.submittedDate),
                defaultSortOrder: "descend",
                sortDirections: ['ascend', 'descend', 'ascend'],
            },
            {
                title: "Request Number",
                dataIndex: "requestNumber",
            },
            { title: "User Name", dataIndex: "userName" },
            { title: "Submitted By", dataIndex: "submittedUser" },
            {
                title: "Status",
                dataIndex: "submissionStatus",
                filters: [
                    { text: "Pending", value: 0 },
                    { text: "Approved", value: 1 },
                    { text: "Rejected", value: 2 },

                ],
                onFilter: (value, record) => record.submissionStatus === value,
                render: (value) =>
                    value === 3 ? (
                        <Tag className="reopen-status">Re-open</Tag>
                    ) : value === 1 ? (
                        <Tag className="approved-status">Approved</Tag>
                    ) : value === 2 ? (
                        <Tag className="reject-status">Reject</Tag>
                    ) : value === 3 ? (
                        <Tag className="reopen-status">ReOpen</Tag>
                    ) : value === 4 ? (
                        <Tag className="reject-status">Re Request</Tag>
                    ) : value === 6 ? (
                        <Tag className="approved-with-stipulation-status">
                            Approved with stipulation
                        </Tag>
                    ) : value === 0 ? (
                        <Tag className="pending-status">Pending</Tag>
                    ) : value === 7 ? (
                        <Tag className="pending-status">Under Committee Review</Tag>
                    ) : value === 8 ? (
                        <Tag className="pending-status">Committee Review Completed</Tag>
                    ) : value === 9 ? (
                        <Tag className="pending-status">Appealed</Tag>
                    ) : value === 10 ? (
                        <Tag className="pending-status">Under Committee Review</Tag>
                    ): (
                        <Tag color="error">On Hold</Tag>
                    ),
            },
            { title: "Action By", dataIndex: "actionBy" },
            {
                title: "Action Date",
                dataIndex: "actionDate",
                render: (value) => formatDate(value),
            },
            {
                title: "Status Notes",
                dataIndex: "reason",
                render: (text, record) => {
                    const displayText = record?.submissionStatus === 4 ? record?.reRequestReason : text;
                    const truncatedText = displayText?.length > 100 ? `${displayText?.slice(0, 100)}...` : displayText;

                    return (
                        <Tooltip
                            title={
                                <div style={{ maxWidth: 800 }}>
                                    {displayText}
                                </div>
                            }
                            overlayStyle={{ maxWidth: 800 }}
                        >
                            <span>{truncatedText}</span>
                        </Tooltip>
                    );
                },
                // render: (text, record) => {
                //     return record.submissionStatus === 4 ? record.reRequestReason : text;
                // },
            },
            {
                title: "Actions",
                dataIndex: "formName",
                render: (text, record) => renderAction(record),
            },
        ],
        [result]
    );
    const handleView = (user) => {
        navigate(`/AccountInfo/ArchitecturalRequestsNew/View/${user?.submissionId}`);
    };
    const EditOk = () => {
        setEditFormOpen(!editFormOpen);
    };
    const EditCancel = () => {
        setEditFormOpen(!editFormOpen);
    };
    const CopyOk = () => {
        setCopyOpen(!copyopen);
    };
    const CopyCancel = () => {
        setCopyOpen(!copyopen);
        setShowPaymentSection(false);
    };

    const EditForm = (pageName) => {
        return (
            <EditFormSubmission
                editFormOpen={editFormOpen}
                setEditFormOpen={setEditFormOpen}
                Edit={"edit"}
                GetForm={viewFormData}
                submissionId={viewFormData?.submissionId}
                components={
                    formDetail
                        ?.components
                }
                unitId={viewFormData?.unitId}
                setIsSuccess={setIsSuccess}
            />
        );
    };
    const CopyPermissionsFields = () => {
        return (
            <EditFormSubmission
                editFormOpen={copyopen}
                setEditFormOpen={setCopyOpen}
                Edit={"copy"}
                GetForm={viewFormData}
                submissionId={viewFormData?.submissionId}
                components={
                    formDetail
                        ?.components
                }
                showPaymentSection={showPaymentSection}
                setShowPaymentSection={setShowPaymentSection}
                unitId={formDetail?.unitId}
                setIsSuccess={setIsSuccess}
            />
        );
    };
    const fetchData = async (data) => {
        setLoading(true);
        const request = {
            residentKey: [apiStore?.configApi?.community?.residentKey?.toString()], // ["104046"] ,//
            pageNumber: JSON.stringify(data?.pageNumber),
            pageSize: JSON.stringify(data?.pageSize),
            searchKey: data?.searchTerm,
            startDate: null,
            endDate: null,
            submissionStatus: data?.submissionStatus,
            sortOrder: data?.order ?? "DESC"

        };
        try {
            const response = await apiStore.FormsApiClient.GetRequestList(JSON.stringify(request));

            setLoading(false);

            if (response?.data?.status) {
                setResult(response?.data?.data);
                applicationStore?.setRequestList(response?.data?.data)
                applicationStore.setPagination({
                    ...applicationStore.Pagination,
                    total: response?.data?.totalCount,
                })
                setPagination({
                    ...pagination,
                    total: response?.data?.totalCount,
                });
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Unable to create the request.",
                    icon: "error",
                    confirmButtonColor: "#44637e",
                });
            }
        } catch (error) {
            console.error("Error in GetArchForm:", error);
            Swal.fire({
                title: "Error",
                text: "An error occurred while fetching the data.",
                icon: "error",
                confirmButtonColor: "#44637e",
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        debugger
        if (applicationStore?.RequestList?.length <= 0) {
            let data = {
                pageNumber: 1,
                pageSize: 10, searchTerm: searchTerm, submissionStatus: applicationStore?.submissionStatusString
            }
            fetchData(data);
        }

    }, [applicationStore?.RequestList]);


    const handleTableChange = (pagination, filters, sorter) => {
        const { order, field } = sorter;
        const sortOrder = order === "ascend" ? "ASC" : order === "descend" ? "DESC" : null;
        const submissionStatusString = filters?.submissionStatus?.join(",");
        if (sortOrder && field) {
            let data = {
                pageNumber: pagination?.current,
                pageSize: pagination?.pageSize,
                searchTerm: searchTerm,
                field: field,
                order: sortOrder,
                submissionStatus: submissionStatusString
            }
            setPagination(pagination);
            applicationStore.setPagination({
                ...applicationStore.Pagination,
                pageSize: pagination?.pageSize,
                current: pagination?.current,
                submissionStatus: submissionStatusString,
                field: field,
                order: sortOrder,
            })
            fetchData(data);
        } else {
            let data = {
                pageNumber: pagination?.current,
                pageSize: pagination?.pageSize,
                searchTerm: searchTerm,
                submissionStatus: submissionStatusString,
                field: field,
                order: sortOrder,
            }
            setPagination(pagination);
            applicationStore.setPagination({
                ...applicationStore.Pagination,
                pageSize: pagination?.pageSize,
                current: pagination?.current
            })
            fetchData(data);
        }


    };
    const handleSearch = (event) => {
        const value = event.target.value;
        applicationStore.setPagination({
            ...applicationStore.Pagination,
            pageSize: 10,
            current: 1
        })
        let data = {
            pageNumber: 1,
            pageSize: 10,
            searchTerm: value,
            field: applicationStore?.field,
            order: applicationStore?.order,
            submissionStatus: applicationStore?.submissionStatusString
        }
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }
        debounceTimer.current = setTimeout(() => {
            fetchData(data);
        }, 1000);

        setSearchTerm(value);

    };
    const handleViewFile = (fileId) => {
        setTimeout(() => {

            window.open(
                `${process.env.REACT_APP_ARCH_API_ENDPOINT}api/Media/view/?fileId=${fileId?.trim()}`,
                "_blank"
            );

        }, 100);
    };
    const handleDownload = (data) => {
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_ARCH_API_ENDPOINT}api/Media/download?fileId=${data?.fileId}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const formatPhoneNumber = (input) => {
        if (!input) return "";
        const cleaned = ("" + input).replace(/\D/g, "").slice(0, 10);
        // Format the cleaned input
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            return [match[1], match[2], match[3]].filter(Boolean).join("-");
        }
        return input;
    };
    const appealFormChange = (e) => {
        const { id, value } = e.target;
        const formatAndSetValue = (formattedValue) => {
            appealDetails[id] = formattedValue;
            form.setFieldsValue(appealDetails);
            setAppealDetails({ ...appealDetails });
        };
        if (id === "phoneNumber") {
            formatAndSetValue(formatPhoneNumber(value));
        } else {
            appealDetails[id] = value;
            setAppealDetails(appealDetails);
        }

    };
    const handleValuesChange = (changedValues, allValues) => {
    };
    const handleAppealDetails = async () => { // Add async
        try {
            setLoading(true); // Set loading before the API call
            let residentKey = apiStore?.configApi?.community?.residentKey;
            let request = {
                ResidentKey: apiStore?.configApi?.community?.residentKey?.toString(),
                ResidentContactKey: apiStore?.configApi?.community?.residentContactKey
                    ? apiStore?.configApi?.community?.residentContactKey?.toString()
                    : residents[residentKey?.toString()],
                SubmissionId: selectedSubmissionDetail?.submissionId || null,
                SubmissionBaseId: selectedSubmissionDetail?.baseId || null,
                DayTimePhone: appealDetails?.phoneNumber || "",
                ReasonForAppeal: appealDetails?.reasonForAppeal || "",
                AppealDoc: fileList ? fileList.map(file => file.fileId) : []
            };

            const response = await apiStore.FormsApiClient.AddAppealRequest(JSON.stringify(request));

            if (response?.data?.status) {
                setResult(response?.data?.data);
                applicationStore?.setRequestList(response?.data?.data);

                const totalCount = response?.data?.totalCount || 0;

                applicationStore.setPagination({
                    ...applicationStore.Pagination,
                    total: totalCount,
                });

                setPagination({
                    ...pagination,
                    total: totalCount,
                });
                setAppealOpen(false);
                form?.resetFields();
                setFileList([]);
                let data = {
                    pageNumber: 1,
                    pageSize: 10,
                    searchTerm: null,
                    field: applicationStore?.field,
                    order: applicationStore?.order,
                    submissionStatus: applicationStore?.submissionStatusString
                }
                fetchData(data);
                Swal.fire({
                    title: "Success",
                    text: "Appeal request created successfully.",
                    icon: "success",
                    confirmButtonColor: "#44637e",
                });
            } else {
                Swal.fire({
                    title: "Error",
                    text: "Unable to create the request.",
                    icon: "error",
                    confirmButtonColor: "#44637e",
                });
            }
        } catch (error) {
            console.error("Error in handleAppealDetails:", error);
            Swal.fire({
                title: "Error",
                text: "An error occurred while processing the request.",
                icon: "error",
                confirmButtonColor: "#44637e",
            });
        } finally {
            setLoading(false); // Ensure loading is turned off in all cases
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const handleAppealCancel = () => {
        setAppealOpen(false)
        form?.resetFields();
        setFileList([]);
    }
    return (
        <>
         {loading && <Spinner />}
            <Modal
                title="Appeal Form"
                centered
                footer=""
                open={appealOpen}
                Ok={() => setAppealOpen(false)}
                Cancel={handleAppealCancel}
                width={500}
            >
                {(pageName) => (
                    <>{loading && <Spinner />}
                        <p>Request Number: {selectedSubmissionDetail?.requestNumber}</p>
                        <p>Submitted Date: {formatDate(selectedSubmissionDetail?.submittedDate)}</p>
                        <p>Unit Address : {selectedSubmissionDetail?.unitName}</p>
                        <Form
                            layout="vertical"
                            className="ant-form"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            onFinish={handleAppealDetails}
                            onFinishFailed={onFinishFailed}
                            onValuesChange={handleValuesChange}
                            autoComplete="off"
                            initialValues={{}}
                            form={form}
                        >
                            <TextAreaInput
                                label="Reason For Appeal"
                                name="reasonForAppeal"
                                placeholder="Please provide a reason for appeal."
                                hasFeedback={true}
                                validateTrigger={"onChange"}
                                size="large"
                                // value={appealDetails?.value}
                                onChange={appealFormChange}
                                rules={validationRules.reason}
                            />
                            <TextInput
                                className="user-form-input"
                                name="phoneNumber"
                                label="Daytime Phone"
                                placeholder="Daytime Phone"
                                hasFeedback={true}
                                validateTrigger={"onChange"}
                                size="large"
                                onChange={appealFormChange}
                                rules={validationRules.dayTimePhone}
                            />
                            <p>File Upload</p>
                            <UploadButton
                                fileList={fileList}
                                setFileList={setFileList}
                                getArchFormResponse={selectedSubmissionDetail}
                                appealDoc={true}
                                handleDownload={handleDownload}
                                handleViewFile={handleViewFile}
                            />
                            <Button
                                htmlType="submit"
                                type="primary"
                                title="Submit"
                            > Submit
                            </Button>
                        </Form >
                    </>)}
            </Modal>
            <div className="arch-request-page">
                <div className="architectural-header-wrapper">
                    <div className="architectural-header-left-wrap">
                        <h1 className="m-0 section-heading">Architectural Requests</h1>
                        <TextInput className="search-bar-wrapper"
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder={"Search here"}
                            icon={
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z"
                                        stroke="#757575"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>}
                        />
                    </div>
                    <div className="architectural-header-right-wrap">
                        <Button onClick={() => navigate('/AccountInfo/ArchitecturalRequestsNew/Add')}>Add New Request</Button>
                    </div>
                </div>


                <DynamicTable

                    columns={columns}
                    data={applicationStore?.RequestList}
                    pagination={{
                        current: applicationStore.Pagination?.current,
                        pageSize: applicationStore.Pagination?.pageSize,
                        total: applicationStore.Pagination?.total,
                        showSizeChanger: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Request`,
                    }}
                    onChange={handleTableChange}
                    rowKey="id"
                    onRow={(record) => ({
                        onClick: (e) => {
                            if (e?.target?.tagName === 'TD') {
                                handleView(record);
                            }
                        },
                    })}
                    onRowClick={handleView}
                />
                <SideBar
                    title={"Edit Request"}
                    centered={"centered"}
                    open={editFormOpen}
                    Ok={EditOk}
                    closable={EditCancel}
                    onClose={EditCancel}
                    width={1800}
                    children={EditForm}
                />
                <SideBar
                    title={"Copy Request"}
                    centered={"centered"}
                    open={copyopen}
                    Ok={CopyOk}
                    closable={CopyCancel}
                    onClose={CopyCancel}
                    width={1800}
                    children={CopyPermissionsFields}
                />
            </div>
        </>
    );
});

export default ArchitecturalRequestList;
