import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload, Form } from "antd";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadButton = ({handlePreview,handleChange, number,fileList,previewOpen,previewImage,setPreviewOpen,setPreviewImage,setFileList }) => {
  const handlePreviews = async (file) => {
    handlePreview(file)
  };
  const handleFile = () => {};
  // const handleUpload = async (file) => {
  //   //setLoading(true);
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("Name", file?.name);
  //   formData.append("dir", null);
  //   // formData.append("UserId", SubmissionInfo?.userId);
  
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/media/add`, {
  //       method: "POST",
  //       body: formData, // Send FormData directly
  //     });
  
  //     const resultData = await response.json();
  //     if (response.ok && resultData.status) {
  //       setFileList(resultData.data.fileId);
  //       message.success(`${file.name} uploaded successfully.`);
        
  //     } else {
  //       Swal.fire({
  //         title: "Error",
  //         text: resultData.message || "Unable to create the request.",
  //         icon: "error",
  //         confirmButtonColor: "#44637e",
  //       });
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Upload error:", error);
  //     Swal.fire({
  //       title: "Error",
  //       text: "An error occurred while submitting the data.",
  //       icon: "error",
  //       confirmButtonColor: "#44637e",
  //     });
  //     message.error(`${file.name} upload failed.`);
  //   }
  // };
  


  const customRequest = ({ file, onSuccess }) => {
    //handleUpload(file).then(() => onSuccess("ok"));
  };


  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <>
      <Form.Item name="files">
        <Upload
          listType="picture-circle"
          fileList={fileList}
          method="get"
          multiple={true}
          onPreview={handlePreviews}
          onChange={handleChange}
        >
          {fileList.length >= number ? null : uploadButton}
        </Upload>
        {previewImage && (
          <Image
            wrapperStyle={{
              display: "none",
            }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        )}
      </Form.Item>
    </>
  );
};
export default UploadButton;
