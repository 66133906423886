import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { Row, Col, Button, Select, Modal, Input, Flex, Form, Radio, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons"; // Importing icons
import Component from "../../../components";
import Spinner from "../../../components/Spinner/Spiner";
import { useStore } from "../../../root-store-context";
import "./SubmissionDetails.css";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import CommonFuncitons from "../../../utils/CommonFunciton";
import TextInput from "../../../components/TextInput/TextInput";
import moment from "moment";

const formatLocalDate = (date) => {
  if (!date) return null; // Return null if no date is provided

  const d = new Date(date);

  // Customize format (e.g., MM/DD/YYYY HH:mm:ss)
  const localDate = d.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const localTime = d.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return `${localDate} ${localTime}`;
};

const Submissions = () => {
  const navigate = useNavigate();
  const debounceTimer = useRef();
  const [form] = Form.useForm()
  const { residentKey } = useParams();
  const { apiStore } = useStore();
  const [isLoadingSubmissions, setIsLoadingSubmissions] = useState(true);
  const [submissions, setSubmissions] = useState([]);
  const [isAchPayment, setIsAchPayment] = useState(false);
  // Modal states
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [isPaymentOptionModalOpen, setIsPaymentOptionModalOpen] =
    useState(false);
  const [remark, setRemark] = useState("");
  const [currentSubmissionId, setCurrentSubmissionId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [payaResponse, setPayaResponse] = useState(null);
  const [isLoadingPaymentResponse, setIsLoadingPaymentResponse] =
    useState(false);
  const asctohex = (asc) => {
    return Array.from(asc)
      .map((char) => ("0" + char.charCodeAt(0).toString(16)).slice(-2))
      .join("");
  };
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    filter: "",
    total: 0,
    sort: "DESC",
    column:"submissionDate"
  });
  const generateUrl = () => {
    // Required parameters
    const params = {
      "developer-id": process.env.REACT_APP_DEVELOPER_ID,
      "hash-key": process.env.REACT_APP_HASH_KEY,
      "user-id": process.env.REACT_APP_USER_ID,
      "timestamp": Math.floor((Date.now() - 60000) / 1000),
      "data": {
        "transaction": {
          "payment_method": isAchPayment ? "ach" : "cc",
          "action": isAchPayment ? "debit" : "sale",
          "transaction_amount": currentRecord?.amount,
          "location_id": process.env.REACT_APP_LOCATION_ID,
          "transaction_api_id": uuidv4(),
          "show_cvv": "1",
          "parent_send_message": 1,
          "parent_close": true,
          "product_transaction_id":isAchPayment? process.env.REACT_APP_ACH_PRODUCT_TRANSACTION_ID: process.env.REACT_APP_CC_PRODUCT_TRANSACTION_ID,
          "ach_sec_code":"WEB",
          "display_close_button": false,
          "order_num": `${currentRecord?.id}`,
          "stylesheet_url":`${process.env.REACT_APP_REDIRECT_URI}/style/style.css`
        },
      },
    };
    // [1] Generate the secure hash
    const userHashKey = process.env.REACT_APP_HASH_KEY; // Replace with your actual user hash key
    params["hash-key"] = CryptoJS.HmacSHA256(
      params["user-id"] + params["timestamp"],
      userHashKey
    ).toString(CryptoJS.enc.Hex);

    // [2] Convert the data to hex
    params["data"] = asctohex(JSON.stringify(params["data"]));

    // [3] Build the URL to retrieve the form
    const urlParams = Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    const url = `https://api.payaconnect.com/v2/payform?${urlParams}`;

    // Log the generated URL for debugging
    console.log("Generated URL:", url);

    // Optionally, open the form in a new tab or window
    // window.open(url, "_blank");
    return (
      <>
        <div className={`${!isAchPayment ? "first-frame" : "iframe"}`}>
          <iframe src={url} title="Example iframe"></iframe>
        </div>
      </>
    );
  };

  window.addEventListener("message", receiveMessage, false);
  async function receiveMessage(event) {
    // Make sure the value for allowed matches the domain of the iFrame you are embedding.
    var allowed = "https://api.payaconnect.com";
    // Verify sender's identity
    if (event.origin !== allowed) return;
    setIsPaymentOptionModalOpen(false);
    setIsLoadingPaymentResponse(true);

    // Add logic here for doing something in response to the message
    const payaResponse = JSON.parse(event.data);
    setPayaResponse(JSON.parse(event.data))
    console.log(payaResponse);
    
  }

  useEffect(() => {
    if(payaResponse){
      console.log("status_id:",payaResponse?.status_id, "reason_code_id:",payaResponse?.reason_code_id, "type_id:",payaResponse?.type_id, payaResponse?.auth_amount);
      const statusMessage = CommonFuncitons.getStatusMessage(payaResponse?.status_id);
      const reasonCodeMessage = CommonFuncitons.getReasonCodeMessage(payaResponse?.reason_code_id);
      const typeMessage = CommonFuncitons.getTypeMessage(payaResponse?.type_id);
      console.log("reasonCodeMessage:",reasonCodeMessage, "statusMessage:",statusMessage,"typeMessage:",typeMessage)   
    
    apiStore.FormsApiClient.ProcessTenantPayment(payaResponse)
    .then((res)=>{
      if (res?.data?.status) {
        navigate(`/Forms/TenantRegistrations/${""}`)
        let req = {
            residentKey : apiStore?.configApi?.community?.residentKey,
            // searchTerm:form?.getFieldValue('search'),
            // sortColumn:pagination?.column||'SubmissionDate',
            // sortOrder:pagination?.sort||'DESC',
            // pageNumber:pagination?.current,
            // pageSize:pagination?.pageSize
        }
        apiStore.FormsApiClient.GetAllTenantSubmissions(req)
          .then((res) => {
            const transformedData = res?.data?.data?.map((item) => ({
              id: item.id,
              property: item.property ?? "",
              submissionDate: item.submissionDate
                ? formatLocalDate(item.submissionDate)
                : "",
              submissionType: item.userId != null ? "Home Owner" : "Non Home Owner",
              status:
                item.status === 0
                  ? "In Process"
                  : item.status === 1
                  ? "Completed"
                  : "Hold",
                  payment: item.paymentStatus === 0 ?  ((item.amount && (item.amount > 0)) ? "Pending": "No Fee") : item.paymentStatus === 1 ? "Done" : item.paymentStatus === 2 ? "Fail" : "Renewal",
              details: item,
              amount: item.amount, // Full details for each submission
            }));
    
           console.log("transformedData2",transformedData)
            setSubmissions(transformedData);
            // setPagination({
            //   ...pagination,
            //   total: res?.data?.data?.totalCount,
            // });
            setIsLoadingSubmissions(false);
          })
          .catch(() => setIsLoadingSubmissions(false));
          if(reasonCodeMessage){
            Swal.fire({
                  title: `${statusMessage}`,
                  text: `Reason: ${reasonCodeMessage}`,
                  icon: "error",
                  confirmButtonColor: "#5A7890",
                });
          }
          else{
              Swal.fire({
                title: isAchPayment?'ACH - Accepted':'CC - Approved',
                text: isAchPayment?`${statusMessage}`:`Payment Done Successfully.`,
                icon: "success",
                confirmButtonColor: "#5A7890",
              });
          }
      } 
      else {
        Swal.fire({
          text: "Something went wrong at the moment",
          icon: "error",
          confirmButtonColor: "#5A7890",
        });
      }
    })
    .catch(()=>{}).finally(()=>{setIsLoadingPaymentResponse(false);})
    }
  }, [apiStore, payaResponse]);

  // Fetch the list of submissions
  useEffect(() => {
    setIsLoadingSubmissions(true);
    let req = {
        residentKey : apiStore?.configApi?.community?.residentKey,
        // searchTerm:form?.getFieldValue('search'),
        // sortColumn:pagination?.column||'SubmissionDate',
        // sortOrder:pagination?.sort||'DESC',
        // pageNumber:pagination?.current,
        // pageSize:pagination?.pageSize
      
    }
    apiStore.FormsApiClient.GetAllTenantSubmissions(req)
    .then((res) => {
        const transformedData = res?.data?.data?.map((item) => ({
          id: item.id,
          property: item.property ?? "",
          submissionDate: item.submissionDate ? formatLocalDate(item.submissionDate) : "",
          submissionType: item.userId != null ? "Home Owner" : "Non Home Owner",
          status: item.status === 0 ? "In Process" : item.status === 1 ? "Completed" : "Hold",
          payment: item.paymentStatus === 0 ?  ((item.amount && (item.amount > 0)) ? "Pending": "No Fee") : item.paymentStatus === 1 ? "Done" : item.paymentStatus === 2 ? "Fail" : "Renewal",
          details: item,
          amount: item.amount
        }));
       
console.log("transformedData3",transformedData);
        setSubmissions(transformedData);
        // setPagination({
        //   ...pagination,
        //   total: res?.data?.data?.totalCount,
        // });
        setIsLoadingSubmissions(false);
      })
      .catch(() => setIsLoadingSubmissions(false));
  }, [apiStore, residentKey]);

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(sorter,"sortersortersortersorter",filters)
    const sortOrder = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : null;
    const sortColumn = sortOrder ? sorter.field : null;
    pagination.sort = sortOrder;
    pagination.column = sortColumn;

     let req = {
        residentKey : apiStore?.configApi?.community?.residentKey,
        // searchTerm:form?.getFieldValue('search') ?? '',
        // sortColumn:sortColumn||'SubmissionDate',
        // sortOrder:sortOrder||'DESC',
        // pageNumber:pagination?.current,
        // pageSize:pagination?.pageSize
    }
    setIsLoadingSubmissions(true);
    apiStore.FormsApiClient.GetAllTenantSubmissions(req)
      .then((res) => {
        const transformedData = res?.data?.data?.map((item) => ({
          id: item.id,
          property: item.property ?? "",
          submissionDate: item.submissionDate
            ? formatLocalDate(item.submissionDate)
            : "",
          submissionType: item.userId != null ? "Home Owner" : "Non Home Owner",
          status:
            item.status === 0
              ? "In Process"
              : item.status === 1
              ? "Completed"
              : "Hold",
              payment: item.paymentStatus === 0 ?  ((item.amount && (item.amount > 0)) ? "Pending": "No Fee") : item.paymentStatus === 1 ? "Done" : item.paymentStatus === 2 ? "Fail" : "Renewal",
          details: item,
          amount: item.amount, // Full details for each submission
        }));

        console.log("transformedData1",transformedData);
        setSubmissions(transformedData);
        setIsLoadingSubmissions(false);
      })
      .catch(() => setIsLoadingSubmissions(false));
    setPagination(pagination);
  };

  // Columns for displaying submission data

  const columns = [
    {
      title: "Property Name",
      dataIndex: "property",
      key: "property",
      sorter: (a, b) => a.property.localeCompare(b.property), // Adding client-side sorting
      sortDirections: ['ascend', 'descend'],
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Property Name`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.property.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Submission Date",
      dataIndex: "submissionDate",
      key: "submissionDate",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment.utc(a.submissionDate).unix() - moment.utc(b.submissionDate).unix(), // Client-side sorting by timestamp
      sortDirections: ['ascend', 'descend'],
      render: (text) => moment.utc(text).local().format('M/D/YYYY, hh:mm:ss A'),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Submission Date`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => moment.utc(record.submissionDate).local().format('M/D/YYYY, hh:mm:ss A').includes(value),
    },
    {
      title: "Approval",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status), // Sorting by status
      sortDirections: ['ascend', 'descend'],
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Radio.Group
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Radio value="In Process">In Process</Radio>
            <Radio value="Hold">Hold</Radio>
            <Radio value="Completed">Completed</Radio>
          </Radio.Group>
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.status.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Payment",
      dataIndex: "payment",
      key: "payment",
      sorter: (a, b) => a.payment.localeCompare(b.payment), // Sorting based on payment value
      sortDirections: ['ascend', 'descend'],
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Radio.Group
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Radio value="Pending">Pending</Radio>
            <Radio value="No Fee">No Fee</Radio>
            <Radio value="Done">Done</Radio>
            <Radio value="Fail">Fail</Radio>
            <Radio value="Renewal">Renewal</Radio>
          </Radio.Group>
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => record.payment.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount, // Sorting by amount
      sortDirections: ['ascend', 'descend'],
      render: (text) => (text ? `$${parseFloat(text).toFixed(2)}` : ``),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Amount`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                confirm();
              }}
              size="small"
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ marginLeft: 8 }}
            >
              Apply
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => (record.amount ? record.amount.toString().includes(value) : false),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <Button
            style={{
              borderRadius: 19,
              border: "1px solid #fff",
              color: "#fff",
              backgroundColor: "#5A7890",
              height: "2rem",
              width: "7rem",
              marginRight: "10px",
            }}
            onClick={() =>
              navigate(`/Forms/TenantRegistrations/View/${residentKey || ""}`, {
                state: { submissionId: record.id },
              })
            }
          >
            View Details
          </Button>
  
          {record.amount &&
            record.payment !== "Done" &&
            record.payment !== "Renewal" && (
              <Button
                style={{
                  backgroundColor: "#5A7890",
                  color: "#fff",
                }}
                onClick={() => {
                  setCurrentRecord(record);
                  setIsPaymentOptionModalOpen(true);
                }}
              >
                Make a Payment
              </Button>
            )}
        </div>
      ),
    },
  ];

  // Show the modal for approving/rejecting
  const handleMakePayment = (id) => {
    console.log(handleMakePayment);
  };

  // Show the modal for approving/rejecting
  const showModal = (id, status) => {
    setCurrentSubmissionId(id);
    setCurrentStatus(status);
    setIsModalVisible(true);
  };

  // Handle modal submit
  const handleOk = () => {
    if (!remark.trim()) {
      alert("Please enter a remark.");
      return;
    }

    const approvalBy = apiStore.configApi.userEmail; // Replace with actual logged-in user ID

    apiStore.FormsApiClient.UpdateTenantRequestStatus(
      currentSubmissionId,
      currentStatus === "Approved" ? 1 : 2,
      remark,
      approvalBy
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.status) {
            Swal.fire({
              text: `${currentStatus} Successfully.`,
              icon: "success",
              confirmButtonColor: "#5A7890",
              customClass: {
                title: "text-danger",
                content: "text-danger",
              },
            });
          } else {
            Swal.fire({
              text: res?.data?.message,
              icon: "error",
              confirmButtonColor: "#5A7890",
              customClass: {
                title: "text-danger",
                content: "text-danger",
              },
            });
          }
        }
        console.log("Status updated successfully:", res);
        setIsModalVisible(false);
        // Reload submissions after status update
        setSubmissions(
          submissions.map((submission) => {
            if (submission.id === currentSubmissionId) {
              submission.status =
                currentStatus === "Approved" ? "Approved" : "Rejected";
              submission.actions = "";
            }
            return submission;
          })
        );
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        alert("Failed to update status. Please try again.");
      });
  };
  const paymentOptions = [
    { name: "Pay with Credit Card", value: "CC" },
    { name: "Pay with eCheck/ACH", value: "ACH" },
  ];
  // Handle modal cancel
  const handleCancel = () => {
    setIsModalVisible(false);
    setRemark("");
  };
  const handlePaymentOptionModalCancel = () => {
    setIsPaymentOptionModalOpen(false);
  };
  const handleCreateRegistration = () => {
    const residentKey = apiStore?.configApi?.community?.residentKey;
    navigate(`/Forms/TenantRegistrations/Create`);
  };
  const handleRadioChange = (e) => {
    setIsAchPayment(e.target.value === "ACH");
  };

  return (
    <>
      {(isLoadingSubmissions || isLoadingPaymentResponse) && <Spinner />}

      <Row justify={"center"}>
        <Col sm={20} className="text-center">
          <Flex
            justify="space-between"
            wrap="wrap"
            align="center"
            gap="16px"
            style={{ paddingBottom: 24 }}
          >
            <Flex wrap="wrap" align="center" gap="16px" className="w-mo-100">
              <h1 className="page-title">Tenant Registration</h1>
            </Flex>
            <Flex wrap="wrap" align="center" gap="16px" className="w-mo-100">
              <Button type="primary" onClick={handleCreateRegistration}>
                New Registration
              </Button>
            </Flex>
          </Flex>
        </Col>       
        <Col sm={20}>
          <Table columns={columns}  dataSource={submissions} />
        </Col>
      </Row>

      {/* Modal for remark input */}
      <Modal
        title={`Enter Remark for ${currentStatus}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Cancel"
      >
        <Input.TextArea
          rows={4}
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          placeholder="Please enter your remark"
        />
      </Modal>
      <Modal
        title={`Payment Option`}
        visible={isPaymentOptionModalOpen}
        onOk={() => {}}
        onCancel={handlePaymentOptionModalCancel}
        width={400}
        // height={!isAchPayment ? 50 : 100}
        footer={false}
      >
        <Form>
          <Radio.Group
            name="paymentMethod"
            value={isAchPayment ? "ACH" : "CC"}
            onChange={handleRadioChange}
          >
            <Radio value="CC">Pay with Credit Card</Radio>
            <Radio value="ACH">Pay with eCheck/ACH</Radio>
          </Radio.Group>
                   {isAchPayment && <>
    <p>By authorizing this transaction, customer agrees that merchant may convert this transaction into an Electronic Funds Transfer (EFT) transaction or paper draft, and to debit this account for the amount of the transaction. Additionally, in the event this draft or EFT is returned unpaid, a service fee, as allowable by law, will be charged to this account via EFT or draft. In the event you choose to revoke this authorization, please do so by contacting the merchant directly. Please note that processing times may not allow for revocation of this authorization.</p>
      </>}
          {generateUrl()}
        </Form>
      </Modal>
    </>
  );
};

export default Submissions;
