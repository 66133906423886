/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Flex,
  MenuProps,
  Popconfirm,
  Skeleton,
  Space,
  Spin,
} from "antd";
import { useEffect, useRef, useState } from "react";
import GridLayout, {
  Layout,
  Responsive,
  WidthProvider,
} from "react-grid-layout";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import {
  DeleteOutlined,
  EditOutlined,
  AppstoreAddOutlined,
  CloseOutlined,
  SaveOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { useStore } from "../../../../root-store-context";
import { observer } from "mobx-react-lite";
import {
  GridStoreItem,
  LayoutItem,
  LayoutItems,
  WidgetRequestData,
} from "../../../../stores/appStores/BoardVue/DashBoardStore";
import { WidgetsSize, widgetsList, widgetsSize } from "../WidgetsList";
import Widget from "./Widget";
import { BvReportsDataResponseItem } from "../../../../api/BoardVue";

type Props = {
  className?: string;
  cols?: { lg: number; md: number; sm: number; xs: number; xxs: number };
  rowHeight?: number;
};

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DashBoardLayout = observer(
  ({
    className = "layout",
    cols = { lg: 12, md: 12, sm: 1, xs: 1, xxs: 1 },
    rowHeight = 100,
  }: Props) => {
    const { dashBoardStore } = useStore();

    const blockRef = useRef<HTMLDivElement>(null);
    const [_items, setItems] = useState<LayoutItems>([]);
    const [blockWidth, setBlockWidth] = useState<number>(0);
    const [layouts, setLayouts] = useState<GridLayout.Layouts>({});
    const [currentCols, setCurrentCols] = useState<number>(1);
    const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [widgetsListState, setWidgetsListState] = useState<MenuProps["items"]>(widgetsList());
    const [needCompress, setNeedCompress] = useState<boolean>(false);
    const [compactType, setCompactType] = useState<'vertical' | 'horizontal' | null>('vertical');   

    const delimiter = "|";

    useEffect(() => {
      dashBoardStore.getLayout();
      const handleResize = () => {
        if (blockRef.current) {
          const newWidth = blockRef.current.getBoundingClientRect().width;
          setBlockWidth(newWidth);
        }
      };
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        setItems(await parseLayout(dashBoardStore.layout));
      };
      fetchData();
    }, [dashBoardStore.layout]);

    // useEffect(() => {
    //   try {
    //   console.log(JSON.parse(JSON.stringify(layouts.lg)))

    //   } catch (error) {
        
    //   }
    // }, [layouts]);

    useEffect(() => {
      if (widgetsList) {
        const itemKeys = _items.map((item) => item.i);
        const newWidgetsList = filterWidgetsList(widgetsList(), itemKeys);
        setWidgetsListState(newWidgetsList);
      }
      // if (needCompress) {
      //   setNeedCompress(false);
      //   const sorted = [...items].sort((a, b) => (a.y+a.h) - (b.y+b.h) || a.x - b.x);
      //   const grid = Array(cols.lg).fill(0);
      //   sorted.forEach(item => {
      //     for (let col = 0; col < cols.lg; col++) {
      //       if (col + item.w > cols.lg) continue;
      //       let space = true;
      //       for (let i = 0; i < item.w; i++) {
      //         if (grid[col + i] > item.y) {
      //           space = false;
      //           break;
      //         }
      //       }
      //       if (space) {
      //         item.x = col;
      //         item.y = Math.max(...grid.slice(col, col + item.w));
      //         for (let i = 0; i < item.w; i++) {
      //           grid[col + i] = item.y + item.h;
      //         }
      //         break;
      //       }
      //     }
      //   });
      //   setLayouts(getGritLayouts(cols, sorted));
      // } else {
      //   setLayouts(getGritLayouts(cols, items));
      // }
      if (needCompress) {
        setCompactType('horizontal');
      }
      setLayouts(getGritLayouts(cols, _items));
    }, [_items]);

    const filterWidgetsList = (list: any, itemKeys: string[]) => {
      return list.filter((item: any) => {
        if (item.children) {
          item.children = item.children.filter((c: any) => {
            return !itemKeys.includes(c.key);
          });
          return item.children.length > 0;
        } else {
          return !itemKeys.includes(item.key);
        }
      });
    };

    const getJSXElementByName = (
      name?: string,
      data?: BvReportsDataResponseItem,
      daysOrYear?: string
    ): JSX.Element => {
      return (
        <Widget widgetName={name} widgetData={data} daysOrYear={daysOrYear} />
      );
    };

    const getDataWidgetToRequest = (
      items: LayoutItems
    ): WidgetRequestData[] => {
      const result: WidgetRequestData[] = items.map((item: LayoutItem) => {
        return item.i.includes(delimiter)
          ? ({
              key: item.i,
              name: item.name ? item.name : item.i.split(delimiter)[0],
              days: item.i.split(delimiter)[1],
            } as WidgetRequestData)
          : ({
              key: item.i,
              name: item.name ? item.name : item.i,
            } as WidgetRequestData);
      });
      return result;
    };

    const parseLayout = async (
      items: GridStoreItem[]
    ): Promise<LayoutItems> => {
      let tempData: LayoutItems = [];

      if (typeof items !== "string" && items.length > 0) {
        tempData = items?.map((item: GridStoreItem) => {
          return {
            ...item,
          } as LayoutItem;
        });
      }

      if (tempData.length > 0) {
        const widgetData = await dashBoardStore.getDataWidgets(
          getDataWidgetToRequest(tempData)
        );
        tempData = addDataToItems(tempData, widgetData);
      }

      tempData = addElementToItems(tempData);
      const result: LayoutItems = tempData;
      return result;
    };

    const addDataToItems = (
      items: LayoutItems,
      widgetData: WidgetRequestData[]
    ): LayoutItems => {
      const result: LayoutItems = items?.map((item: LayoutItem) => {
        return {
          ...item,
          data: widgetData.find(
            (i) => i.name === item.name || i.name === item.i
          ),
        } as LayoutItem;
      });
      return result;
    };

    const addElementToItems = (items: LayoutItems): LayoutItems => {
      const result: LayoutItems = items.map((item: LayoutItem) => {
        if (item.i.includes(delimiter)) {
          const splitKey = item.i.split(delimiter);
          let daysOrYears = splitKey.length > 1 ? splitKey[1] : "";
          const intDaysOrYears = parseInt(daysOrYears);
          if (intDaysOrYears > 365 && intDaysOrYears < 1990) {
            daysOrYears = `${intDaysOrYears / 365}`;
          }
          return {
            ...item,
            element: getJSXElementByName(item.name, item.data, daysOrYears),
          } as LayoutItem;
        } else {
          return {
            ...item,
            element: getJSXElementByName(item.name, item.data),
          } as LayoutItem;
        }
      });
      return result;
    };

    const getGritLayouts = (
      cols: Props["cols"],
      items: LayoutItems
    ): GridLayout.Layouts => {
      let result: GridLayout.Layouts = {};

      if (cols) {
        const getYCoordinates = (index: number): number => {
          let result = 0;
          for (let i = 0; i < index; i++) {
            result += items[i].h;
          }
          return index === 0 ? result : result + 1;
        };

        const mapMobileLayouts = (item: LayoutItem, index: number): Layout => {
          const layout: Layout = {
            i: item.i,
            x: 0,
            y: getYCoordinates(index),
            h: item.h,
            w: 1,
          };
          return layout;
        };

        const groupAndSortItemsByXAndY = (
          items: GridStoreItem[]
        ): GridStoreItem[][] => {
          const groupedItems: { [key: number]: GridStoreItem[] } = {};

          for (const item of items) {
            if (!groupedItems[item.y]) {
              groupedItems[item.y] = [];
            }
            groupedItems[item.y].push(item);
          }
          for (const key in groupedItems) {
            groupedItems[key].sort((a, b) => a.x - b.x);
          }
          const sortedGroups: GridStoreItem[][] = Object.values(groupedItems);

          return sortedGroups;
        };

        const mapItemToLayout = (items: LayoutItems): Layout[] => {
          return items.map((item: LayoutItem) => {
            const newLayout: Layout = {
              i: item.i,
              x: item.x,
              y: item.y,
              h: item.h,
              w: item.w,
            };
            return newLayout;
          });
        };

        const getNewMobileLayouts = (
          sortedGroups: GridStoreItem[][]
        ): Layout[] => {
          const newLayouts: Layout[] = [];
          let currentY = 0;
          for (const group of sortedGroups) {
            for (const item of group) {
              newLayouts.push({
                i: item.i,
                x: 0,
                y: currentY,
                w: 1,
                h: item.h,
              });
              currentY += item.h;
            }
          }
          return newLayouts;
        };

        for (const key in cols) {
          if (Object.prototype.hasOwnProperty.call(cols, key)) {
            const value: number = cols[key as keyof typeof cols];
            const mobileLayouts: Layout[] = getNewMobileLayouts(
              groupAndSortItemsByXAndY(items)
            );
            const layouts: Layout[] =
              value === 12
                ? mapItemToLayout(items)
                : mobileLayouts.map(mapMobileLayouts);
            result = {
              ...result,
              [key as keyof typeof cols]: layouts,
            };
          }
        }
      }
      return result;
    };

    const handleDragStop = (
      layout: GridLayout.Layout[],
      oldItem: Layout,
      newItem: Layout,
      placeholder: Layout,
      event: MouseEvent,
      element: HTMLElement
    ) => {
      if (currentCols === 12) {
        const newItems: LayoutItems = layout.map(
          (item: Layout, index: number) => {
            const result: LayoutItem = {
              ..._items[index],
              x: item.x,
              y: item.y,
            };
            return result;
          }
        );
        setItems(newItems);
      }
    };

    const onRemoveItem = (i: string): void => {
      setNeedCompress(true);
      setItems(_items.filter((item) => item.i !== i));
    };

    const createElement = (el: LayoutItem): JSX.Element => {
      const removeStyle: React.CSSProperties = {
        position: "absolute",
        right: "5px",
        top: "5px",
        zIndex: 1000000,
      };
      const RemoveElement = (): JSX.Element => {
        return (
          <Button
            className="remove"
            size="small"
            onClick={(e) => {
              onRemoveItem(el.i);
            }}
            style={removeStyle}
            icon={<DeleteOutlined />}
          />
        );
      };
      return (
        <Card key={el.i} size="small">
          {isEdit && <RemoveElement />}
          <div style={{ height: "100%" }} className="grid-dragHandle">
            {el.element}
          </div>
        </Card>
      );
    };

    const onBreakpointChange = (breakpoint: string, cols: number) => {
      setCurrentBreakpoint(breakpoint);
      setCurrentCols(cols);
    };

    const layoutChangeHandler = (layout: Layout[]) => {
      if (isEdit) {
        // console.log("layoutChangeHandler: ", layout)
        if (compactType == 'horizontal') {
          setCompactType('vertical');
          setNeedCompress(false);
        }
        let newItems = _items.map((i) => {
          let itemFromLayout = layout.find((j) => j.i == i.i);
          if (itemFromLayout != undefined) {
            i.x = itemFromLayout.x;
            i.y = itemFromLayout.y;
          }
          return i;
        })
        setItems(newItems);
        setLayouts(getGritLayouts(cols, newItems));
      }
    };

    const handIsEdit = (
      e?: React.MouseEvent<HTMLElement, MouseEvent>
    ): void => {
      setIsEdit(true);
    };

    const handClose = async (
      e?: React.MouseEvent<HTMLElement, MouseEvent>
    ): Promise<void> => {
      setItems(await parseLayout(dashBoardStore.layout));
      setIsEdit(false);
    };

    const handSave = async (
      e?: React.MouseEvent<HTMLElement, MouseEvent>
    ): Promise<void> => {
      const isSaveDada = await dashBoardStore.saveLayout(_items);
      setIsEdit(false);
    };

    const getWidgetSize = (key: string): WidgetsSize | undefined => {
      return widgetsSize.find((item) => item.key === key);
    };

    const addWidget = async (
      key: string,
      widgetName: string,
      widgetSize: WidgetsSize | undefined
    ) => {
      if (widgetSize) {
        const widgetsItems = _items;

        widgetsItems.push({
          i: key,
          x: 0,
          y: 100,
          h: widgetSize.h,
          w: widgetSize.w,
          name: widgetName,
        });

        setItems(await parseLayout(widgetsItems));
      }
    };

    const onMenuClick: MenuProps["onClick"] = (e) => {
      if (e.key.includes(delimiter)) {
        const key = e.key.split(delimiter)[0];
        const widgetSize = getWidgetSize(key);
        addWidget(e.key, key, widgetSize);
      } else {
        const key = e.key;
        const widgetSize = getWidgetSize(key);
        addWidget(key, key, widgetSize);
      }
    };

    const TollBarResponsiveGridLayout = (): JSX.Element => {
      return (
        <>
          <h1 className="page-title">Dashboard</h1>
          <div>
            {isEdit === true ? (
              <>
                <Popconfirm
                  title="Cancel editing"
                  description="All changes will not be saved, are you sure?"
                  onConfirm={handClose}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    icon={<CloseOutlined />}
                    disabled={dashBoardStore.isLoading}
                  />
                </Popconfirm>
                <Divider type="vertical" />
                <Popconfirm
                  title="Save Widgets"
                  description="Are you sure you want to save the widgets?"
                  onConfirm={handSave}
                  icon={<InfoCircleFilled style={{ color: "#5a7890" }} />}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    icon={<SaveOutlined />}
                    disabled={dashBoardStore.isLoading}
                  />
                </Popconfirm>
                <Divider type="vertical" />
                <Dropdown
                  trigger={["click"]}
                  disabled={dashBoardStore.isLoading}
                  menu={{ items: widgetsListState, onClick: onMenuClick }}
                >
                  <Button icon={<AppstoreAddOutlined />} />
                </Dropdown>
              </>
            ) : (
              <Button
                onClick={handIsEdit}
                icon={<EditOutlined />}
                disabled={dashBoardStore.isLoading}
              />
            )}
          </div>
        </>
      );
    };

    return (
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Flex
          justify="start"
          wrap="wrap"
          align="center"
          gap="middle"
          style={{ padding: 10 }}
        >
          <TollBarResponsiveGridLayout />
        </Flex>
        <Spin spinning={dashBoardStore.isLoading}>
          {dashBoardStore.isLoading && _items.length === 0 ? (
            <>
              <Skeleton active />
            </>
          ) : (
            <div style={isEdit ? { userSelect: "none" } : {}}>
              <ResponsiveReactGridLayout
                onLayoutChange={layoutChangeHandler}
                onBreakpointChange={onBreakpointChange}
                layouts={layouts}
                className={className}
                cols={cols}
                rowHeight={rowHeight}
                width={blockWidth}
                isResizable={false}
                isDraggable={isEdit}
                resizeHandles={[]}
                onDragStop={handleDragStop}
                compactType={compactType}
                draggableHandle=".grid-dragHandle"
                useCSSTransforms={true}
              >
                {_items.map((el) => createElement(el))}
              </ResponsiveReactGridLayout>
            </div>
          )}
        </Spin>
      </Space>
    );
  }
);

export default DashBoardLayout;
