import React from 'react';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { App as AntdApp, ConfigProvider } from 'antd';
import { RootStoreContext } from './root-store-context';
import RootStore from './stores/root-store';
import Router from './Routes/Router';
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from "oidc-client-ts";
import { Components } from "formiojs";
//import store from "../../redux/Store";
import UploadButton from './components/UploadButton/CustomUploadButton';

const onSigninCallback = (): void => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  )
}
const oidcConfig = {
  authority: process.env.REACT_APP_IDP as string,
  client_id: process.env.REACT_APP_CLIENT_ID as string,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI as string,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  extraQueryParams:{
    user_type:"board_member"
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: false,
};
const rootStore = new RootStore();
class UploadButtonComponent extends Components.components.field {
 
  static schema() {
    return {
      type: "uploadbutton",
      label: "Upload File",
      key: "customUpload",
      input: true,
      noRemove: true,
      protected:true,
      disableDeleting: true ,ignore: true

    };
  }

  static get builderInfo() {
    return {
      title: "Upload Button",
      icon: "upload",
      group: "basic",
      schema: UploadButtonComponent.schema(), ignore: true // Hide from builder to remove all options
    };
  } 

  attach(element : any) {
    // Create a container div where React component will be mounted
    const container = this.ce("div", { class: "custom-upload-button" });
    element.appendChild(container);
  //  const state = store.getState(); // Directly store ka state get karo
   // const GetAllDocumentType = state?.AllDocumentTypeByHoaId;
    const root = ReactDOM.createRoot(container);
    root.render(    <RootStoreContext.Provider value={rootStore}><UploadButton  
     
     
    
    /></RootStoreContext.Provider>);

    return super.attach(element);
  }
}

Components.setComponent("uploadbutton", UploadButtonComponent);
const App: React.FC = () => {

  return (
    <ConfigProvider
      theme={{
        token: {
          colorLink: "#5a7890",
          colorLinkHover: "#5a7890",
          colorLinkActive: "#5a7890",
        },
        components: {
          Menu: {
            colorBgContainer: '#FFFFFF',
            colorText: "#e6f4ff",
            itemSelectedColor: "#5a7890",
            fontSize: 14,
            itemSelectedBg: "#CBD8E2",
            itemColor: "#5a7890",
            itemHoverBg: "#CBD8E2",
            itemHoverColor: "#5a7890",
            colorBgLayout: "#FFFFFF",
            borderRadius: 0,
            borderRadiusLG: 0,
            itemMarginBlock: 0,
            itemMarginInline: 0
          },
          Input: {
            colorBgContainer: '#FFFFFF',
            borderRadius: 6
          },
          Form: {
            size: 13,
          },
          Button: {
            borderRadius: 17,
            colorPrimary: "rgba(47, 75, 98, 1)",
            colorPrimaryHover: "#5a7890",
            colorPrimaryActive: "#5a7890",
            colorPrimaryTextActive: "#ffffff",
          },
          Table: {
            cellPaddingBlock: 12,
            cellPaddingInline: 8,
            cellFontSizeSM: 13,
            cellPaddingInlineSM: 4,
            headerBg: "rgba(105, 105, 105, 0.30)",
            headerSortActiveBg: "rgba(105, 105, 105, 0.40)",
            headerSortHoverBg: "rgba(105, 105, 105, 0.45)",
            rowHoverBg: "rgb(235, 235, 235)",
            borderColor: "rgba(105, 105, 105, 0.2)",
            bodySortBg: "rgba(255, 255, 255, 0)",
            colorPrimary: "rgb(30, 42, 52)"
          },
          Layout: {
            siderBg: "#FFFFFF",
          },
          Descriptions: {
            itemPaddingBottom: 0,
          }
        },
      }}
      >

      <RootStoreContext.Provider value={rootStore}>
        <AntdApp>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AntdApp>
      </RootStoreContext.Provider>
    </ConfigProvider>
  );
};

export default App;