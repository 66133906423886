import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Form, Input, Button, Row, Col, Radio, Typography } from "antd";
import Swal from "sweetalert2";
import Spinner from "../../../components/Spinner/Spiner"; // Assuming Spinner component is available
import { useStore } from "../../../root-store-context"; // Assuming you're using the root-store context for API calls
import TextInput from "../../../components/TextInput/TextInput";
import moment from "moment";

const { Title, Text } = Typography;

const PaymentPage = () => {
  const { submissionId } = useParams(); // Get the submissionId from the URL
  const location = useLocation(); // Access location state
  const navigate = useNavigate();
  const { apiStore } = useStore(); // Assuming you're using an apiStore for API calls
    const [formatted, setFormatted] = useState({});
  const [isAchPayment, setIsAchPayment] = useState(false);
  const [isLoadingPaymentResponse, setIsLoadingPaymentResponse] = useState(false);
  const [paymentForm] = Form.useForm();
  const [fee, setFee] = useState(location.state?.fee || 0); // Get fee from location state
  const [residentKey, setResidentKey] = useState(location.state?.residentKey || ''); // Extract residentKey from state
  
  // Logging residentKey to check
  useEffect(() => {
    console.log(fee, submissionId, "PaymentPage", residentKey); 
  }, [fee, submissionId, residentKey]);

  const paymentOptions = [
    { name: "Pay with Credit Card", value: "CC" },
    { name: "Pay with eCheck/ACH", value: "ACH" },
  ];

  // Handle payment method change (ACH vs Credit Card)
  const handleRadioChange = (e) => {
    setIsAchPayment(e.target.value === "ACH");
  };
  
    const validateExpiryDate = (_, value) => {
      if (!value) {
        return Promise.resolve();
      }
      // Check if value is in "MM/YY" format
      const formatRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
      if (!formatRegex.test(value)) {
        return Promise.reject(
          new Error("Expiry Date must be in the format MM/YY")
        );
      }
  
      // Extract month and year from the value
      const month = parseInt(value.slice(0, 2), 10);
      const year = parseInt(value.slice(3, 5), 10);
  
      // Get current date
      const now = moment();
      const currentYear = now.year();
      const currentMonth = now.month() + 1; // Months are 0-based in moment
  
      // Determine the full year based on the two-digit year
      const fullYear = year >= currentYear % 100 ? 2000 + year : 1900 + year;
  
      // Check if the expiry date is valid
      if (month < 1 || month > 12) {
        return Promise.reject(
          new Error("Expiry Date month must be between 01 and 12")
        );
      }
  
      const expiryDate = moment()
        .set({ year: fullYear, month: month - 1, date: 1 })
        .endOf("month");
  
      // Check if the expiry date is in the future
      if (expiryDate.isBefore(now, "month")) {
        return Promise.reject(new Error("Expiry Date must be in the future"));
      }
  
      return Promise.resolve();
    };
  const handleExpiryChange = (e) => {
    debugger;
    const { value } = e.target;

    // Remove all non-digit characters
    const cleaned = value.replace(/\D+/g, "");

    // Limit to 16 digits
    const limited = cleaned.slice(0, 4);

    // Split the cleaned number into chunks of 4 digits and join with spaces
    const formattedCard = limited.match(/.{1,2}/g)?.join("/") || "";

    // Update the formatted value
    const formatted = { cardExpiry: formattedCard };

    // Update local state or form field value
    setFormatted(formatted);
    paymentForm.setFieldsValue(formatted);
  };
  const handleCvvChange = (e) => {
    debugger;
    const { value } = e.target;

    // Remove all non-digit characters
    const cleaned = value.replace(/\D+/g, "");

    // Limit to 16 digits
    const limited = cleaned.slice(0, 4);

    // Split the cleaned number into chunks of 4 digits and join with spaces
    const formattedCard = limited.match(/.{1,4}/g)?.join(" ") || "";

    // Update the formatted value
    const formatted = { cvv: formattedCard };

    // Update local state or form field value
    setFormatted(formatted);
    paymentForm.setFieldsValue(formatted);
  };
  const handleNameChange = (e) => {
    const { value } = e.target;
  
    // Remove all non-alphabetic and non-space characters
    const cleaned = value.replace(/[^A-Za-z\s]/g, "");
  
    // Update the cleaned value in the state
    const formattedName = { cardHolderName: cleaned };
  
    // Update local state or form field value
    setFormatted(formattedName);
    paymentForm.setFieldsValue(formattedName);
  };

  const handleAccountNumberChange = (e) => {
    const { value } = e.target;
  
    // Remove all non-digit characters
    const cleaned = value.replace(/\D+/g, "");
  
    // Update the cleaned value in the state
    const formattedAccountNumber = { accountNumber: cleaned };
  
    // Update local state or form field value
    setFormatted(formattedAccountNumber);
    paymentForm.setFieldsValue(formattedAccountNumber);
  };
  const handleRoutingNumberChange = (e) => {
    const { value } = e.target;
  
    // Remove all non-digit characters
    const cleaned = value.replace(/\D+/g, "");
  
    // Update the cleaned value in the state
    const formattedAccountNumber = { routingNumber: cleaned };
  
    // Update local state or form field value
    setFormatted(formattedAccountNumber);
    paymentForm.setFieldsValue(formattedAccountNumber);
  };

  const handleCardNumberChange = (e) => {
    const { value } = e.target;

    // Remove all non-digit characters
    const cleaned = value.replace(/\D+/g, "");

    // Limit to 16 digits
    const limited = cleaned.slice(0, 16);

    // Split the cleaned number into chunks of 4 digits and join with spaces
    const formattedCard = limited.match(/.{1,4}/g)?.join(" ") || "";

    // Update the formatted value
    const formatted = { cardNumber: formattedCard };

    // Update local state or form field value
    setFormatted(formatted);
    paymentForm.setFieldsValue(formatted);
  };

  // Handle payment form submission
  const handlePaymentFormSubmit = async (values) => {
    const req = {
      tenantId: submissionId,
      cardNumber: isAchPayment
        ? values?.accountNumber
        : values?.cardNumber?.replace(/\s+/g, ""),
      cvv: values?.cvv,
      cardExpiry: values?.cardExpiry,
      cardHolderName: values?.cardHolderName,
      routingNumber: values?.routingNumber,
      isAchPayment,
      paymentMethod: isAchPayment ? "ACH" : "CC",
      amount: fee
    };

    setIsLoadingPaymentResponse(true);

    const res = await apiStore.FormsApiClient.ProcessTenantPayment(req);
    setIsLoadingPaymentResponse(false);

    if (res?.data?.status) {
        navigate(`/Forms/TenantRegistrations/${residentKey || ""}`)
      Swal.fire({
        text: "Payment Done Successfully.",
        icon: "success",
        confirmButtonColor: "#5A7890",
      });
    } else {
      Swal.fire({
        text: res?.data?.message,
        icon: "error",
        confirmButtonColor: "#5A7890",
      });
    }
  };

  return (
    <>
      {isLoadingPaymentResponse && <Spinner />}
      <Button
                style={{
                  borderRadius: 19,
                  border: "1px solid #fff",
                  color: "#fff",
                  backgroundColor: "#5A7890",
                  height: "2rem",
                  width: "7rem",
                }}
                onClick={() => navigate(`/Forms/TenantRegistrations/${residentKey || ""}`)}
              >
                Back To List
              </Button>
      <p
        className="text-center"
        style={{
          color: "#5A7890",
          fontSize: "22px",
          marginBottom: "8px",
          marginTop: "5px",
          fontWeight: "bold",
        }}
      >
        Make A Payment
      </p>
      <div style={{ fontSize: "18px", marginBottom: "2.5rem" }}>
        Registration Fee : <strong>${fee}</strong>.
      </div>
      <div className="custom-input-box-wrap" style={{ paddingBottom: "16px" }}>
        {/* Using Radio.Group to handle radio buttons */}
        <Radio.Group
          onChange={handleRadioChange}
          value={isAchPayment ? "ACH" : "CC"}
        >
          {paymentOptions.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.name}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      <Form form={paymentForm} onFinish={handlePaymentFormSubmit}>
        <section>
          <Row gutter={16}>
            <Col span={12} className="custom-input-box-wrap">
              <TextInput
                rules={
                  isAchPayment
                    ? [{ required: true, message: "Account Holder Name is required" }]
                    : [{ required: true, message: "Card Holder Name is required" }]
                }
                name="cardHolderName"
                size="large"
                label={isAchPayment ? "Account Holder Name" : "Card Holder Name"}
                placeholder={isAchPayment ? "Enter Account Holder Name" : "Enter Card Holder Name"}
                onChange={handleNameChange}
              />
            </Col>
            <Col span={12} className="custom-input-box-wrap">
              {!isAchPayment && (
                <TextInput
                  name="cardNumber"
                  size="large"
                  label="Card Number"
                  placeholder="Enter Card Number"
                  onChange={handleCardNumberChange}
                  rules={[{ required: true, message: "Card Number is required" }]}
                />
              )}
              {isAchPayment && (
                <TextInput
                  name="accountNumber"
                  size="large"
                  label="Account Number"
                  placeholder="Enter Account Number"
                  onChange={handleAccountNumberChange}
                  rules={[{ required: true, message: "Account Number is required" }]}
                />
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            {!isAchPayment && (
              <>
                <Col span={12} className="custom-input-box-wrap">
                  <TextInput
                    name="cvv"
                    size="large"
                    label="CVV"
                    placeholder="Enter CVV"
                    onChange={handleCvvChange}
                    rules={[{ required: true, message: "CVV is required" }]}
                  />
                </Col>
                <Col span={12} className="custom-input-box-wrap">
                  <TextInput
                    name="cardExpiry"
                    size="large"
                    label="Expiration Date"
                    placeholder="MM/YY"
                    onChange={handleExpiryChange}
                    rules={[{ required: true, message: "Expiration Date is required" },{ validator: validateExpiryDate },]}
                  />
                </Col>
              </>
            )}
            {isAchPayment && (
              <Col span={12} className="custom-input-box-wrap">
                <TextInput
                  name="routingNumber"
                  size="large"
                  label="Routing Number"
                  placeholder="Enter Routing Number"
                  rules={[{ required: true, message: "Routing Number is required" }]}
                  onChange={handleRoutingNumberChange}
                />
              </Col>
            )}
          </Row>
        </section>
        <div style={{ textAlign: "right", paddingTop: "16px" }}>
          <Button
            htmlType="submit"
            className="submit-button"
            style={{
              marginLeft: 8,
              borderRadius: 19,
              color: "white",
              backgroundColor: "#5A7890",
              height: "2.5rem",
              width: "8rem",
            }}
          >
            Pay
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PaymentPage;
