import { useEffect, useState, useRef } from "react";
import Component from "../../../components";
import { Button, Dropdown, Menu, message, Tag } from "antd";
import { useStore } from "../../../root-store-context";
import Swal from "sweetalert2";
import Editor from "../../../components/Editor/Editor";
import TextInput from "../../../components/TextInput/TextInput";
import UploadButton from "../../../components/UploadButton/NewUploadButton";
import { formatDate } from "../../../Common";
import {
  PaperClipOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import CommonFuncitons from "../../../utils/CommonFunciton";
import ModalComponent from "../../../components/Modal/Modal";
import FileHelper from "../../../helpers/FileHelper";

const Comments = (props) => {
  const {form} = props;
  const { apiStore,applicationStore } = useStore();
  const { Spinner } = Component;
  const [fileList, setFileList] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [submissionInfo, setSubmissionInfo] = useState();
  const [createComment, setCreateComment] = useState({ documentIds: [] });
  const [loading, setLoading] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);
  const editorRef = useRef(null);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("OldNew");
  const [visible, setVisible] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [error, setError] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({
    commentText: "",
    documents: [],
  });
  const [documentLoadingStates, setDocumentLoadingStates] = useState({});
  let residents = {
    103096: "145011",
    1200782: "5012102",
    112192: "155269",
    1195639: "445445",
  };

  const fetchCommentDetails = async () => {
    let id = props?.form?.baseId;
    try {
      let residentKey = apiStore?.configApi?.community?.residentKey?.toString();
      let residentContactKey = apiStore?.configApi?.community
        ?.residentContactKey
        ? apiStore?.configApi?.community?.residentContactKey?.toString()
        : residents[residentKey?.toString()];
      const res = await apiStore.FormsApiClient.GetCommentDetails(
        id,
        residentKey,
        residentContactKey
      );
      if (res?.data) {
        const requestDetails = res.data;
        updateComments(requestDetails.comments);
        const combinedDocs = requestDetails.comments?.reduce((acc, comment) => {
          if (comment?.documents?.length > 0) {
            acc.push(...comment.documents);
          }
          return acc;
        }, []);
        const submissionData = props?.form?.submissionData;
        const uploadedFiles = extractUploadedFiles(submissionData);
        const files =
          uploadedFiles?.length > 0 ? consolidateFiles(uploadedFiles) : [];
        //   const mergedFiles = [...combinedDocs, ...files]
        setSubmissionInfo(requestDetails.submissionInfo);
        //  const obj = { form: res?.data?.formData ? JSON.parse(res.data.formData) : {} };
      }
    } catch (error) {
      console.error("Error in GetCommentDetails:", error);
    }
  };
  function extractUploadedFiles(submissionData) {
    if (typeof submissionData === "string") {
      let parsedData;
      parsedData = JSON.parse(submissionData);
      if (typeof parsedData === "string") {
        parsedData = JSON.parse(submissionData);
        const fileComponents = parsedData?.components?.filter(
          (component) => component.type === "file"
        );
        const uploadedFiles = fileComponents?.map((fileField) => ({
          key: fileField.key,
          label: fileField.label,
          files:
            fileField.defaultValue?.map((file) => ({
              ...file,
              type: "submission",
              ...file.data,
            })) || [],
        }));

        return uploadedFiles;
      } else {
        const fileComponents = parsedData?.components?.filter(
          (component) => component.type === "file"
        );
        const uploadedFiles = fileComponents?.map((fileField) => ({
          key: fileField.key,
          label: fileField.label,
          files:
            fileField.defaultValue?.map((file) => ({
              ...file,
              type: "submission",
              ...file.data,
            })) || [],
        }));

        return uploadedFiles;
      }
    }
  }

  function consolidateFiles(data) {
    return data.reduce((acc, item) => {
      if (item.files && item.files.length > 0) {
        acc.push(...item.files);
      }
      return acc;
    }, []);
  }

  const updateComments = (newComments) => {
    const mergedComments = [
      ...new Map(
        [...commentData, ...newComments].map((comment) => [
          comment.commentId,
          comment,
        ])
      ).values(),
    ];
    const sortedComments = sortComments(mergedComments, sortOrder);
    const filteredComments = filterComments(sortedComments, searchTerm);
    const lastIsDocumentRequired = filteredComments[filteredComments.length - 1]?.isDocumentRequired;
    applicationStore.setDocumentRequired(lastIsDocumentRequired)
    setCommentData(filteredComments);
  };

  const handleRemove = async (file, SubmissionInfo) => {
    setDocumentLoadingStates((prevStates) => ({
      ...prevStates,
      [file.uid]: true, // Set loading for the specific file
    }));
    try {
      const queryParams = new URLSearchParams({
        fileId: file.uid,
        submissionId: SubmissionInfo?.submissionId,
        userId: SubmissionInfo?.userId,
      });

      const response = await fetch(
        `${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/delete?${queryParams.toString()}`,
        { method: "DELETE" }
      );

      const resultData = await response.json();
      if (response.ok && resultData.status) {
        setFileList((prevFileIds) =>
          prevFileIds.filter((fileId) => fileId !== file.uid)
        );
        setFileData((prevFileList) =>
          prevFileList.filter((item) => item.uid !== file.uid)
        );

        message.success({
          content: `${file.name} deleted successfully.`,
          style: { textAlign: "right" },
        });
      } else {
        Swal.fire({
          title: "Error",
          text: resultData.message || "Unable to delete the file.",
          icon: "error",
          confirmButtonColor: "#44637e",
        });
      }
    } catch (error) {
      console.error("Delete error:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while deleting the file.",
        icon: "error",
        confirmButtonColor: "#44637e",
      });
      message.error(`${file.name} delete failed.`);
    } finally {
      setDocumentLoadingStates((prevStates) => ({
        ...prevStates,
        [file.uid]: false, // Reset loading for the specific file
      }));
    }
  };
  useEffect(() => {
    const fetchData = () => {
      try {
        if (props?.form?.baseId) {
          fetchCommentDetails();
        }
      } catch (error) {
        console.error("Error fetching comment details:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 20000);

    return () => clearInterval(intervalId);
  }, [apiStore, searchTerm, sortOrder]);

  useEffect(() => {
    if (fileList.length > 0) {
      setCreateComment((prevComment) => {
        return {
          ...prevComment,
          documentIds: [...(prevComment.documentIds || []), fileList],
        };
      });
    }
  }, [fileList]);

  const handleDownload = (data) => {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/download?fileId=${data.fileId
      }`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getInitials = (userName) => {
    if (!userName) return "";
    const [firstName, lastName] = userName
      ?.split(" ")
      .map((name) => name.trim());
    const firstInitial = firstName ? firstName[0] : "";
    const lastInitial = lastName ? lastName[0] : "";

    return `${firstInitial}${lastInitial}`.toUpperCase();
  };

  const sortComments = (comments, order) => {
    if (order === "OldNew") {
      return [...comments].sort(
        (a, b) => new Date(a.commentDate) - new Date(b.commentDate)
      );
    } else if (order === "NewOld") {
      return [...comments].sort(
        (a, b) => new Date(b.commentDate) - new Date(a.commentDate)
      );
    } else if (order === "NewestTop") {
      return [...comments].sort(
        (a, b) => new Date(b.commentDate) - new Date(a.commentDate)
      );
    }
    return comments;
  };

  const filterComments = (comments, term) => {
    if (!term) return comments;
    return comments.filter(
      (comment) =>
        comment?.userName?.toLowerCase().includes(term) ||
        comment?.comments?.toLowerCase().includes(term)
    );
  };

  const handleSort = (e) => {
    const order = e.key;
    setSortOrder(order);

    const sortedComments = sortComments(commentData, order);
    setCommentData(sortedComments);
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    let filteredComments = filterComments(commentData, term);
    const sortedComments = sortComments(filteredComments, sortOrder);

    setCommentData(sortedComments);
  };

  const menu = (
    <Menu onClick={handleSort}>
      <Menu.Item key="NewOld"> New to Old</Menu.Item>
      <Menu.Item key="OldNew"> Old to New</Menu.Item>
    </Menu>
  );

  const handleComment = async () => {
    let isCommentDoc =
    createComment?.documentIds?.[createComment?.documentIds?.length - 1]
      ?.length > 0;
      if ((createComment?.emailText || createComment?.documentIds?.length > 0)) {
      setError("");
      setIsAddingComment(true);
      if (
        (createComment?.emailText?.trim() &&
          createComment?.emailText?.trim() !== "<p></p>") ||
        isCommentDoc 
        
      ) {
      const request = {
        submissionId: submissionInfo?.submissionId,
        parentId: null,
        baseId: props?.form?.baseId,
        comments: createComment?.emailText,
        isPublic: true,
        documentIds:
          createComment?.documentIds?.[createComment?.documentIds?.length - 1], // Assuming this is an array or list of IDs
        residentKey: submissionInfo?.residentKey,
        residentContactKey: submissionInfo?.residentContactKey,
      };

      try {
        setLoading(true);
        const response = await apiStore.FormsApiClient.MakeNewRequestComment(
          JSON.stringify(request)
        );
        if (response?.status == 200) {
          setLoading(false);
          setIsAddingComment(false);
          if (response?.data?.status) {
            fetchCommentDetails();
            setFileList([]);

            setCreateComment({ documentIds: [] });
          } else {
            setLoading(false);
            setIsAddingComment(false);
            Swal.fire({
              title: "Error",
              text: "Unable to create the request.",
              icon: "error",
              confirmButtonColor: "#44637e",
            });
          }
        } else {
          setLoading(false);
        }
        setIsAddingComment(false);
      } catch (error) {
        setLoading(false);
        console.error("Error submitting data:", error);
        Swal.fire({
          title: "Error",
          text: "An error occurred while submitting the data.",
          icon: "error",
          confirmButtonColor: "#44637e",
        });
        setIsAddingComment(false);
      }}
    } else {
      setLoading(false);
      setError("Please write comment or document");
    }
  };
  const handleClear = async () => {
    setFileList([]);
    setCreateComment({ documentIds: [] });
  };


  const handleShowMore = (commentText, documents) => {
    setModalContent({ commentText, documents });
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalContent({ commentText: "", documents: [] });
  };
  const handlePost = ()=>{
    let isCommentDoc =
    createComment?.documentIds?.[createComment?.documentIds?.length - 1]
      ?.length > 0;
    if(applicationStore?.isDocument && !isCommentDoc){
      Swal.fire({
        title: "Are you sure post this comment without document",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5a7890",
        cancelButtonColor: "#BFBFBF",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          handleComment()
        } else {
          console.log('later')
        }
      });
    }else{
      handleComment();
    }
  }
  const ViewComments = () => {
    return (
      <div>
        <p
          className="m-0 comment-text"
          dangerouslySetInnerHTML={{
            __html: modalContent.commentText,
          }}
        />

        <div className="modal-documents-wrap">
          {modalContent.documents.map((file, index) => {
            const fileIcon = CommonFuncitons.getFileIcon(
              file?.originalFileName
            );
            const fileExtension = file?.originalFileName?.split('.').pop()?.toLowerCase();
            const isViewable = CommonFuncitons?.viewableExtensions?.includes(fileExtension);
            return (
              <div className="d-flex align-items-center gap-1" key={index}>
                <span
                  className="document-icon-wrap"
                  onClick={() => isViewable ? props?.handleViewFile(file?.fileId) : handleDownload(file)}
                >
                  <img className="doc-icon" src={fileIcon} alt="File Icon" />
                </span>
                <p  className="comment-btn mb-0 comment-doc-attachment"  onClick={() => isViewable ? props?.handleViewFile(file?.fileId) : handleDownload(file)}>
                  {file?.originalFileName}
                </p>
              </div>
            );
          })}
        </div>
        <Button key="close" onClick={handleCloseModal}>
          Close
        </Button>
      </div>
    );
  };
  return (
    <>
      {loading && <Spinner />}
      <ModalComponent
        title="Full Comment and Documents"
        open={isModalVisible}
        centered="centered"
        onCancel={handleCloseModal}
        Cancel={handleCloseModal}
        width={1200}
        footer={false}
        children={ViewComments}
      ></ModalComponent>
      <div className="content-wrapper comment-scroll-wrapper">
        <div className="comment-header">
          <p className="secondary-heading">Comments</p>
          <div className="comment-header-inner-wrap">
            <TextInput
              className="comment-search-bar-wrapper mb-0"
              value={searchTerm}
              onChange={handleSearch}
              icon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z"
                    stroke="#757575"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
              placeholder={"Search here"}
            />
            <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
              <div
                className="comment-filter-wrap"
                onClick={() => {
                  setVisible(!visible);
                }}
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.6665 17.1667V8.83334M6.6665 17.1667L4.1665 14.6667M6.6665 17.1667L9.1665 14.6667M13.3332 3.83334V12.1667M13.3332 3.83334L15.8332 6.33334M13.3332 3.83334L10.8332 6.33334"
                    stroke="#606060"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>Filter</span>
              </div>
            </Dropdown>
          </div>
        </div>

        <div className="comment-scroll-box">
          {commentData?.length > 0 ? (
            commentData?.length > 0 &&
            commentData?.map((data, i) => {
              const commentText = data?.comments || "";
              const isTruncated = commentText.length > 230;
              const truncatedComment = commentText.substring(0, 230);
              const documents = data?.documents || [];
              const displayDocuments = documents.slice(0, 3); // Show the first 3 documents outside the modal
              const remainingDocumentCount = documents.length - 3;
              return (
                <>
                  <div className="comment-message-wrap">
                    <div className="comment-header-wrap">
                      <div className="comment-profile-box">
                        <span className="comment-profile-icon">
                          {`${getInitials(data?.userName)}`}
                        </span>
                        <p className="comment-profile-name">
                          {`${data?.userName} (${data?.roleName})` ?? ""}
                        </p>
                      </div>
                      <div>
                        <p className="comment-date">
                          {formatDate(data?.commentDate)}
                        </p>
                      </div>
                    </div>
                    <div>
                    <div className="shown-comment-text-wrap">
                      <p
                        className="m-0 comment-text break-word"
                        dangerouslySetInnerHTML={{ __html: truncatedComment }}
                      />
                           {isTruncated && (
                         <div className="see-more">
                           <Tag
                            onClick={() =>
                              handleShowMore(commentText, documents)
                            }
                            className="see-more-btn"
                          >
                            See More
                          </Tag>
                         </div>
                        )}
                        </div>
                    </div>
                    <div className="document-row-wrapper">
                      {/* {data?.documents?.length > 0 &&
                        data?.documents?.map((file, index) => {
                          const fileIcon = CommonFuncitons.getFileIcon(file?.originalFileName);
                          return (
                            <>
                              <div
                                className="document-row-wrap mb-1"
                                onClick={() => handleViewFile(file?.fileId)}
                              >
                                <span className="document-icon-wrap">
                                  <img className="doc-icon" src={fileIcon} />
                                </span>
                                <p className="mb-0 comment-doc-attachment">
                                  {file?.originalFileName}
                                </p>
                              </div>
                            </>
                          );
                        })} */}
                        {displayDocuments?.map((file, index) => {
                        const fileIcon = CommonFuncitons.getFileIcon(
                          file?.originalFileName
                        );
                        const fileExtension = file?.originalFileName?.split('.').pop()?.toLowerCase();
                        const isViewable = CommonFuncitons?.viewableExtensions.includes(fileExtension);
                        const isDownloadable = CommonFuncitons?.downloadableExtensions.includes(fileExtension);
                        return (
                          <>
                            <span
                              className="document-icon-wrap"
                              
                              onClick={() => isViewable ? props?.handleViewFile(file?.fileId) : handleDownload(file)}
                            >
                              <img
                                className="doc-icon"
                                src={fileIcon}
                                alt="File Icon"
                              />
                            </span>
                            <p  onClick={() => isViewable ? props?.handleViewFile(file?.fileId) : handleDownload(file)} className="comment-btn mb-0 comment-doc-attachment"  >
                              {file?.originalFileName}
                            </p>
                          </>
                        );
                      })}
                      {remainingDocumentCount > 0 && (
                        <Tag
                         onClick={() => handleShowMore(commentText, documents)}
                          className="see-more-documents-btn"
                        >
                          {`+${remainingDocumentCount} more documents`}
                        </Tag>
                      )}
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <p>No comments yet.</p>
          )}
        </div>

        {(form?.submissionStatus !== null && form?.submissionStatus  !== undefined && form?.submissionStatus  !== "") && FileHelper?.status?.includes(form?.submissionStatus)  && <><div className="comment-section-container pt-4">
          <div className="comment-content-grid">
            <div className="uploaded-files">
              {fileData.map((file) => (
                <div key={file.uid} className="uploaded-file-item">
                  <span>
                    <PaperClipOutlined /> {file.name}
                  </span>
                  <span
                    // onClick={() => handleRemove(file, submissionInfo)}
                    style={{ cursor: "pointer" }}
                  >
                    {documentLoadingStates[file.uid] ? (
                      <LoadingOutlined style={{ marginLeft: 8 }} />
                    ) : (
                      <DeleteOutlined
                        onClick={() => handleRemove(file, submissionInfo)}
                        style={{ marginLeft: 8, cursor: "pointer", color: "red" }}
                      />
                    )}

                  </span>
                </div>
              ))}

            </div>
            <Editor
              ref={editorRef}
              edit={createComment.isEdit}
              value=""
              formInfo={createComment}
              setForm={setCreateComment}
            />
            {createComment?.emailText &&
              createComment.emailText.trim() !== "<p></p>" ? (
              ""
            ) : (
              <span className="comment-pannel-label">Write a comment...</span>
            )}
            <div className="comment-pannel-bottom-row">
              <div className="comment-additional-icon-wrap">
                <UploadButton
                  setDocumentLoading={setDocumentLoading}
                  documentLoading={documentLoading}
                  fileList={fileList}
                  setFileList={setFileList}
                  fileData={fileData}
                  setFileData={setFileData}
                  SubmissionInfo={submissionInfo}
                  files={createComment}
                  setDocumentLoadingStates={setDocumentLoadingStates}
                />
              </div>
              <div className="comment-pannel-buttons-wrap">
                {(createComment?.emailText &&
                  createComment?.emailText?.trim() !== "<p></p>" &&
                  createComment?.emailText.trim() !== "<p></p>") ||
                  createComment?.documentIds?.[
                    createComment?.documentIds?.length - 1
                  ]?.length !== 0 ? (
                  <Button
                    className="clear-button"
                    disabled={isAddingComment}
                    onClick={() => handleClear()}
                  >
                    Clear
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  className="Add-comment-button"
                 // disabled={isAddingComment}
                  onClick={() => handlePost()}
                >
                  Post
                </Button>
              </div>
            </div>
          </div>
        </div>
        <p className="text-danger"> {error ?? ""}</p></>}
      </div>
    </>
  );
};

export default Comments;
