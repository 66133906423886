import { AxiosResponse } from "axios";
import MobileWebApiClient, { Tuple } from "../base/MobileWebApiClient";
import { ComplianceConcern, ComplianceConcernHistory, DirectoryProfile } from "../Association/AssociationTypes";
import {
  AccountTransactionItem,
  AddToCart,
  ContactInfoChangeItem,
  ContactInfoItem,
  CountResponse,
  EntryAccessRequestItem,
  FeeWaiverItem,
  MaintenanceRequestHistory,
  MaintenanceRequestItem,
  ResidentDirectoryProfile,
  ResidentDirectoryProfileTransfer
} from "./FormsTypes";
import { getPhoneFormat } from "../../helpers/phoneFormat";
import { List } from "echarts";
import { CardItem, CardItemList, CardItemResponse, EditCardItem, SubmissionForm,SubmissionDetail,FormResponses } from "../Common/CommonTypes";
import ApiStore from "../../stores/appStores/ApiStore";

export default class FormsApiClient extends MobileWebApiClient {
  public addFeeWaiverItem = async (feeWaiverItem: FeeWaiverItem): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost('user/feewaiversend', feeWaiverItem);
  }
  
  public addEntryAccessRequestItem = async (entryAccessRequestItem: EntryAccessRequestItem): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost('user/entryaccessrequestsend', entryAccessRequestItem);
  }

  public addMaintenanceRequest = async (maintenanceRequestItem: MaintenanceRequestItem): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost('user/maintenancerequestsend', maintenanceRequestItem);
  }

  public addContactInfoChange = async (contactInfoChangeItem: ContactInfoChangeItem): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost('user/ChangeOfResidentAddress', contactInfoChangeItem);
  }

  public async getMaintenanceHistory(): Promise<MaintenanceRequestHistory> {
    const getMaintenanceHistory = await this.tryPost(
      "sharepoint/getmaintenancerequesttasklist",
      {
        Item1: this._configApi.community.communityId,
        Item2: this._configApi.community.office,
        Item3: this._configApi.community.residentKey,
      }
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => false);
    return getMaintenanceHistory;
  }

  public async getGetAccountTransactions(): Promise<Array<AccountTransactionItem>> {
    var res = await this.tryPost('community/GetAccountTransactions', this._configApi.community);
    return res.data.map((item: AccountTransactionItem): AccountTransactionItem => ({
      ...item,
      date: new Date(item.date),
    }))
  }

  public async GetOwnerContactInfo(): Promise<ContactInfoItem | null> {
    return await this.post("user/GetOwnerContactInfo", this._configApi.community)
    .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
    .catch(() => null);
  }

  public getFile = async (url: string): Promise<AxiosResponse> => {
    return await this.tryPost('sharepoint/getfile', {
      "Item1": url,
      "Item2": this._configApi.community.communityId,
      "Item3": this._configApi.community.office,
    });
  }

  public async getComplianceDisclaimer(): Promise<Tuple<boolean, string>> {
    const getComplianceDisclaimerResponsive = await this.tryPost(
      "community/checkshowcompliancedisclamer",
      this._configApi.community.communityId
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);
    return getComplianceDisclaimerResponsive;
  }

  public async getResidentDirectoryProfile(): Promise<ResidentDirectoryProfile> {
    const getComplianceFormResponsive: ResidentDirectoryProfileTransfer = await this.tryPost(
      "community/getcommunityresidentdirectoryprofile",
      {
        item1: this._configApi.userEmail,
        item2: this._configApi.community.residentKey,
      } as Tuple<string, number>, true
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);

    const profile = {} as ResidentDirectoryProfile;
    profile.EmailAddress = getComplianceFormResponsive?.email;
    if(profile.EmailAddress == undefined){
      profile.EmailAddress = this._configApi.userEmail;
    }
    if (getComplianceFormResponsive?.cellPhone.length > 0) {
      profile.DaytimePhone = getComplianceFormResponsive.cellPhone;
    } else if (getComplianceFormResponsive?.homePhone.length > 0) {
      profile.DaytimePhone = getComplianceFormResponsive.homePhone;
    }
    profile.DaytimePhone = getPhoneFormat(profile.DaytimePhone);
    return profile;
  }

  public async saveComplianceForm(data: ComplianceConcern): Promise<boolean> {
    const saveComplianceFormResponsive = await this.tryPost(
      "user/complianceconcernsend",
      {
        UserId: this._configApi.userId,
        Community: this._configApi.community,
        ...data,
      } as ComplianceConcern
    )
      .then((res) => (res.statusText === "OK" ? true : false))
      .catch(() => false);
    return saveComplianceFormResponsive;
  }

  public async getComplianceHistory(): Promise<ComplianceConcernHistory> {
    const getComplianceHistory = await this.tryPost(
      "sharepoint/getcomplianceconcerntasklist",
      {
        Item1: this._configApi.community.communityId,
        Item2: this._configApi.community.office,
        Item3: this._configApi.community.residentKey,
      }
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => false);
    return getComplianceHistory;
  }

  public async GetDisclaimersAndFee(residentKey: string): Promise<Object | null> {
    try {
      const response = await this.tryTenantGet(
        `user/GetDisclaimersAndAdditionalFee?propertyId=${this._configApi.community.communityId}`  // Modify the URL to include the Id parameter
      );
      return response ? response : null;
    } catch (error) {
      console.error('Error fetching disclaimers and fee:', error);
      return null;
    }
  } 

  public async GetPropertyAndUserInformation(residentKey: string): Promise<Object | null> {
    try {
      const response = await this.tryGetLocalhost(
        `api/tenant/GetPropertyAndUserInformation?userId=${this._configApi.userId}&residentKey=${this._configApi.community.residentKey}`  // Modify the URL to include the Id parameter
      );
      return response ? response : null;
    } catch (error) {
      console.error('Error fetching disclaimers and fee:', error);
      return null;
    }
  } 

  public PostTenantRegistrationForms = async (tenantRequest: Object): Promise<AxiosResponse<Object>> => {
    return await this.tryPostLocalHost('api/tenant/TenantRegistrationV2', tenantRequest, "multipart/form-data");
  }
  // public ProcessTenantPayment1 = async (paymentRequest: Object): Promise<AxiosResponse<Object>> => {
  //   return await this.tryPostHub('api/Payment/TenantPayment', paymentRequest);
  // }
  public ProcessTenantPayment = async (paymentRequest: Object): Promise<AxiosResponse<Object>> => {
    return await this.tryPostLocalHost('api/tenant/TenantPayment', paymentRequest);
  }
  public GetAllTenantSubmissions = async (request : Object): Promise<AxiosResponse<Object | null>> => {
    return await this.tryPostLocalHost(`api/tenant/GetAllTenantSubmissions`, request);
  }
  public GetTenantSubmissionDetails = async (id : number): Promise<AxiosResponse<Object | null>> => {
    return await this.tryGetLocalhost(`api/tenant/GetTenantSubmissionDetailsById?id=${id}`);
  }
  public UpdateTenantRequestStatus = async (id:number, status:string, reason: string, userId :string): Promise<AxiosResponse<Object | null>> => {
    return await this.tryPostLocalHost(`api/tenant/UpdateTenantRequestStatus?id=${id}&status=${status}&reason=${reason}&approvalBy=${userId}`);
  }
  public async GetAllStatesCitiesAndCommunities(): Promise<Object | null> {
    try {
      const response = await this.tryTenantGet(
        `user/GetAllStatesCitiesAndCommunities`  // Modify the URL to include the Id parameter
      );
      return response ? response : null;
    } catch (error) {
      console.error('Error fetching GetAllStatesCitiesAndCommunities:', error);
      return null;
    }
  }

  public GetArchForm = async (residentKey : number, residentContactKey : number): Promise<AxiosResponse<Object | null>> => {
    return await this.tryGetArch(`api/Form/getbyresidentandcontactkey?residentKey=${residentKey}&residentContactKey=${residentContactKey}`);
  }//104046, 146314
  public addToCartArch = async (addToCart: AddToCart): Promise<AxiosResponse<boolean>> => {
    return await this.tryPostArch('api/ThirdPartyUserCard/addtocard', addToCart);
  }
  public GetCountAddToCartArch = async (residentKey :string[]): Promise<AxiosResponse<CountResponse>> => {
    return await this.tryPostArch('api/ThirdPartyUserCard/getcarditemcount', residentKey);
  }
  public GetCardItemArch = async (residentKey :string[]): Promise<AxiosResponse<CardItemResponse>> => {
    return await this.tryPostArch('api/ThirdPartyUserCard/getallcarditem', residentKey);
  }
  public CardItemsSave = async (data :any): Promise<AxiosResponse<boolean>> => {
    return await this.tryPostArch('api/ThirdPartySubmission/carditems', data);
  }
  public  GetRequestList=async(data:any): Promise<AxiosResponse<boolean>>=>{
    return await this.tryPostArch('api/ThirdPartySubmission/getbyresidentkey',data );
  }

  public  MakeNewRequest=async(data:any): Promise<AxiosResponse<boolean>>=>{
    return await this.tryPostArch('api/ThirdPartySubmission/createsubmissionfromthirdparty',data );
  }

  public  MakeNewRequestComment=async(data:any): Promise<AxiosResponse<boolean>>=>{
    return await this.tryPostArch('api/ThirdPartySubComments/makenew',data );
  }

  public DeleteItemCart = async (residentKey : string, residentContactKey : string,itemId:string): Promise<AxiosResponse<Object | null>> => {
    return await this.tryDeleteArch(`api/ThirdPartyUserCard/deleteitem?residentKey=${residentKey}&residentContactKey=${residentContactKey}&itemId=${itemId}`);
  }
  public EditCardItem = async (data:EditCardItem): Promise<AxiosResponse<Object | null>> => {
    return await this.tryPutArch(`api/ThirdPartyUserCard/editcarditem`,data);
  }
 
  public EditSubmission = async (data:SubmissionForm): Promise<AxiosResponse<Object | null>> => {
    return await this.tryPutArch(`api/ThirdPartySubmission/editsubmissionfromthirdparty`,data);
  }



  public DeleteSubmission = async (submissionId:string,residentKey : string, residentContactKey : string,status:number): Promise<AxiosResponse<Object | null>> => {
    return await this.tryDeleteArch(`/api/ThirdPartySubmission/deletesubmissionfromthirdparty?submissionId=${submissionId}&residentKey=${residentKey}&residentContactKey=${residentContactKey}&status=${status}`);
  }
 
  public GetCardItemById = async (itemId : string): Promise<AxiosResponse<CardItem>> => {
    return await this.tryGetArch(`api/UserCard/getcarditembyid?itemId=${itemId}`);
  }
  public GetSubmissionDetails = async (submissionId : string): Promise<AxiosResponse<Object | null>> => {
    return await this.tryGetArch(`api/ThirdPartySubmission/getbysubmissionid?submissionId=${submissionId}`);
  }
  public GetCommentDetails = async (submissionId : number,residentKey : string, residentContactKey : string): Promise<AxiosResponse<Object | null>> => {
    return await this.tryGetArch(`api/ThirdPartySubComments/getbysubmissionId?baseId=${submissionId}&residentKey=${residentKey}&residentContactKey=${residentContactKey}`);
  }

  public GetDocumentType = async (hoaId : string): Promise<AxiosResponse<Object | null>> => {
    return await this.tryGetArch(`api/DocumentType/getallbyhoaid?hoaId=${hoaId}`);
  }
  public AddAppealRequest=async(data:any): Promise<AxiosResponse<boolean>>=>{
    return await this.tryPostArch('api/ThirdPartySubmission/appeal',data );
  }
}

export { FormsApiClient };
