import { useEffect, useRef, useState } from "react";
import { Form } from "react-formio";
import { Button, notification } from "antd";
import "formiojs/dist/formio.full.min.css";
import "./MySpecificPage.scss";
import UploadButton from "../UploadButton/CustomUploadButton";
import { useStore } from "../../root-store-context";
const CustomForms = ({
  formRef,
  showPaymentSection,
  setShowPaymentSection,
  forms,HandleAddToCart,
  isFeeNotRequired,
  getArchFormResponse,
  fileList,
  setFileList,
  isSubmit
}) => {
  const {applicationStore} = useStore();
  const handleBuyNowClick = () => {
    setShowPaymentSection(true); // Show payment section when Buy Now is clicked
  };

  const isFormLoaded = forms && Object.keys(forms).length > 0;
  const customFileService = {
    uploadFile: async (
      binaryData,
      fileName,
      name,
      dir,
      progressCallback,
      url,
      options,
      onFinish,
      updateSubmissionData
    ) => {
      try {
        const endpoint = `${process.env.REACT_APP_ARCH_API_ENDPOINT}api/Media/add`;

        const mimeType = fileName?.type
          ? fileName?.type
          : "application/octet-stream";

       // const fileBlob = new Blob([binaryData], { type: mimeType });

        const formData = new FormData();
        formData.append("file", fileName);
        formData.append("name", name);
        formData.append("dir", dir);
        formData.append("userId", applicationStore.submissionDetails?.userId);

        const fetchOptions = {
          method: "POST",
          body: formData,
        };

        const response = await fetch(endpoint, fetchOptions);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (!data?.status) {
          notification["error"]({
            message: "file is not Uploaded",
            description: "",
          });
          throw new Error("file is not Uploaded");
        }
        if (onFinish && typeof onFinish === "function") {
          onFinish(null, data);
        }
        if (
          updateSubmissionData &&
          typeof updateSubmissionData === "function"
        ) {
          updateSubmissionData(data?.data);
        }

        data.filePath = data?.data?.filePath;

        return data;
      } catch (error) {
        console.error("Error during file upload:", error);
        if (onFinish && typeof onFinish === "function") {
          onFinish(error);
        }
      }
    },

    downloadFile: (file) => {
      if (!file || !file.filePath) {
        console.error("Invalid file data:", file);
        return Promise.reject("Invalid file data");
      }
      let filePath = file.filePath;
      if (filePath.startsWith("http://") || filePath.startsWith("https://")) {
        const urlObj = new URL(filePath);
        filePath = urlObj.pathname + urlObj.search;
        filePath = filePath.replace(/^\/+|\/+$/g, "");
      }
      return new Promise((resolve) => {
        window.open(`${process.env.REACT_APP_ARCH_API_ENDPOINT}${filePath}`, "_blank");
        resolve();
      });
    },
  };
  let options = {
    noAlerts: true,
    disableAlerts: true,
    disableSubmitButton: true, // Disable the default submit button
    noAlerts: true,
    // Ensure only valid props are included
    readOnly: false, // Remove this line if unsupported
  }
  const Options = {
    ...options,
    fileService: customFileService,
  };
  return (
    <div>
      <div className="local-bootstrap custom-form-io-design">
        {isFormLoaded && (
          <>
            <Form
              form={forms}
              ref={formRef}
              options={Options}
             
            />
           
          </>
        )}
      </div>
    </div>
  );
};

export default CustomForms;
