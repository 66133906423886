import { observer } from "mobx-react-lite";
import useBreakpoint from 'use-breakpoint'
import { Space, Table, Button, Input, Select, Flex } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { LocalTask, TasksListBlock } from "../../../api/BoardVue";
import { useStore } from "../../../root-store-context";
import { useNavigate } from 'react-router-dom';
import { DateSort, FileNaturalCompare, TextSort } from "../../../helpers/SorterHelper";

const { Column } = Table;


const BoardTasks = observer(() => {

    const { apiStore } = useStore();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<TasksListBlock>();
    const [searchText, setSearchText] = useState("");
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const [statuses, setStatuses] = useState<string[]>();
    const { applicationStore } = useStore();
    const { boardTaskStore: boardTaskStore } = useStore();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setCurrentPage(1);
    }, [response, searchText])

    useEffect(() => {
        onChangeStatus("All Active");
    }, [statuses])

    useEffect(() => {
        try {
            const filteredStatuses = applicationStore.taskStatuses?.filter((el: string) => el);
            const newStatusesUniqWithAll: string[] = Array.from(new Set([...(filteredStatuses || []), "All", "All Active"])).sort();
            setStatuses(newStatusesUniqWithAll);
        } catch (error) {
            console.error("Error fetching SharePoint statuses:", error);
        }
    }, [applicationStore.taskStatuses]);

    const onChangeStatus = (value: string) => {
        let statusFilter = [value]
        setResponse(undefined);
        const fetchFilteredSharePointItems = async () => {
            try {
                setIsLoading(true);
                const tasks = await apiStore.BoardVueApiClient.getTaskListByStatusWithFilter(statusFilter)
                setResponse({ ...response, taskList: tasks, } as TasksListBlock);
                setIsLoading(false);
            }
            catch (error) {
                console.error("Error fetching filtered SharePoint items:", error);
                setIsLoading(false);
            }
        };
        if (value === "All Active" && statuses?.length) {
            statusFilter = statuses.filter(status => !["All", "All Active", "Completed"].includes(status));
            fetchFilteredSharePointItems()
        } else if (statuses?.length) {
            statusFilter = statuses.filter(status => statusFilter.includes(status));
            fetchFilteredSharePointItems()
        }
    };

    const onRowClck = (item: LocalTask) => {
        boardTaskStore.setTaskModel({} as LocalTask);
        boardTaskStore.attachments = [];
        navigate(`/BoardVue/BoardTasks/Edit/${item.id}`);
    }

    const onCreateNewTask = () => {
        boardTaskStore.setTaskModel({} as LocalTask);
        boardTaskStore.attachments = [];
        navigate('/BoardVue/BoardTasks/Create');
    }

    return <>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Flex justify="space-between" wrap="wrap" align="center" gap="16px" style={{ paddingBottom: 24 }}>
                <Flex wrap="wrap" align="center" gap="16px" className="w-mo-100">
                    <h1 className="page-title">Board Tasks</h1>
                    <Input className="serach-bar" prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear
                        onChange={(value) => { setSearchText(value.target.value) }} />
                </Flex>
                <Flex wrap="wrap" align="center" gap="16px" className="w-mo-100" >
                    <Select placeholder="Filter by status"
                    className="select-box"
                        defaultValue="All Active"
                        options={statuses?.map((text) => ({ value: text, label: text }))}
                        onChange={onChangeStatus} />
                    <Button
                        size="middle" type="primary" onClick={onCreateNewTask}> Create </Button>
                </Flex>
            </Flex>


            <Table className="table-wrapper" dataSource={response?.taskList?.map((el, index) => { return { ...el, key: index } })}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                pagination={{ defaultPageSize: 10, showSizeChanger: false, responsive: true, current: currentPage, onChange: (pageNum) => setCurrentPage(pageNum) }}
                loading={isLoading}
                size={breakpoint === "mobile" ? "small" : "large"}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => { onRowClck(record) }, // click row
                    };
                }}>
                <Column title={<span style={{ whiteSpace: 'nowrap' }}>Task ID</span>} dataIndex="id" key="id"
                    sorter={(a: any, b, direction) => FileNaturalCompare(a.id, b.id, direction)}
                    filteredValue={[searchText]}
                />
                <Column title="Task Name" dataIndex="title" key="title"
                    sorter={(a: any, b, direction) => FileNaturalCompare(a.title, b.title, direction)}
                    filteredValue={[searchText]}
                    onFilter={(text, record) => { return String(record.title).toLowerCase().includes(String(text).toLowerCase()) }}
                    render={(text: any) => <div className="wrap-text-anywhere">{text}</div>}
                />
                <Column responsive={["md"]} title="Start Date" dataIndex="startDate" key="startDate"
                    sorter={(a: LocalTask, b: LocalTask) => DateSort(a, b, "startDate")}
                    render={(date) => (<>{new Date(date).toLocaleString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })}</>)} />
                <Column responsive={["md"]} title="Due Date" dataIndex="dueDate" key="dueDate"
                    sorter={(a: LocalTask, b: LocalTask) => DateSort(a, b, "dueDate")}
                    render={(date) => (<>{new Date(date).toLocaleString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })}</>)} />
                <Column responsive={["md"]} title="Modified Date" dataIndex="modified" key="modified"
                    sorter={(a: LocalTask, b: LocalTask) => DateSort(a, b, "modified")}
                    render={(date) => (<>{new Date(date).toLocaleString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })}</>)} />
                {/* <Column title="Assigned To" dataIndex="assignedToNames" key="assignedToNames"
                    sorter={(a: any, b: any) => {
                        return a.assignedToNames.toString().localeCompare(b.assignedToNames.toString())
                    }} /> */}
                <Column title="Requested By" dataIndex="requestedBy" key="requestedBy"
                    sorter={(a: any, b: any) => {
                        return a.requestedBy?.toString().localeCompare(b.requestedBy?.toString())
                    }} />
                <Column title="Priority" dataIndex="priority" key="priority"
                    sorter={(a: LocalTask, b: LocalTask) => TextSort(a, b, "priority")} />
                <Column responsive={["md"]} title="Status" dataIndex="internalTaskStatus" key="internalTaskStatus"
                    sorter={(a: LocalTask, b: LocalTask) => TextSort(a, b, "internalTaskStatus")} />
            </Table>
        </Space>
    </>
})

export default BoardTasks