class CommonFuncitons{
  static viewableExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'pdf', 'doc', 'docx', 'pptx', 'txt', 'rtf', 'xml'];
  static downloadableExtensions = ['csv', 'xlsx', 'zip', 'mp4', 'avi', 'mov', 'mkv', 'flv', 'wmv', 'webm'];
    static getFileIcon = (fileName) => {
        if(!fileName) return ""
        if (fileName.endsWith('.pdf')) return '/images/pdf-icon.png';
      
        if (fileName.endsWith('.csv')) return '/images/Import CSV.png';
      
        if (fileName.endsWith('.zip')) return '/images/ZIP (1).png';
      
        if (fileName.match(/\.(jpg|jpeg|png|gif|bmp|svg)$/i)) return '/images/Image File.png';
      
        if (fileName.match(/\.(docx?|doc)$/i)) return '/images/Microsoft Word 2019.png';
      
        if (fileName.endsWith('.xlsx')) return '/images/Microsoft Excel 2019.png';
        if (fileName.endsWith('.xlx')) return '/images/Microsoft Excel 2019.png';
      
        if (fileName.endsWith('.pptx')) return '/images/Microsoft PowerPoint 2019.png';
      
        if (fileName.endsWith('.txt')) return '/images/TXT (1).png';
        if (fileName.endsWith('.rtf')) return '/images/Image File.png';
        if (fileName.endsWith('.xml')) return '/images/Image File.png';
        if (fileName.match(/\.(mp4|avi|mov|mkv|flv|wmv|webm)$/i)) return '/images/Video.png';
      
        return '/images/Document.png'; 
      };


      // Function to handle status_id and return message
      static getStatusMessage = (status_id) => {
        switch(status_id) {
            case 101:
                return 'Approved';
            case 102:
                return 'AuthOnly';
            case 111:
                return 'Refunded';
            case 121:
                return 'AvsOnly';
            case 131:
                return 'Pending Origination';
            case 132:
                return 'Originating';
            case 133:
                return 'Originated';
            case 134:
                return 'Settled';
            case 135:
                return 'Reserved';
            case 136:
                return 'Rejected';
            case 191:
                return 'Settled (deprecated - batches are now settled on the /v2/transactionbatches endpoint)';
            case 201:
                return 'Voided';
            case 301:
                return 'Declined';
            case 331:
                return 'Charged Back';
            default:
                return null;
        }
      }
      
      
      
      static getReasonCodeMessage = (code) => {
        const codeMessages = {
          0: 'N/A',
          //1000: isAchPayment?'ACH - Accepted':'CC - Approved',
          1001: 'AuthCompleted',
          1002: 'Forced',
          1003: 'AuthOnly Declined',
          1004: 'Validation Failure (System Run Trx)',
          1005: 'Processor Response Invalid',
          1200: 'Voided',
          1201: 'Partial Approval',
          1500: 'Generic Decline',
          1510: 'Call',
          1518: 'Transaction Not Permitted - Terminal',
          1520: 'Pickup Card',
          1530: 'Retry Trx',
          1531: 'Communication Error',
          1540: 'Setup Issue, contact Support',
          1541: 'Device is not signature capable',
          1588: 'Data could not be de-tokenized',
          1599: 'Other Reason',
          1601: 'Generic Decline',
          1602: 'Call',
          1603: 'No Reply',
          1604: 'Pickup Card - No Fraud',
          1605: 'Pickup Card - Fraud',
          1606: 'Pickup Card - Lost',
          1607: 'Pickup Card - Stolen',
          1608: 'Account Error',
          1609: 'Already Reversed',
          1610: 'Bad PIN',
          1611: 'Cashback Exceeded',
          1612: 'Cashback Not Available',
          1613: 'CID Error',
          1614: 'Date Error',
          1615: 'Do Not Honor',
          1616: 'NSF',
          1617: 'Exceeded Withdrawal Limit',
          1618: 'Invalid Service Code',
          1619: 'Exceeded activity limit',
          1620: 'Violation',
          1621: 'Encryption Error',
          1622: 'Card Expired',
          1623: 'Renter',
          1624: 'Security Violation',
          1625: 'Card Not Permitted',
          1626: 'Trans Not Permitted',
          1627: 'System Error',
          1628: 'Bad Merchant ID',
          1629: 'Duplicate Batch (Already Closed)',
          1630: 'Batch Rejected',
          1631: 'Account Closed',
          1650: 'Contact Support',
          1651: 'Max Sending - Throttle Limit Hit (ACH only)',
          1652: 'Max Attempts Exceeded',
          1653: 'Contact Support',
          1654: 'Voided - Online Reversal Failed',
          1655: 'Decline (AVS Auto Reversal)',
          1656: 'Decline (CVV Auto Reversal)',
          1657: 'Decline (Partial Auth Auto Reversal)',
          1658: 'Expired Authorization',
          1659: 'Declined - Partial Approval not Supported',
          1660: 'Bank Account Error, please delete and re-add Account Vault',
          1661: 'Declined AuthIncrement',
          1662: 'Auto Reversal - Processor can\'t settle',
          1663: 'Manager Needed (Needs override transaction)',
          1664: 'Account Vault Not Found: Sharing Group Unavailable',
          1665: 'Contact Not Found: Sharing Group Unavailable',
          1701: 'Chip Reject',
          1800: 'Incorrect CVV',
          1801: 'Duplicate Transaction',
          1802: 'MID/TID Not Registered',
          1803: 'Stop Recurring',
          1804: 'No Transactions in Batch',
          1805: 'Batch Does Not Exist'
        };
      
        // Return the message corresponding to the reason code
        return codeMessages[code] || null;
      };
      
      
      // Function to handle type_id and return message
      static getTypeMessage = (type_id) => {
        switch(type_id) {
            case 20:
                return 'Sale';
            case 21:
                return 'AVS Only';
            case 22:
                return 'Settle (deprecated - batches are now settled on the /v2/transactionbatches endpoint)';
            case 30:
                return 'Refund';
            case 40:
                return 'Credit';
            case 50:
                return 'Debit';
            default:
                return null;
        }
      }
      
      static allowedTypes =  {
        image: [
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/bmp",
          "image/tiff",
          "image/webp",
        ],
        video: [
          "video/mp4",
          "video/avi",
          "video/mov",
          "video/mkv",
          "video/flv",
          "video/wmv",
          "video/webm",
        ],
        document: [
         "application/msword", 
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
          "application/vnd.ms-excel", 
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
          "text/csv", 
          "application/vnd.ms-powerpoint", 
          "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
          "application/pdf",
          "application/rtf", 
          "application/zip",
          "text/plain", 
          "application/xml", 
        ],
      }
}

export default CommonFuncitons;