import { Col, Row, Button, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrowIcon } from "../../../svg/BackArrowIcon.svg";

const ProfileInfo = ({ GetUser }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
      <div className="content-wrapper section-divider">
        <div className="view-request-heading">
          <div>
            <Tooltip title="Go Back">
              <Button className="icon-wrap" icon={
                <BackArrowIcon />
              } onClick={goBack} />
            </Tooltip>
          </div>

          <h1 className="secondary-heading">Request Information</h1>
        </div>
        <Row gutter={[8, 8]}>
          {Object.keys(GetUser)?.length > 0 &&
            Object.keys(GetUser).map((key) => (
              <Col xs={24} md={12} lg={8} key={key}>
                <div className="request-information-wrap">
                <strong className="information-label">{key}:</strong>{" "}
                <span className="information-text">{GetUser[key]}</span>
                </div>
              </Col>
            ))}
        </Row>
      </div>
  );
};
export default ProfileInfo;
