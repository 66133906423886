import React, { useEffect, useRef ,useState} from "react";
import { Form as FormioForm } from "react-formio";
import _ from "lodash";
import UploadButton from "../../../components/UploadButton/CustomUploadButton";

const UserForm = (props) => {
  const { GetForm,Edit, forms } = props;
  const formRef = useRef(null);
  const [fileList, setFileList] = useState([]);

  const setReadOnly = (schema) => {
    const newSchema = _.cloneDeep(schema);

    const makeReadOnly = (components) => {
      components?.forEach((component) => {
        component.readOnly = true;
        if (component?.type === "file") {
          if (component?.defaultValue?.length > 0) {
            component?.defaultValue?.forEach((file) => {
              if (file?.data?.filePath) {
                file.url = process.env.REACT_APP_ARCH_API_ENDPOINT + file?.data?.filePath;
                file.filePath = process.env.REACT_APP_ARCH_API_ENDPOINT + file?.data?.filePath;
                file.data.filePath = process.env.REACT_APP_ARCH_API_ENDPOINT + file?.data?.filePath;
              }
            });
          } else {
            component.defaultValue = [{ originalName: "No Files", disabled: true,size:"" }];
          }
        }
  
       
        if (component.components) {
          makeReadOnly(component.components); 
        }
        if (component.columns) {
          component.columns.forEach((column) => {
            if (column.components) {
              makeReadOnly(column.components);
            }
          });
        }
      });
    };

    makeReadOnly(newSchema.components);
    return newSchema;
  };

  const options = {
    readOnly: Edit === "view",
    noSubmit: Edit === "view",
  };

  const readonlyForm = setReadOnly(forms);

  useEffect(()=>{
    if(GetForm?.files){
      const updatedFiles = GetForm?.files.map(file => ({
        ...file,
        fileId:file?.fileId,
        name:file?.originalFileName,
        docTypeId:file?.docTypeId,
        documentType: file?.docTypeName // Adding documentType
    }));
    setFileList(updatedFiles);
    }
 
  },[GetForm?.files])
  const handleViewFile = (fileId) => {
    setTimeout(() => {
      window.open(
        `${process.env.REACT_APP_ARCH_API_ENDPOINT}api/Media/view/?fileId=${fileId?.trim()}`,
        "_blank"
      );
  
    }, 100);
  };
  const handleDownload = (data) => {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_ARCH_API_ENDPOINT}api/Media/download?fileId=${data?.fileId}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  return (
      <div>
        <div className="local-bootstrap section-divider custom-form-io-design">
          <FormioForm
            form={Edit === "view" ? readonlyForm : forms}
            options={options}
            ref={formRef}
          />
          
        
        </div>
      </div>
  );
};

export default UserForm;
