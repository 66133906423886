import { useEffect, useState } from "react";
import { Col, Row, Typography, Card } from "antd";
import { useStore } from "../../../root-store-context";
import { formatDate } from "../../../Common";
import ProfileInfo from "./ProfileInfo";
import FormView from "./ViewForm";
import "./ViewForm.css";
import Swal from "sweetalert2";
import Spinner from "../../../components/Spinner/Spiner";
import Comments from "./Comment";
import Documents from "./Documents";
import { observer } from "mobx-react-lite";
import UploadButton from "../../../components/UploadButton/RectangleUploadButton";

const PermissionsFields = observer((props) => {
  const {
    SubmissionDetail,
    setShowFileViewer,
    setFilePath,
    setFileName,
    handleViewFile
  } = props;
  const requestData = SubmissionDetail;
  var formDetails = requestData?.formData
    ? JSON.parse(requestData?.formData)
    : null;


  return (
    <>
      <div className="local-bootstrap section-wrapper mb-1">
        <Row justify={'center'}>
          <Col sm={18}>
            <p className="secondary-heading mb-1">View Submission Request</p>
            <FormView
              Edit={"view"}
              GetForm={requestData}
              isSupportedDocument={formDetails?.isSupportedDocument}
              components={formDetails?.components}
              id={requestData?.id}
              unitId={requestData?.unitId}
              setShowFileViewer={setShowFileViewer}
              setFilePath={setFilePath}
              setFileName={setFileName}
            />
          </Col>
        </Row>
      </div>

      <div className="comment-doc-wrapper">
        <div className="section-wrapper comment-component-wrap">
          <Comments
            handleViewFile={handleViewFile}
            form={requestData}
            submissionId={requestData?.submissionId}
          />
        </div>
        <div className="section-wrapper document-component-wrap">
          <Documents
            GetUser={props?.GetUser}
            handleViewFile={handleViewFile}
            form={requestData}
            submissionId={requestData?.submissionId}
          />
        </div>
      </div>
    </>
  );
});

const ArchitecturalRequest = observer(() => {

  const { apiStore, applicationStore } = useStore();

  const [Profile, setProfile] = useState({});
  const [SubmissionDetail, setSubmissionDetail] = useState({});
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [appealDoc, setAppealDoc] = useState([]);

  const getRequestId = () => {
    const path = window.location.pathname;
    const splitPath = path.split("/");
    return splitPath[splitPath.length - 1];
  };

  const handleClose = () => {
    setShowFileViewer(false);
  };
  const submissionStatusMap = {
    0: "Pending",
    1: "Approved",
    2: "Rejected",
    3: "Re-Open",
    4: "Re-Request",
    5: "OnHold",
    6: "Approved with Stipulation",
    7: "Under Committee Review",
    8: "Committee Review Completed",
    9: "Appealed",
    10:"Under Committee Review",
  };
  useEffect(() => {
    setIsLoading(true);
    let id = getRequestId();
    apiStore.FormsApiClient.GetSubmissionDetails(id)
      .then((res) => {
        let obj = {};
        if (res?.status) {
          let requestDetails = res?.data;
          setSubmissionDetail(requestDetails);
          const submissionStatus = submissionStatusMap[requestDetails?.submissionStatus] || "On Hold";
          var formDetails = requestDetails?.formData
    ? JSON.parse(requestDetails?.formData)
    : null;
          applicationStore.setIsSupportedDocRequired(formDetails?.isSupportedDocRequired)
          const user = {
            ["Request Number"]: requestDetails?.requestNumber,
            ["Submitted By"]: requestDetails?.userName,
            ["Submitted Date"]: formatDate(requestDetails?.submittedDate),

            ["Unit Address"]: requestDetails?.unitName,
            ["Submission Status"]: submissionStatus,
            ["Association"]: requestDetails?.communityName,
          };
          setProfile(user);
          setProfile(user);
          applicationStore.setsubmissionDate(user)
         applicationStore.setFileList(requestDetails?.files)
          obj.form = JSON.parse(res?.data?.formData);
          setIsLoading(false);
        } else {
          Swal.fire({
            title: "Error",
            text: res?.data?.message,
            icon: "Error",
            confirmButtonColor: "#44637e",
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [apiStore]);

  useEffect(() => {
    if (SubmissionDetail?.appealDetail?.files) {
      // Map over the files from EditAndViewFormData and add the `documentType` property
      const updatedFiles =
        SubmissionDetail?.appealDetail?.files.map(
          (file) => ({
            ...file,
            fileId: file?.fileId,
            name: file?.originalFileName,
            docTypeId: file?.docTypeId,
            documentType: file?.docTypeName, // Adding documentType
          })
        );
      // Set the fileList state with the updated data
      setAppealDoc(updatedFiles);
    }
  }, [
    SubmissionDetail?.appealDetail
  ]);
  const handleViewFile = (fileId) => {
    setTimeout(() => {
      window.open(
        `${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/view/?fileId=${fileId?.trim()}`,
        "_blank"
      );
      setShowFileViewer(true);
    }, 100);
  };
  const handleDownload = (data) => {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/download?fileId=${data?.fileId}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="submission-page-main-wrapper">
          <Row justify={'center'}>
            <Col sm={24} >
              <div className="voting-profile-info section-wrapper mb-1">
                <ProfileInfo GetUser={Profile} />
              </div>
            </Col></Row>
          <div className="table-wrapper form-wrap-controller">
            <PermissionsFields
              GetUser={Profile}
              SubmissionDetail={SubmissionDetail}
              setShowFileViewer={setShowFileViewer}
              showFileViewer={showFileViewer}
              setFilePath={setFilePath}
              setFileName={setFileName}
              fileName={fileName}
              filePath={filePath}
              handleClose={handleClose}
              handleViewFile={handleViewFile}
            />
          </div>
          {(SubmissionDetail?.reRequestReason || SubmissionDetail?.reason) &&
            <Card title="Reason" className="mt-3">
              <Typography style={{
                maxHeight: "8em",
                overflowY: "auto"
              }}>{SubmissionDetail?.submissionStatus === 4 ? SubmissionDetail?.reRequestReason ?? "No Reason" : SubmissionDetail?.reason ?? "No Reason"}</Typography>
            </Card>
          }
          {SubmissionDetail?.appealDetail && <Card title="Appeal Detail" className="mt-3">
            <p>Reason For Appeal: {SubmissionDetail?.appealDetail?.reasonForAppeal}</p>
            <p>DayTime Phone: {SubmissionDetail?.appealDetail?.dayTimePhone}</p>

            {SubmissionDetail?.appealDetail?.files?.length > 0 && (
              <UploadButton
                appealDoc={true}
                fileList={appealDoc}
                handleDownload={handleDownload}
                handleViewFile={handleViewFile}
                Edit="view"
              />
            )}
          </Card>}
        </div>
      )}
    </>
  );
});

export default ArchitecturalRequest;
