export type Attachment = {
    Name: string,
    Guid: string,
    Data: string,
    Url: string
}

export enum Role {
    Homeowner = 0,
    BoardMember = 1,
}
export type Permission = {
    id: number;
    feature: string;
    menuLabel: string;
    isBoardVuePermission: boolean;
  };
  export type Count = {
    totalCount: number;
  };
export type CommunityProxy = {
    id: number;
    communityId: string;
    name: string;
    address: string;
    residentKey: number;
    isVms: number;
    userId: number;
    role: Role;
    office: string;
    phone: string;
    residentContactKey: number;
};

export type CardItem = {
    itemId: string;
    userId: string;
    hoaName: string;
    communityName: string;
    unitName: string;
    formId: string;
    submissionData: any | null;
    addedDate: string;
    email: string;
    userName: string;
    requestStatus: number;
    hoaId: string;
    communityId: string;
    unitId: string;
    formData: any | null;
    sourceId: number;
    residentContactKey:string | null;
    residentKey:string | null;
};
export type EditCardItem = {
    itemId: string;
    hoaId: string;
    communityId: string;
    residentContactKey: string | null;
    residentKey: string | null;
    formId: string;
    submissionData: any | null;
    formData: any | null;
    sourceId: number;
};
export type FeeDetail = {
    userId: string;
    unitId: string;
    communityId: string;
    hoaId: string;
    submissionFee: number;
    ccFee: number;
    isFeeNotRequired:boolean;
};

export type CardItemList = {
    items: CardItem[];
    feeDetail: FeeDetail;
};
export type ViewForm = {
    itemId: string;
    userId: string;
    hoaId: string;
    hoaName: string;
    communityId: string;
    communityName: string;
    unitId: string;
    unitName: string;
    residentKey: string | null;
    residentContactKey: string | null;
    formId: string;
    submissionData: string;
    formData: string;
};
export type SubmissionForm = {
    submissionId: string;
    userId: string;
    hoaName: string;
    minVotesForApproval:number;
    communityName: string;
    unitName: string;
    submissionStatus:number;
    residentKey: string | null;
    residentContactKey: string | null;
    reason:string|null;
    reRequestReason:string | null;
    submittedDate:string;
    actionDate:string;
    actionBy:string;
    email:string;
    userName:string;
    paymentId:string;
    authId:string;
    cardType:string;
    cardNumber:string;
    submissionFee:number;
    ccFee:number;
    ccAmount:number;
    paidFee:number;
    paymentDate:string;
    approval:boolean|null;
    doneVotes:number;
    hoaId:string;
    communityId:string;
    unitId:string;
    formId: string;
    submissionData: string;
    formData: string;
    sourceId:number|null;
};
export type SubmissionDetail = {
    hoaId:string;
    communityId:string;
    residentKey: string | null;
    residentContactKey: string | null;
    formId: string;
    submissionData: string;
    formData: string;
    sourceId:number|null;
    ccFee:number;
    submissionFee:number;
    cardDetail:{
        cardNumber:string,
        cardHolderName:string,
        cardExpiry:string,
        cvv:string
    }
};
export type CardItemResponse = {
    message: string | null;
    status: boolean;
    data: {
        items: CardItem[];
        feeDetail: FeeDetail;
    };
    totalCount: number;
}
export type FormResponses = {
    message: string | null;
    status: boolean;
    data:CardItem | null;
  };
  export type FormObject = {
    id?: string;
    hoaName?: string;
    versionId?: number;
    status?: number;
    createdBy?: string;
    userId?: string;
    createdDate?: string;
    updatedBy?: string | null;
    updatedDate?: string | null;
    communityName?: string;
    ccFee?: number;
    submissionFee?: number;
    hasCommunityLevelFee?: boolean;
    isFeeNotRequired?: boolean;
    isSupportedDocRequired?: boolean;
    parentFormId?: string;
    formName?: string;
  }
export type AllFeaturesForCommunity = {
    communityId: number;
    endpoint: string | null;
    exceptionText: string | null;
    featureId: number;
    id: number;
    isActive: boolean;
    sharedSecret: any;
}
export type AllFeaturesForBoard = {
    userId : number;
    permissionId: number | null;
    isActive: boolean;
    id:number;
    isBoardVuePermission:boolean
}

export type Pagination = {
    current?: number;
    pageSize?: number;
    filter?: string;
    total?: number; 
    submissionStatus?:string;
    field?:string;
    order?:string;
  }

export type AllFeaturesForCommunityObject = {
    CreateArchitecturalRequestFeature?: AllFeaturesForCommunity,
    FAQMenuFeature?: AllFeaturesForCommunity,
    MailArchiveMenuFeature?: AllFeaturesForCommunity,
    eStatementsMenuFeature?: AllFeaturesForCommunity,
    eTrakRoomSchedules?: AllFeaturesForCommunity,
    MembershipCard?: AllFeaturesForCommunity,
    NOTUSED?: AllFeaturesForCommunity,
    TicketsandFitness?: AllFeaturesForCommunity,
    GuestPasses?: AllFeaturesForCommunity,
    PayandViewBalance?: AllFeaturesForCommunity,
    Compliance?: AllFeaturesForCommunity,
    ArchitecturalRequests?: AllFeaturesForCommunity,
    ContactsandLinks?: AllFeaturesForCommunity,
    ResidentDirectory?: AllFeaturesForCommunity,
    PropertyDocuments?: AllFeaturesForCommunity,
    ComplianceConcernemail?: AllFeaturesForCommunity,
    MaintenanceRequestemail?: AllFeaturesForCommunity,
    ContactInfoChange?: AllFeaturesForCommunity,
    FeeWaiver?: AllFeaturesForCommunity,
    Notifications?: AllFeaturesForCommunity,
    ComplianceConcernSP?: AllFeaturesForCommunity,
    MaintenanceRequestSP?: AllFeaturesForCommunity,
    EntryAccessRequest?: AllFeaturesForCommunity,
    Election?: AllFeaturesForCommunity,
    ABDIGatePasses?: AllFeaturesForCommunity,
    SCHHTickets?: AllFeaturesForCommunity,
    SCHHFitnessClasses?: AllFeaturesForCommunity,
    TicketsandRecreation?: AllFeaturesForCommunity,
    TenantRegistration?: AllFeaturesForCommunity,
    ArchitecturalRequest?: AllFeaturesForCommunity,
}