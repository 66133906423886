import { observer } from "mobx-react-lite";
import useBreakpoint from 'use-breakpoint'
import { Table, Button, Input, Flex, Select, Spin, App } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useStore } from "../../root-store-context";
import { DateSort, TextSort } from "../../helpers/SorterHelper";
import { dateValidation } from "../../helpers/dateValidation";
import Title from "antd/es/typography/Title";
import { AccountTransactionItem, PayAccountFeesAndCharges, PayAccountItem } from "../../api/AccountSummary/AccountSummaryTypes";

const { Column } = Table;

const PayAndViewBalance = observer(() => {
    const { message } = App.useApp();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accountItems, setAccountItems] = useState<Array<PayAccountItem>>([]);
    const [selectedAccount, setSelectedAccount] = useState<string>("");
    const [accountTransactionItems, setAccountTransactionItems] = useState<Array<AccountTransactionItem>>([]);
    const [accountFeesAndChargesItems, setAccountFeesAndChargesItems] = useState<Array<PayAccountFeesAndCharges>>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageFees, setCurrentPagFees] = useState(1);
    const [selectedBalance, setSelectedBalance] = useState(0);

    const { apiStore, modalViewerStore } = useStore();
    const [searchText, setSearchText] = useState("");
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')

    useEffect(() => {
        setCurrentPage(1);
    }, [accountTransactionItems, searchText])

    useEffect(() => {
        setIsLoading(true);
        apiStore.AccountSummaryApiClient.getAccountsBalance().then(async res => {
            setAccountItems(res);
            if (res.length > 0) {
                setSelectedAccount(res[0].residentAccount.toString())
                setSelectedBalance(res[0].balance)
                loadTransactionByAccount(res[0].residentAccount.toString());
            } else {
                setIsLoading(false);
            }
        }).catch(error => {
            setIsLoading(false)
        })
    }, [])

    const loadPayUri = async (accountNumber: string): Promise<string | null> => {
        return await apiStore.AccountSummaryApiClient.getPayUri(accountNumber).then(res => {
            return res
        }).catch(error => {
            console.log("loadTransactionByAccount: ", error);
            return null;
        })
    }

    const loadingPay = async () => {
        message.open({
            type: 'loading',
            content: 'Opening pay online page!',
            key: 'loadingPay'
        });
        const payUri = await loadPayUri(selectedAccount);
        if (payUri !== null) {
            modalViewerStore.openPageByUrl(payUri, "PAY ONLINE");
        } else {
            message.open({
                type: 'error',
                content: 'Don`t load pay URI please try agrain later',
                key: 'error'
            });
        }
        message.destroy("loadingPay");
    }

    const loadTransactionByAccount = async (accountNumber: string): Promise<void> => {
        setIsLoading(true);
        apiStore.AccountSummaryApiClient.getAcccountTransactionByAccount(accountNumber).then(res => {
            setAccountTransactionItems(res.transactions);
            setAccountFeesAndChargesItems(res.feesAndCharges);
            setIsLoading(false);
        }).catch(error => {
            console.log("loadTransactionByAccount: ", error);
            setIsLoading(false);
        })
    }
    const onChangeStatus = (value: string) => {
        setSelectedAccount(value)
        loadTransactionByAccount(value)
    };
    return (
        <Spin spinning={isLoading}>
            <Flex justify="flex-start" wrap="wrap" align="center" gap="middle" style={{ padding: 10 }}>
                <Flex flex={"1 1 500px"} wrap="wrap">
                    <h2 style={{ flex: "0 0 230px" }}>Pay and View Balance</h2>
                    <Input prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px', flex: "0 0 250px" }}
                        onChange={(value) => { setSearchText(value.target.value) }} />
                </Flex>
                <Flex justify="space-between" flex={"1 1 500px"} align="center" wrap="wrap" gap="middle">
                    <Select placeholder="Filter by status"
                        style={{ flex: "1 1 230px" }}
                        value={selectedAccount?.toString()}
                        options={accountItems?.map((account) => ({ value: account.residentAccount.toString(), label: account.accountName }))}
                        onChange={onChangeStatus} />
                    <Flex align="center" gap={10} wrap="wrap" justify="flex-end" flex={"2 1 250px"}>
                        <Flex wrap="nowrap"><div>Amount Due: <b> ${Number(selectedBalance).toFixed(2)}</b></div></Flex>
                        <Button size="middle" type="primary"
                            onClick={loadingPay}>PAY ONLINE</Button>
                    </Flex>
                </Flex>
            </Flex>
            <Title level={4}>Transaction History</Title>
            <Table dataSource={accountTransactionItems.map((el, index) => { return { ...el, key: index } })}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: false,
                    responsive: true,
                    current: currentPageFees,
                    onChange: (pageNum) => setCurrentPagFees(pageNum)
                }}
                size={breakpoint === "mobile" ? "small" : "large"}>
                <Column width="20%" title="Date" dataIndex="date" key="date"
                    sorter={(a, b) => DateSort(a, b, "date")}
                    render={(date) => dateValidation(date)}
                />
                <Column width="40%" title="Description" dataIndex="description" key="description"
                    filteredValue={[searchText]}
                    sorter={(a, b) => TextSort(a, b, "description")}
                    onFilter={(text, record) => {
                        return (record as AccountTransactionItem).description.toString().toLowerCase().includes(String(text).toLowerCase())
                    }}
                />
                <Column width="20%" title="Charges" dataIndex="charges" key="charges"
                    render={(date) => date ? `$${Number(date).toFixed(2)}` : ''}
                    sorter={(a, b) => TextSort(a, b, "charges")}
                />
                <Column width="20%" title="Credits" dataIndex="credits" key="credits"
                    render={(date) => date ? `$${Number(date).toFixed(2)}` : ''}
                    sorter={(a, b) => TextSort(a, b, "credits")}
                />
            </Table>
            <Title level={4}>Fees and Charges</Title>
            <Table dataSource={accountFeesAndChargesItems.map((el, index) => { return { ...el, key: index } })}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: false,
                    responsive: true,
                    current: currentPage,
                    onChange: (pageNum) => setCurrentPage(pageNum)
                }}
                size={breakpoint === "mobile" ? "small" : "large"}>
                <Column width="50%" title="Description" dataIndex="description" key="description"
                    filteredValue={[searchText]}
                    sorter={(a, b) => TextSort(a, b, "description")}
                    onFilter={(text, record) => {
                        return (record as AccountTransactionItem).description.toString().toLowerCase().includes(String(text).toLowerCase())
                    }}
                />
                <Column width="50%" title="Amount" dataIndex="amount" key="amount"
                    render={(date) => date ? `$${Number(date).toFixed(2)}` : ''}
                    sorter={(a, b) => TextSort(a, b, "amount")}
                />
            </Table>
        </Spin>)

})

export default PayAndViewBalance;
