import { Button, Card, Col, Divider, Form, Row } from "antd";
import React from "react";
import moment from "moment";
import Component from "..";
import Validation from "../../utils/Validation";

const Payment = ({
  data = {},
  handleCancel,
  setData,
  form,
  feeDetails = {},
  handle,
  IsCardItem
}) => {
  const validationRules = Validation.getValidation([
    "firstName",
    "cardNumber",
    "cardHolderName",
    "cvv",
    "cardExpiry",
  ]);
  const { TextInput } = Component;

  const handleCardholderNameChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z ]/g, "");
    const { id } = e.target;

    data[id] = value;
    setData(data);
    form.setFieldsValue(data);
  };

  const handleExpirationDateChange = (e) => {
    let { value } = e.target;
    const { id } = e.target;
    let expirationDateError;
    value = value.replace(/[^0-9\/]/g, "");
    if (value.length > 2 && !value.includes("/")) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    if (value.length === 5) {
      const [month, year] = value.split("/");
      const currentYear = moment().year() % 100;
      const expirationMonth = parseInt(month, 10);
      const expirationYear = parseInt(year, 10);
      if (
        isNaN(expirationMonth) ||
        expirationMonth < 1 ||
        expirationMonth > 12 ||
        isNaN(expirationYear) ||
        expirationYear < currentYear ||
        (expirationYear === currentYear &&
          expirationMonth < moment().month() + 1)
      ) {
        expirationDateError = "Invalid expiration date or date in the past";
      }
    } else if (value.length < 5 && !value.includes("/")) {
      expirationDateError = "Invalid expiration date format";
    }

    data[id] = value;
    setData(data);
    form.setFieldsValue(data);
  };

  const handleCVVChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
    const { id } = e.target;

    data[id] = value;
    setData(data);
    form.setFieldsValue(data);
  };
  const handleCardNumberChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    if (value.length > 16) {
      value = value.slice(0, 16);
    }
    const formattedValue = value.replace(/(.{4})/g, "$1 ").trim();

    data[e.target?.id] = formattedValue;
    setData(data);
    form.setFieldsValue(data);
  };
  const HandleOnChange = (e) => {
    const { value, id } = e?.target;
    if (id === "cardNumber") {
      handleCardNumberChange(e);
    } else if (id === "cardHolderName") {
      handleCardholderNameChange(e);
    } else if (id === "cardExpiry") {
      handleExpirationDateChange(e);
    } else {
      handleCVVChange(e);
    }
  };
  const onFinish = (handle) => {
    data["paymentStatus"] = true;
    handle(data);
  };

  console.log(data, "dataaaaa", form);
  return (
    <>
      {/* <Form
        layout="vertical"
        className="ant-form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{}}
        onFinish={() => {
          onFinish(handle);
        }}
        form={form}
      > */}
        <Row gutter={[16, 16]} justify={"space-between"} style={{flexWrap:'wrap-reverse'}}>
          <Col xs={24} sm={IsCardItem ? 24 : 13}>
            {/* <p className="primary-heading pb-3">Card Details</p> */}
            <Row justify={"space-between"}>
              <Col xs={24} sm={24}>
                <TextInput
                  name="cardNumber"
                  label="Card Number"
                  placeholder="Card Number"
                  hasFeedback={true}
                  validateTrigger={"onChange"}
                  size="large"
                  value={data?.cardNumber?.trim()?data?.cardNumber:""}
                  onChange={HandleOnChange}
                  rules={validationRules?.cardNumber}
                  className="user-form-input"
                />
                <span className="text-danger">
                  {data?.cardNumberError && data?.cardNumberError}
                </span>
              </Col>
              <Col xs={24} sm={24}>
                <TextInput
                  name="cardHolderName"
                  label="CardHolder Name"
                  placeholder="CardHolder Name"
                  hasFeedback={true}
                  validateTrigger={"onChange"}
                  size="large"
                  value={data?.cardholderName}
                  onChange={HandleOnChange}
                  rules={validationRules.cardHolderName}
                  className="user-form-input"
                />
              </Col>
              <Col xs={24} sm={11}>
                <TextInput
                  name="cardExpiry"
                  label="Expiration Date"
                  placeholder="Expiration Date"
                  hasFeedback={true}
                  validateTrigger={"onChange"}
                  size="large"
                  value={data?.cardExpiry}
                  onChange={HandleOnChange}
                  rules={validationRules.cardExpiry}
                  className="user-form-input"
                />
                <span className="text-danger">
                  {data?.expirationDateError && data?.expirationDateError}
                </span>
              </Col>
              <Col xs={24} sm={11}>
                <TextInput
                  name="cvv"
                  label="Cvv"
                  placeholder="Cvv"
                  hasFeedback={true}
                  validateTrigger={"onChange"}
                  size="large"
                  value={data?.cvv}
                  onChange={HandleOnChange}
                  rules={validationRules.cvv}
                  className="user-form-input"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={IsCardItem ? 24 : 11}>
            {!IsCardItem && (
              <Card title="Fee Summary">
                <>
                  <div className="d-flex flex-column justify-content-between">
                    <div className="fee-summary-top-wrap">
                      <div className="fee-summary" style={{ width: "100%" }}>
                        <p
                          className="secondary-heading"
                          style={{ marginRight: "auto" }}
                        >
                          Submission Fee:
                        </p>
                        <span
                          className="amount-text"
                          style={{ textAlign: "right", whiteSpace: "nowrap" }}
                        >
                          $
                          {parseFloat(feeDetails?.submissionFee || 0).toFixed(
                            2
                          )}
                        </span>
                      </div>
                      <Divider />
                      <div className="fee-summary" style={{ width: "100%" }}>
                        <p
                          className="secondary-heading"
                          style={{ marginRight: "auto" }}
                        >
                          CC Fee:
                        </p>
                        <span
                          className="amount-text"
                          style={{ textAlign: "right", whiteSpace: "nowrap" }}
                        >
                          ${parseFloat(feeDetails?.ccFee || 0).toFixed(2)}
                        </span>
                      </div>
                    </div>

                    <Divider />

                    <div className="fee-summary-bottom-wrap">
                      <div className="fee-summary" style={{ width: "100%" }}>
                        <p
                          className="secondary-heading"
                          style={{ marginRight: "auto" }}
                        >
                          Total Amount:
                        </p>
                        <span
                          className="amount-text"
                          style={{ textAlign: "right", whiteSpace: "nowrap" }}
                        >
                          $
                          {(
                            parseFloat(feeDetails?.submissionFee || 0) +
                            parseFloat(feeDetails?.ccFee || 0)
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              </Card>
            )}
        
          </Col>
        </Row>
        {/* <div className="payment-submit" style={{ width: "100%" }}>
              {true && (
                <Button
                  htmlType="button"
                  className="cancel"
                  aria-label="Cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              )}
              <Button
                className="submit-button"
                htmlType="submit"
                aria-label="Submit and Pay"
              >
                Submit & Pay
              </Button>
            </div> */}
      {/* </Form> */}
    </>
  );
};

export default Payment;
