import { observer } from "mobx-react-lite";
import { Table, Button, Input, Flex, Select, Spin, App, Image, Space, Popconfirm } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import { TextSort } from "../../../helpers/SorterHelper";
import useBreakpoint from "use-breakpoint";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountContactDataItem, CommunityUserContactsData } from "../../../api/AccountSummary/AccountSummaryTypes";
import tenantIcon from "../../../images/pageIcons/AccountSummary/tenant.png"
import houseIcon from "../../../images/pageIcons/AccountSummary/house.png"

const { Column } = Table;

const AccountContacts = observer(() => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [communityUserData, setCommunityUserData] = useState<CommunityUserContactsData>();
    const [currentPage, setCurrentPage] = useState(1);
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const navigate = useNavigate();
    const { apiStore } = useStore();
    const { message } = App.useApp();
    const location = useLocation();

    const initializeAccounts = () => {
        setIsLoading(true);
        apiStore.AccountSummaryApiClient.getCommuityUserContactsData().then(async res => {
            if (res) {
                setCommunityUserData(res);
            }
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false)
        })
    }
    useEffect(() => {
        initializeAccounts();
    }, [])
    const successDelete = () => {
        message.open({
            type: 'success',
            content: 'Contact delete successfully.',
        });
    };

    const errorDelete = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during deleting.',
        });
    };
    const removeCommunityUser = (residentKey: number, removeFunction: (residentKey: number) => Promise<boolean>) => {
        message.open({
            type: 'loading',
            content: 'Deleting...',
            key: 'deleting'
        });
        removeFunction(residentKey).then(res => {
            message.destroy("deleting");
            if (res) {
                successDelete();
            } else {
                errorDelete();
            }
            initializeAccounts();
        }).catch(error => {
            message.destroy("deleting");
            errorDelete();
            initializeAccounts();
        });
    };

    const removeCommunityUserContact = (contactKey: number) => {
        return apiStore.AccountSummaryApiClient.removeCommunityUserContact(contactKey);
    };

    const removeCommunityUserTenant = (residentKey: number) => {
        return apiStore.AccountSummaryApiClient.removeCommunityUserTenant(residentKey);
    };

    return (
        <Spin spinning={isLoading}>
            <Flex justify="space-between" wrap="wrap" align="center" gap="16px" style={{ paddingBottom: 24 }}>
                <Flex wrap="wrap" align="center" gap="16px">
                    <h1 className="page-title">Account Contacts</h1>
                </Flex>
                <Button size="middle" type="primary"
                    onClick={() => {
                        navigate(location.pathname+'/Add');
                    }}>Add Contact</Button>
            </Flex>
            <Table className="table-wrapper" dataSource={communityUserData?.conactsList?.map((el, index) => { return { ...el, key: index } })}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: false,
                    responsive: true,
                    current: currentPage,
                    onChange: (pageNum) => setCurrentPage(pageNum)
                }}
                
                size={breakpoint === "mobile" ? "small" : "large"}>
                {/* <Column title="Type" dataIndex="isTenant" key="isTenant"
                    align="center"
                    sorter={(a, b) => TextSort(a, b, "isTenant")}
                    render={(isTenant: boolean) => {
                        if (isTenant) {
                            return <div>
                                <Image src={tenantIcon} preview={false} height={25} title="Tenant" />
                                <div>Tenant</div>
                            </div>
                        } else {
                            return <div>
                                <Image src={houseIcon} preview={false} height={25} title="Household" />
                                <div>Household</div>
                            </div>
                        }
                    }}
                /> */}
                <Column title="Name" dataIndex="contactName" key="contactName"
                    sorter={(a, b) => TextSort(a, b, "contactName")}
                />
                <Column title="Email" dataIndex="email" key="email"
                    render={(email) => (<div className="wrap-text-anywhere"><a href={"mailto:" + email} target="_blank" rel="noopener noreferrer">{email}</a></div>)}
                    sorter={(a, b) => TextSort(a, b, "email")}
                />
                <Column title="Phone" dataIndex="phonesString" key="phonesString"
                    render={(date) => <pre style={{ whiteSpace: "break-spaces" }}><div className="wrap-text-anywhere">{date}</div></pre>}
                    sorter={(a, b) => TextSort(a, b, "phonesString")}
                />
                <Column title="" key="contactKey" align="center"
                    render={(data: AccountContactDataItem) => <div className="wrap-text-anywhere">
                        <Button type="primary" style={{ margin: 10 }} onClick={() => {
                            navigate(location.pathname+`/Edit/${data.residentKey}/${data.contactKey}`);
                        }}>Edit</Button>
                        {data.isTenant && communityUserData?.isPrimaryOwner ?
                            <Popconfirm
                                title="Delete the task"
                                description={<div style={{ width: 250 }}>Are you sure you want to remove this tenant from account?
                                    This tenant will lose access to all AAM systems.</div>}
                                okText="Delete"
                                cancelText="Cancel"
                                onConfirm={() => {
                                    removeCommunityUser(data.residentKey, removeCommunityUserTenant)
                                }}
                            >
                                <Button danger>Delete</Button>
                            </Popconfirm> :
                            <Popconfirm
                                disabled={!(data.contactType === "Contact")}
                                title="Delete the task"
                                description={<div style={{ width: 250 }}>Are you sure you want to permanently remove this contact from account?
                                    This is not reversible and the contact will lose access to all AAM systems.</div>}
                                okText="Delete"
                                cancelText="Cancel"
                                onConfirm={() => {
                                    removeCommunityUser(data.contactKey, removeCommunityUserContact)
                                }}
                            >
                                <Button danger disabled={!(data.contactType === "Contact")}>Delete</Button>
                            </Popconfirm>
                        }
                    </div>}
                />
            </Table>
        </Spin>)

})

export default AccountContacts;
