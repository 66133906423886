import { Spin } from "antd";

const Spiner = ()=>{
    return(
        <>
        <div className="spinner-containers">
        <Spin size="large" />
      </div>
        </>
    )
}

export default Spiner;