import React, { useEffect, useState, useRef } from 'react';
import Component from "../../../components";
import { Button, Col, Form, Row, Switch, Dropdown, Menu, message, Spin } from "antd";
import { useStore } from "../../../root-store-context";
import Swal from "sweetalert2";
import moment from "moment";
import ModalComponent from "../../../components/Modal/Modal";
import Editor from "../../../components/Editor/Editor";
import TextInput from "../../../components/TextInput/TextInput";
import UploadButton from "../../../components/UploadButton/NewUploadButton";
import PdfIcon from "../../../images/pdf-icon.png";
import { ReactComponent as ViewIcon } from "../../../svg/ViewIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../svg/DownloadIcon.svg";
import { formatDate } from '../../../Common';
import { PaperClipOutlined, DeleteOutlined } from "@ant-design/icons";
import CommonFuncitons from '../../../utils/CommonFunciton';
const Documents = (props) => {
  const {applicationStore, apiStore } = useStore();
  const {form,  submissionId, handleViewFile, GetUser } = props;
  const { ComponentSpiner } = Component;
  const [fileList, setFileList] = useState([]);
  const [documentedComments, setDocumentedComments] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [submissionInfo, setSubmissionInfo] = useState();
  const [createComment, setCreateComment] = useState({ documentIds: [] });
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("NewestTop");

  let residents = {
    "103096": "145011",
    "1200782": "5012102",
    "112192": "155269",
    "1195639": "445445"
  };
  const fetchCommentDetails = async () => {
    let id = props?.form?.baseId;
    setLoading(true)
    try {
      let residentKey = apiStore?.configApi?.community?.residentKey?.toString();
      let residentContactKey = apiStore?.configApi?.community?.residentContactKey ? apiStore?.configApi?.community?.residentContactKey?.toString() : residents[residentKey?.toString()]; // "146314";
      const res = await apiStore.FormsApiClient.GetCommentDetails(id, residentKey, residentContactKey);
      if (res?.data) {
        setLoading(false)
        const requestDetails = res.data;
        updateComments(requestDetails.comments);
        const combinedDocs = requestDetails.comments?.reduce((acc, comment) => {
          if (comment?.documents?.length > 0) {
              const updatedDocuments = comment.documents.map((doc) => ({
                  ...doc,
                  commentDate: comment.commentDate,
              }));
              acc.push(...updatedDocuments);
          }
          return acc;
      }, []);
     const setFormData = JSON.parse(props?.form?.formData)
        const submissionData = {
          display:'form',
          components:setFormData?.components
        };
       
        const uploadedFiles = extractUploadedFiles(submissionData);
      //  const files = uploadedFiles?.length > 0 && consolidateFiles(uploadedFiles);
        const otherFiles = Array.isArray(form?.files)
            ? form?.files?.map(file => ({
                ...file, // Spread existing file properties
                type: `Supported Document || ${file.docTypeName}` ,
                commentDate: props?.form?.submittedDate, // Add commentDate
            }))
            : [];
            const appealDoc = Array.isArray(props?.form?.appealDetail?.files)
            ? form?.appealDetail?.files?.map(file => ({
                ...file, // Spread existing file properties
                type: `Appeal Document` ,
                commentDate: props?.form?.appealDetail?.createdDate, // Add commentDate
            }))
            : [];

        const mergedFiles = [...combinedDocs, ...uploadedFiles, ...otherFiles,...appealDoc]
        setDocumentedComments(mergedFiles || []);
        setSubmissionInfo(requestDetails.submissionInfo);
        const obj = { form: res?.data?.formData ? JSON.parse(res.data.formData) : {} };
      }else{  setLoading(false)}
    } catch (error) {
      setLoading(false)
      console.error("Error in GetCommentDetails:", error);
    }
  };
  function extractUploadedFiles(submissionData) {
    // const submissionData = JSON.parse(submissionD);
    const findFilesInJson = (data) => {
        let results = [];

        // If data is an array, recursively search each item
        if (Array.isArray(data)) {
            data.forEach((item) => {
                results = results.concat(findFilesInJson(item));
            });
        } else if (typeof data === "object" && data !== null) {
            // If data is an object, loop through its keys
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const value = data[key];

                    // If we find a file-like object (customize this based on your structure)
                    if (key === "type" && value === "file" && data.defaultValue?.length > 0) {
                        // Push the file information to results
                        console.log(data.defaultValue,"data.defaultValuedata.defaultValue")
                        results = results.concat(data.defaultValue.map((file) => ({
                            ...file,
                            label: data.label || "Unnamed File",
                        })));
                    } else {
                        // Recursively search deeper
                        results = results.concat(findFilesInJson(value));
                    }
                }
            }
        }

        return results;
    };

    // Call the recursive function to find all files
    const foundFiles = findFilesInJson(submissionData);
console.log(foundFiles,"foundFiles")
    return foundFiles?.map(file => ({
        ...file.data,  // Spread the existing data properties
        type: 'Request',// Add the type inside data
        commentDate: props?.form?.submittedDate
    }));
}


  function consolidateFiles(data) {
    return data.reduce((acc, item) => {
      if (item.files && item.files.length > 0) {
        acc.push(...item.files);
      }
      return acc;
    }, []);
  }

  const updateComments = (newComments) => {
    const mergedComments = [
      ...new Map(
        [...commentData, ...newComments].map((comment) => [comment.commentId, comment])
      ).values(),
    ];
    const sortedComments = sortComments(mergedComments, sortOrder);
    const filteredComments = filterComments(sortedComments, searchTerm);

    setCommentData(filteredComments);
  };

  useEffect(() => {
    const fetchData = () => {
      try {
        if(props?.form?.baseId){
          fetchCommentDetails();
        }

      } catch (error) {
        console.error('Error fetching comment details:', error);
      }
    };

    fetchData(); // Initial fetch
    const intervalId = setInterval(fetchData, 20000); // Replace with your preferred interval

    // Clear the interval when the component unmounts or dependencies change
    return () => clearInterval(intervalId);
  }, [apiStore, searchTerm, sortOrder]);

  useEffect(() => {
    //if (fileList.length > 0) {
    setCreateComment((prevComment) => {
      return {
        ...prevComment,
        documentIds: [
          ...(prevComment.documentIds || []), // Ensure documentIds is an array
          fileList,
        ],
      };
    });
    // }
  }, [fileList]);

  const handleDownload = (data) => {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/download?fileId=${data.fileId}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const formatDate = (inputDate) => {
  //   if (inputDate) {
  //     return moment(inputDate).format("MM/DD/YYYY");
  //   }
  //   return "-";
  // }
  const sortComments = (comments, order) => {
    if (order === "OldNew") {
      return [...comments].sort((a, b) => new Date(a.commentDate) - new Date(b.commentDate));
    } else if (order === "NewOld") {
      return [...comments].sort((a, b) => new Date(b.commentDate) - new Date(a.commentDate));
    } else if (order === "NewestTop") {
      return [...comments].sort((a, b) => new Date(b.commentDate) - new Date(a.commentDate));
    }
    return comments;
  };

  const filterComments = (comments, term) => {
    if (!term) return comments;
    return comments.filter((comment) =>
      comment?.userName?.toLowerCase().includes(term) ||
      comment?.comments?.toLowerCase().includes(term)
    );
  };
 
  
  return (
    <>
 
     {/* {loading && <ComponentSpiner/>} */}
      <div className='comment-document-list'>
        <p className="secondary-heading mb-2">Files</p>
        <div className='all-doc-column'>
          {documentedComments?.length > 0 &&
         documentedComments?.map((data, i) => {
          const fileIcon = CommonFuncitons.getFileIcon(data?.originalFileName);
          const isCSV = data?.originalFileName?.endsWith('.csv');
          const isXlsx = data?.originalFileName?.endsWith('.xlsx');
          const isXlx = data?.originalFileName?.endsWith('.xlx');
          return (
            <div className="all-document-row-wrap" key={i}>
              <div className="document-row-wrap">
                <span className="document-icon-wrap">
                  <img
                    className="doc-icon"
                    src={fileIcon}
                    onClick={() => handleViewFile(data?.fileId)}
                  />
                </span>
                <div className="d-flex flex-column">
                  <span className="m-0 docment-name-style"  onClick={() => handleViewFile(data?.fileId)}>
                    {data?.originalFileName}
                  </span>
                  <p className="m-0">
                    {data?.type ?? "Comment"} - {formatDate(data?.commentDate) == "-" ? applicationStore?.submissionDate["Submitted Date"] : formatDate(data?.commentDate)} 
                  </p>
                </div>
              </div>
              <div className="document-action-wrap">
                {!(isCSV || isXlsx || isXlx) && (
                  <Button className="icon-wrap p-0" onClick={() => handleViewFile(data?.fileId)}>
                    <ViewIcon />
                  </Button>
                )}
                <Button className="icon-wrap p-0" onClick={() => handleDownload(data)}>
                  <DownloadIcon/>
                </Button>
              </div>
            </div>
          );
        })}
        </div>
      </div>


    </>
  );
};

export default Documents;
