import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Button, Typography, List, Modal } from "antd";
import DynamicTable from "../../../components/Table/Table"; // Assuming DynamicTable is available
import Spinner from "../../../components/Spinner/Spiner";
import { useStore } from "../../../root-store-context";
import "./SubmissionDetails.css";

// Date formatter function
const formatDate = (date) => {
  if (!date) return null; // return null if no date is provided
  const d = new Date(date);
  const month = d.getMonth() + 1; // Months are 0-indexed, so we add 1
  const day = d.getDate();
  const year = d.getFullYear();

  return `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${year}`;
};

const { Title, Text } = Typography;

const SubmissionDetails = () => {
  
  const navigate = useNavigate();
  const { residentKey } = useParams(); 
  const location = useLocation();
  const { submissionId } = location.state ?? {}; // Get the submissionId from the state
  const { apiStore } = useStore();
  const [submissionDetails, setSubmissionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isContactsModalVisible, setIsContactsModalVisible] = useState(false);
  const [tenantEmergencyContacts, setTenantEmergencyContacts] = useState([]);

  // Fetch detailed submission data
  useEffect(() => {
    if (submissionId) {
      apiStore.FormsApiClient.GetTenantSubmissionDetails(submissionId) // Call second API to fetch details
        .then((res) => {
          setSubmissionDetails(res.data);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [submissionId, apiStore]);

  if (isLoading) return <Spinner />;

  if (!submissionDetails) {
    return <div>No details available for this submission.</div>;
  }

  // Columns for dynamic tables
  const tenantColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
   // { title: "Birth Date", dataIndex: "birthDate", key: "birthDate" },
    {
      title: "Violation",
      dataIndex: "violation",
      key: "violation",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, tenant) => (
        <Button
          type="link"
          onClick={() => handleViewContacts(tenant.key)}
        >
          View Contacts
        </Button>
      ),
    },
  ];

  const vehicleColumns = [
    { title: "Make", dataIndex: "make", key: "make" },
    { title: "Model", dataIndex: "model", key: "model" },
    { title: "Year", dataIndex: "year", key: "year" },
    { title: "Color", dataIndex: "color", key: "color" },
    { title: "License", dataIndex: "license", key: "license" },
    { title: "State", dataIndex: "state", key: "state" },
    { title: "Spot", dataIndex: "spot", key: "spot" },
  ];

  const petColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Breed", dataIndex: "breed", key: "breed" },
    {
      title: "Rabies Due Date",
      dataIndex: "rabiesDueDate",
      key: "rabiesDueDate",
    },
    { title: "License", dataIndex: "license", key: "license" },
    { title: "Age", dataIndex: "age", key: "age" },
  ];

  // Lease Columns
  const leaseColumns = [
    { title: "Start Date", dataIndex: "startDate", key: "startDate" },
    { title: "End Date", dataIndex: "endDate", key: "endDate" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  // Payment Columns
  const paymentColumns = [
    { title: "Payment Date", dataIndex: "paymentDate", key: "paymentDate" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    { title: "Payment Method", dataIndex: "paymentMethod", key: "paymentMethod" },
    { title: "Payment Status", dataIndex: "paymentStatus", key: "paymentStatus" },
    { title: "Card/Account Holder Name", dataIndex: "cardHolderName", key: "cardHolderName" },
    { title: "Card/Account Number", dataIndex: "cardNumber", key: "cardNumber" },
    { title: "Transaction Id", dataIndex: "transactionId", key: "transactionId" },
  ];

  // Transform data for DynamicTable
  const transformTenants = submissionDetails.tenants?.map((tenant) => ({
    key: tenant.id,
    name: `${tenant.firstName} ${tenant.lastName}`,
    email: tenant.email,
    phone: tenant.phone,
    violation: tenant.violation,
    sendConfirmationMail: tenant.sendCopyToTenant,
    emergencyContacts: tenant.emergencyContacts
    //birthDate: tenant.birthDate ? formatDate(tenant.birthDate) : '',
  }));

  const transformVehicles = submissionDetails.vehicles?.map((vehicle) => ({
    key: vehicle.requestId,
    make: vehicle.make,
    model: vehicle.model,
    year: vehicle.year,
    color: vehicle.color,
    license: vehicle.license,
    state: vehicle.state,
    spot: vehicle.spot,
  }));

  const transformPets = submissionDetails.pets?.map((pet) => ({
    key: pet.name, 
    name: pet.name,
    type: pet.type,
    breed: pet.breed,
    rabiesDueDate: pet.rabiesDueDate ? formatDate(pet.rabiesDueDate) : '',
    license: pet.license,
    age: pet.age,
  }));

  const transformLeases = {
    startDate:  submissionDetails?.leaseInfo.startDay ? formatDate( submissionDetails?.leaseInfo.startDay) : '',
    endDate:  submissionDetails?.leaseInfo.endDay ? formatDate( submissionDetails?.leaseInfo.endDay) : '',
    amount: '$' + (submissionDetails.amount ?  submissionDetails.amount : "0"), // Fixed to use lease.amount, not submissionDetails.amount
    status: submissionDetails?.leaseInfo.newOrRenew, // Fixed to use lease.amount, not submissionDetails.amount
  };

  const transformPayments = {
    key: submissionDetails?.paymentDetails.id,
    amount: '$' + (submissionDetails?.paymentDetails.amount ? submissionDetails?.paymentDetails.amount : "0"),
    paymentMethod: submissionDetails?.paymentDetails.paymentMethod,
    paymentStatus:
      submissionDetails?.paymentStatus === 0
        ? 'Pending'
        : submissionDetails?.paymentStatus === 1
        ? 'Done'
        : submissionDetails?.paymentStatus === 2
        ? 'Fail'
        : 'Renewal',
    cardHolderName: submissionDetails?.paymentDetails.cardHolderName,
    cardNumber: submissionDetails?.paymentDetails.cardNumber,
    transactionId: submissionDetails?.paymentDetails.transactionId,
  };
  const fullMailingAddress = `${submissionDetails?.mailingAddress}` +
  (submissionDetails?.mailingCity && submissionDetails?.mailingCity.trim() !== "" 
    ? `, ${submissionDetails?.mailingCity}, ${submissionDetails?.mailingState}` 
    : "") +
  (submissionDetails?.mailingZip && submissionDetails?.mailingZip.trim() !== "" 
    ? `, ${submissionDetails?.mailingZip}` 
    : "");

    const handleViewContacts = (tenantId) => {
      // Find the tenant's emergency contacts based on their id
      const tenant = submissionDetails.tenants.find(t => t.id === tenantId);
      
      // Combine firstName and lastName to create contactName for each emergency contact
      const emergencyContacts = tenant?.emergencyContacts?.map(contact => ({
        contactName: `${contact.firstName} ${contact.lastName}`, // Combine first and last name
        relationship: contact.relationship,
        phone: contact.phone,
        email:contact.email
      })) || [];
    console.log(emergencyContacts,"emerge");
      setTenantEmergencyContacts(emergencyContacts);
      setIsContactsModalVisible(true);
    };
  
    // Modal columns for emergency contacts
    const emergencyContactColumns = [
      { title: "Contact Name", dataIndex: "contactName", key: "contactName" },
      { title: "Relationship", dataIndex: "relationship", key: "relationship" },
      { title: "Phone", dataIndex: "phone", key: "phone" },
      { title: "Email", dataIndex: "email", key: "email" },
    ];
  return (
    <Row justify="center">
      <Col span={20}>
      {residentKey ? (
        <Button
          style={{
            borderRadius: 19,
            border: "1px solid #fff",
            color: "#fff",
            backgroundColor: "#5A7890",
            height: "2rem",
            width: "7rem",
          }}
          onClick={() => navigate(`/Forms/TenantRegistrations/${residentKey || ""}`)}
        >
          Back To List
        </Button>
      ):
      (
        <Button
          style={{
            borderRadius: 19,
            border: "1px solid #fff",
            color: "#fff",
            backgroundColor: "#5A7890",
            height: "2rem",
            width: "7rem",
          }}
          onClick={() => navigate(`/Forms/TenantRegistrations`)}
        >
          Back To List
        </Button>
      )
      }
        
        <Card bordered={false} className="submission-card">
          <Title level={2}>Submission Details</Title>
          {/* Display Submission Details */}
          <div>
            {/* <Text strong>Submission ID:</Text> <Text>{submissionDetails.id}</Text>
            <br /> */}
            <Text strong>Selected Property:</Text> <Text>{submissionDetails?.property}, {submissionDetails?.city}, {submissionDetails?.state} </Text>
            <br />
            <Text strong>Property Address:</Text> <Text>{submissionDetails?.propertyFullAddress}, {submissionDetails?.propertyCity}, {submissionDetails?.propertyState}, {submissionDetails?.propertyZip}</Text>
            <br />
            <Text strong>Mailing Address:</Text> <Text>{fullMailingAddress}</Text>
            <br />
            <Text strong>Submitter Name:</Text> <Text>{submissionDetails?.submitterFirstName} {submissionDetails?.submitterLastName}</Text>
            <br />
            <Text strong>Submitter Email:</Text> <Text>{submissionDetails?.submitterEmail}</Text>
            <br />
            <Text strong>Submitter Telephone:</Text> <Text>{submissionDetails?.submitterTelephone}</Text>
            <br />
            <Text strong>Status:</Text> <Text>{submissionDetails?.status == 0?"Pending":(submissionDetails?.status == 1?"Approved":"Rejected")}</Text>
            <br />
            <Text strong>Submission Date:</Text> <Text>{formatDate(submissionDetails?.submissionDate)}</Text>
            <br />
            <Text strong>Payment Status:</Text> <Text>{transformPayments?.paymentStatus}</Text>
          

          {submissionDetails?.paymentStatus === 1 &&(<>
            <br />
            <Text strong>Payment Method:</Text> <Text>{transformPayments.paymentMethod}</Text>
            <br />
            <Text strong>Card Holder Name:</Text> <Text>{transformPayments.cardHolderName}</Text>
            {/* <br />
            <Text strong>Card Number:</Text> <Text>{transformPayments.cardNumber}</Text> */}
            <br />
            <Text strong>Transaction Id:</Text> <Text>{transformPayments.transactionId}</Text>
          
          </>)}
          {submissionDetails?.stateDisclaimer &&(<>
            <br />
            <Text strong>State Disclaimer:</Text> <Text>{submissionDetails?.stateDisclaimer}</Text>
          
          </>)}
          {submissionDetails?.propertyDisclaimer &&(<>
            <br />
            <Text strong>Property Disclaimer:</Text> <Text>{submissionDetails?.propertyDisclaimer}</Text>
          
          </>)}
        
          </div>
          {/* Lease Information Section */}
          <Title level={3}>Lease Information</Title>
          <DynamicTable columns={leaseColumns} data={[transformLeases] ?? []} />

          {/* Payment Information Section
          <Title level={3}>Payment Information</Title>
          <DynamicTable columns={paymentColumns} data={[transformPayments] ?? []} /> */}

          {/* Tenants Section */}
          <Title level={3}>Tenants Information</Title>
          <DynamicTable columns={tenantColumns} data={transformTenants ?? []} />

          {/* Modal for Emergency Contacts */}
          <Modal
            title="Emergency Contacts"
            visible={isContactsModalVisible}
            onCancel={() => setIsContactsModalVisible(false)}
            footer={[
              <Button key="close" onClick={() => setIsContactsModalVisible(false)}>
                Close
              </Button>,
            ]}
          >
            <DynamicTable columns={emergencyContactColumns} data={tenantEmergencyContacts ?? []} pagination={false} />

          </Modal>

          {/* Vehicles Section */}
          <Title level={3}>Vehicles Information</Title>
          <DynamicTable columns={vehicleColumns} data={transformVehicles ?? []} />

          {/* Pets Section */}
          <Title level={3}>Pets Information</Title>
          <DynamicTable columns={petColumns} data={transformPets ?? []} />

          {/* Supporting Documents Section */}
          <Title level={3}>Supporting Documents</Title>
          <List
            dataSource={submissionDetails?.documents ?? []}
            renderItem={(doc) => (
              <List.Item>
                <a href={doc.url} download>
                  {doc.originalFileName}
                </a>
              </List.Item>
            )}
          />

          {/* Approval Information Section (if applicable) */}
          {submissionDetails.status !== 0 && (
            <>
              <Title level={3}>Approval Information</Title>
              <div>
                <Text strong>Approval Status:</Text> <Text>{submissionDetails.status === 0 ? "Pending" : submissionDetails.status === 1 ? "Approved" : "Rejected"}</Text>
                <br />
                <Text strong>Approval Remark:</Text> <Text>{submissionDetails.approvalRemark}</Text>
                <br />
                <Text strong>Approved By:</Text> <Text>{submissionDetails.approvalBy}</Text>
                <br />
                <Text strong>Approval Date:</Text> <Text>{formatDate(submissionDetails.approvalDate)}</Text>
              </div>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default SubmissionDetails;
