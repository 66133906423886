import { Card, Flex, Spin, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useStore } from "../../../root-store-context";
import { Link } from "react-router-dom";
import {ArrowLeftOutlined} from '@ant-design/icons';
import { report } from "process";

const ArchitecturalStatusReport = observer(() => {

    const { reportStore, applicationStore:{ SelectedAddress } } = useStore();

    const [isLoadReport, setIsLoadReport] = useState<boolean>(true);
    const [reportUrl, setReportUrl] = useState<string>("");
    const lastUsedSourceCommunityId = useRef<string|null>(null);
    // const embed = async (token:string, guidStr:string, mountPointStr:string) => {
    //     await embedDashboard({
    //         id: guidStr, // given by the Superset embedding UI
    //         supersetDomain: reportStore.getReportUrl(),
    //         mountPoint: document.getElementById(mountPointStr) as HTMLElement, // html element in which iframe render
    //         fetchGuestToken: async () => token,
    //         dashboardUiConfig: {
    //             hideTitle: true,
    //             hideChartControls: false,
    //             hideTab: false,
    //             filters: {
    //                 expanded: true,
    //             },
    //         },
    //     })
    // }

    // const reportPost = async (propertyid:string) => {
    //     const url = await reportStore.getReportUrl2(propertyid, "26");
    //     if (url) {
    //         setReportUrl(url);
    //     }
    // }


    
    useEffect(() => {
        const currentSourceCommunityId = SelectedAddress?.communityId;
        const currentSourceCommunityName = SelectedAddress?.name;
        // reportPost(currentSourceCommunityId);
        if (currentSourceCommunityId !== lastUsedSourceCommunityId.current
            && document.getElementById("BV_ArchitecturalStatusCSVExport")
            ) {
                setIsLoadReport(true);
                reportStore.getReportUrl2(currentSourceCommunityName, "61").then(url => {
                if(url){
                    console.log(currentSourceCommunityName);
                    setReportUrl(url);
                    setIsLoadReport(false);
                }
            }).catch(err=> {
                console.log(err)
            })
            lastUsedSourceCommunityId.current = currentSourceCommunityId;
        }

    }, [SelectedAddress?.communityId]);

    return <>
            <Flex vertical={true} gap={10}>
            <Link to="/BoardVue/Reports" style={{ marginLeft: 10 }}><ArrowLeftOutlined /> Back</Link>
                <Card>
                {/* <Card title={<Typography.Title level={5} style={{margin:0}}><b>Architectural Status CSV Export</b></Typography.Title>}> */}
                    <div></div>
                    <iframe id="BV_ArchitecturalStatusCSVExport" className="reportIframe"
                        src={reportUrl}
                        width="100%"
                        height="1000"
                        allowTransparency={true}
                        frameBorder={0}
                    ></iframe>
                    {isLoadReport === true ? <>
                        <Flex gap="small" vertical>
                            <Spin/>
                        </Flex>
                    </>: <></>}
                </Card>
            </Flex>
    </>
})

export default ArchitecturalStatusReport
