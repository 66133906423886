import moment from "moment";

const formatDate = (inputDate) => {
    if (inputDate) {
        //return moment(inputDate).format("MM/DD/YYYY");
        const date = new Date(inputDate + 'Z');
    const options = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: 'numeric', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: true 
    };
    return date.toLocaleString(undefined, options);
    }
    return "-";
};
const getDigits = (str, number) => {
    return str.slice(number);
};

export  {formatDate,getDigits};
