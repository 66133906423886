import React, { useEffect, useState } from 'react';
import { EditOutlined,SearchOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Button, Row, Col, Select, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import DynamicTable from '../../../components/Table/Table';
import { ArchitecturalSubmittal } from '../../../api/BoardVue';
import { useStore } from '../../../root-store-context';
import Swal from "sweetalert2";
import Spinner from '../../../components/Spinner/Spiner';
import TextInput from '../../../components/TextInput/TextInput';
const { TextArea } = Input;
const { Option } = Select;

const ArchitecturalSubmittalsList: React.FC = () => {
  const { apiStore, applicationStore } = useStore();
  const [data, setData] = useState<ArchitecturalSubmittal[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ArchitecturalSubmittal | null>(null);
  const [form] = Form.useForm();

  const modificationTypes = [
    "Add Gutters",
    "Add Landscaping",
    "Add Stone",
    "Addition Beyond Existing",
    "Addition to Home",
    "Addition Under Existing",
    "Address Sign",
    "Antenna",
    "Arbor",
    "Arizona Room Enclosure",
    "Artificial Turf",
    "Attic Fans",
    "Awning Installation",
    "Backyard Access Path",
    "Balcony",
    "Bar-B-Que Grill",
    "Batting Cage",
    "Bird Deterrents",
    "Boulder",
    "Cabana",
    "California Room Enclosure",
    "Canopy",
    "Carport Stanchions",
    "Casita",
    "Closet",
    "Coach Lighting",
    "Concrete Coating",
    "Concrete Enhancement",
    "Concrete Slab",
    "Courtyard",
    "Courtyard Gate",
    "Courtyard Wall",
    "Curbing",
    "Customer Home Plans",
    "Deck",
    "Dog Run",
    "Driveway Extension",
    "Driveway Modification",
    "Electrical Conduit",
    "Enclose Patio",
    "Exterior Home D\u00e9cor",
    "Exterior Lighting",
    "Fan Installation",
    "Fence or Wall Installation",
    "Fence or Wall Modification",
    "Firepit",
    "Fireplace",
    "Flagpole Installation",
    "Flagstone",
    "Flower Bed",
    "Fountain or Water Feature",
    "French Drain",
    "Front Porch",
    "Front Yard Landscape",
    "Garage Addition",
    "Garage Door",
    "Garbage Enclosure",
    "Garden",
    "Gas or Electric Run",
    "Gate Installation or Changes",
    "Gazebo Installation",
    "General Appeal",
    "Generator",
    "Granite Replenishment",
    "Guest House",
    "Hardscape Installation",
    "Home Construction",
    "Hurricane Protection Systems",
    "HVAC",
    "Install Door",
    "Install Doorbell",
    "Install Grass",
    "Install Gravel",
    "Install Pavers",
    "Interior Flooring",
    "Irrigation",
    "Jacuzzi",
    "Keypad",
    "Lake Dock Modification",
    "Lamp Post",
    "Landscape Alterations",
    "Landscape Extension",
    "Landscape Installation",
    "Landscape Lighting",
    "Landscape Ornamental",
    "Landscape Screening",
    "Landscape Strip Replacement",
    "Lattice Cover",
    "Lawn Install",
    "Mailbox Installation",
    "Miscellaneous or Other",
    "New Construction",
    "New Dock Build",
    "No Information Given",
    "Paint Change",
    "Paint Door",
    "Paint Exterior",
    "Paint Other",
    "Patio Alteration",
    "Patio Cover",
    "Patio Enclosure",
    "Patio Furniture",
    "Patio Installation",
    "Paver Walkway",
    "Pergola",
    "Permanent Basketball Hoop",
    "Photovoltaic Solar System",
    "Pillars",
    "Plant Replacement",
    "Planter Wall",
    "Play Equipment",
    "Pony Wall or Decorative Wall",
    "Pool Equipment Wall",
    "Pool Heater",
    "Pool Installation",
    "Popouts",
    "Portable Basketball Hoop",
    "Pots",
    "Potted Plants",
    "Privacy Screening",
    "Radon Mitigation System",
    "Railing",
    "Ramada",
    "Ramp",
    "Rear Yard Landscape",
    "Remodeling",
    "Replace Landscape",
    "Replace Turf with Granite",
    "Retention Wall",
    "River Rock",
    "Roof Installation or Modification",
    "Room Addition",
    "RV Gate",
    "Satellite Dish",
    "Screen Door",
    "Screen Enclosure Beyond Existing",
    "Screen Enclosure Under Existing",
    "Screened Enclosure",
    "Security Bars",
    "Security Cameras",
    "Security Door",
    "Shade Structure",
    "Shed",
    "Shutters",
    "Sidewalk or Walkway Installation",
    "Sideyard Landscape",
    "Sideyard Parking",
    "Signage",
    "Skylights",
    "Solar Other",
    "Solar Panels",
    "Solar Tubes",
    "Stepping Stones",
    "Steps",
    "Stone Facing or Veneer",
    "Storm Door Installation",
    "Structural Changes",
    "Stucco Replacement",
    "Sun Screens",
    "Sun Shades",
    "Tile",
    "Trampoline Installation",
    "Trash Can Enclosure",
    "Trash Receptacle Pad",
    "Travertine",
    "Tree/Root Removal",
    "Trellis",
    "Umbrella",
    "Variance",
    "View Fencing",
    "Wall Modification",
    "Wheel Chair Lift",
    "Window Alterations",
    "Window Coverings",
    "Window Installation",
    "Woodchips",
    "Wrought Iron Fence",
    "Xeriscape",
    "Yard Art"
];

  const architecturalStatuses = [
  "Pending Committee Decision",
  "Approved",
  "Approved with Stipulations",
  "Preliminary Approval",
  "Preliminary Approval with Stipulations",
  "Denied",
  "Withdraw"
];

  const archSubWF = [
    "Starting",
    "Failed on Start",
    "In Progress",
    "Error Occurred",
    "Canceled",
    "Completed",
    "Failed on Start (retrying)",
    "Error Occurred (retrying)",
    "Approved",
    "Rejected"
];

const priority = [
  "(1) High",
  "(2) Normal",
  "(3) Low"
];

const architecturalProcess = [
  "Request More Info",
  "Pending Committee Review",
  "Committee Reviewed / Decisions Provided by All",
  "Completed"
];

const taskStatus = [
  "Not Started",
  "In Progress",
  "Completed",
  "Deferred",
  "Waiting on someone else",
  "Pending Board Approval",
  "Pending Committee Approval",
  "Pending Vendor Update",
  "On Hold",
  "Reference",
  "Send to Compliance"
];
  // Fetch data from API
  const getBoardList = async () => {
    try {
      const res = await apiStore.BoardVueApiClient.GetArchitecturalSubmittalsList();
      if (res.status) {
        setData(res.data);
      } else {
        message.error(res.data.message || 'Failed to load data');
      }
    } catch (error) {
      message.error('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBoardList();
  }, []);

  const handleEditClick = (record: ArchitecturalSubmittal) => {
    setSelectedItem(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    setLoading(true);
    try {
      const updatedValues = await form.validateFields();
      const updatedSubmittal: ArchitecturalSubmittal = {
        ...selectedItem, 
        ...updatedValues, 
      };
      try {
        const res = await apiStore.BoardVueApiClient.UpdateArchitecturalSubmittal(updatedSubmittal);
        if (res.status) {
          Swal.fire({
            title: "Success",
            text: "Successfully Updated Submittal.",
            icon: "success",
            confirmButtonColor: "#44637e",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Unable to Update Submittal.",
            icon: "error",
            confirmButtonColor: "#44637e",
          });
          message.error(res.data.message || 'Failed to load data');
        }
      } catch (error) {
        message.error('Error updating submittal');
      } finally {
        setLoading(false);
      }
      getBoardList();
    } catch (error) {
      console.error('Form validation error:', error);
      message.error('Please correct the errors in the form');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields(); 
  };

  const columns: ColumnsType<ArchitecturalSubmittal> = [
    { title: 'Id', dataIndex: 'id', key: 'id' },
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Arch Received Date', dataIndex: 'receivedDate', key: 'receivedDate', render: (text) => new Date(text).toLocaleDateString() },
    { title: 'Arch Due Date', dataIndex: 'dueDate', key: 'dueDate', render: (text) => new Date(text).toLocaleDateString() },
    { title: 'Modification Type', dataIndex: 'modificationType', key: 'modificationType' },
    { title: 'Architectural Status', dataIndex: 'architecturalStatus', key: 'architecturalStatus' },
   // { title: 'ArchSubWF', dataIndex: 'archSubWF', key: 'archSubWF' },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <Button icon={<EditOutlined />} onClick={() => handleEditClick(record)} />
      ),
    },
  ];
  const getFileName = (attachment:string) => {
    // Split the path and return the last segment as the file name
    return attachment.split('/').pop();
};
const [searchTerm, setSearchTerm] = useState('');
const [filteredData, setFilteredData] = useState<ArchitecturalSubmittal[]>([]);
const handleSearch = (event:any) => {
  const value = event.target.value;
  setSearchTerm(value);
  const filtered = data?.filter(item => 
    item.title.toLowerCase().includes(value.toLowerCase())

  );
  setFilteredData(filtered);
};
useEffect(()=>{
  if(data?.length > 0){
    setFilteredData(data)
  }
},[data])
  return (
    <>
    {loading && <Spinner />}
    <div>
      <h1>Architectural Submittals</h1>
      <Row justify={'end'}>
      <Col sm={6}>
      <TextInput
      icon={<SearchOutlined />}
         className="search"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
        />
      </Col>
    </Row>
      <DynamicTable columns={columns} data={filteredData} onActionClick={() => { }} pagination={true} onChange={() => { }} onRowClick={() => { }} />

      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <h2 style={{marginBottom:"2rem"}}>Update Submittal</h2>
        <Form form={form} layout="vertical">
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Property Address" name="title">
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Arch Received Date" name="receivedDate">
                <Input disabled/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Arch Due Date" name="dueDate">
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Modification Type" name="modificationType">
              <Select options={modificationTypes.map(type => ({ label: type, value: type }))} disabled/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Architectural Status" name="architecturalStatus">
              <Select options={architecturalStatuses.map(type => ({ label: type, value: type }))} disabled/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Task Status" name="taskStatus" rules={[{ required: true, message: 'Please input the task status' }]}>
              <Select placeholder="Select Task Status" options={taskStatus.map(type => ({ label: type, value: type }))} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Task Description" name="taskDescription">
                <TextArea rows={4} disabled/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Note" name="note">
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
          {selectedItem?.noteHistory && (
            <Row gutter={16}>
              <Col span={24}>
                <div style={{ marginTop: '1rem' }}>
                <Form.Item label="Note History" name="noteHistory">
                  <div
                    style={{
                      padding: '1rem',
                      backgroundColor: '#f5f5f5',
                      borderRadius: '5px',
                      whiteSpace: 'pre-line',
                    }}
                    dangerouslySetInnerHTML={{ __html: selectedItem.noteHistory }}
                  />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          )}

          <Row gutter={16}>
            <Col span={24}>
                <Form.Item label="Attachments">
                {selectedItem?.attachments && selectedItem.attachments.length > 0 ? (
                  selectedItem.attachments.map((attachment, index) => (
                    <div key={index}>
                      <a href={`${attachment}`} target="_blank" rel="noopener noreferrer">
                        {getFileName(attachment)}
                      </a>
                    </div>
                  ))
                ) : (
                  <span>No attachments available</span>
                )}
                </Form.Item>
            </Col>
        </Row>
        </Form>
      </Modal>
    </div>
    </>
   
  );
};

export default ArchitecturalSubmittalsList;
