import { ChangeEvent, ReactElement, useState } from "react";
import { Button, Col, Flex, Input, List, Row, Tag, Tooltip } from "antd";
import { InlineField, ThreeLineField } from "./AccountInfoCard";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";

const styles = {
  contactInfoContainer: {
    display: "flex",
    justifyContent: "start",
    flexFlow: "row wrap",
    borderBottom: "1px solid lightgray",
  },
  contactInfoCard: {
    flex: "1 1 300px",
    textAlign: "start" as const,
    alignSelf: "stretch",
    padding: "5px",
    margin: "5px",
  },
  contactInfoCardNoFlex: {
    textAlign: "start" as const,
    alignSelf: "stretch",
    padding: "5px",
    margin: "5px",
  },
  buttonClose: {
    background: "none",
    border: "none",
  },
  phoneContainer: {
    display: "block",
    width: 300,
    marginBottom: 10,
    marginRight: 10,
    height: 40,
    lineHeight: 40,
  },
  phoneError: {
    backgroundColor: "#fdd6de",
  },
  phoneInput: {
    height: 40,
    lineHeight: 40,
  },
  phoneClose: {
    width: "38px",
    background: "red",
  },
  phoneName: {},
  contactInfoInput: {
    display: "block",
    maxWidth: "320px",
    marginBottom: 10,
    marginRight: 10,
    height: 40,
    lineHeight: 40,
  },
};
export type PhoneData = {
  methodId: number;
  phoneType: string;
  type: number;
  number: string | null;
};
export type ContactInformation = {
  accountNumber: string;
  activeFutures: null | any;
  activeRenters: null | any;
  address: string;
  address2: string;
  birthdate: null | any;
  businessPhone: null | any;
  businessPhoneMethodId: null | any;
  contactAddress: null | any;
  contactFullName: string;
  contactId: string;
  dateReceived: null | any;
  email: string;
  emailMethodId: number;
  employeeKey: null | any;
  expirationDate: null | any;
  firstName: string;
  futures: null | any;
  homePhone: null | any;
  homePhoneMethodId: null | any;
  id: string;
  isETrakType: boolean;
  isMain: boolean;
  lastName: string;
  mainAcc: null | any;
  mobilePhone: null | any;
  mobilePhoneMethodId: null | any;
  modifiedString: null | any;
  noActiveFutures: null | any;
  noActiveRenters: null | any;
  note: null | any;
  occupancy: boolean;
  owner: boolean;
  phones: PhoneData[];
  primaryOwner: string;
  rc_Date5: string;
  renters: null | any;
  salutation: string;
  type: string;
  user: null | any;
  writeAccess: boolean;
};
export const PhoneInputNumber = ({
  phoneData,
  onSave,
  onRemove,
  disabled,
}: {
  phoneData: PhoneData;
  onSave?: (phoneData: PhoneData) => void;
  onRemove?: (phoneData: PhoneData) => void;
  disabled: boolean;
}) => {
  const [phone, setPhone] = useState<string>(
    phoneData.number
      ? phoneData.number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      : ""
  );
  const pattern = /\d{3}-\d{3}-\d{4}/;
  const mask = "___-___-____";

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!disabled && onSave !== undefined) {
      const inputValue = event.target.value;
      const digitsOnly = inputValue.replace(/\D/g, "");
      let numIndex = 0;
      let newMask = mask.replace(/_/g, (_) => digitsOnly[numIndex++] || "_");
      await setPhone(newMask);
      let lastDigitIndex = newMask.search(/\d(?!.*\d)/);
      lastDigitIndex = lastDigitIndex === -1 ? 0 : lastDigitIndex + 1;
      (event.nativeEvent.target as HTMLInputElement).setSelectionRange(
        lastDigitIndex,
        lastDigitIndex
      );

      if (pattern.test(phone)) {
        phoneData.number = phone.replace(/\D/g, "");
        onSave(phoneData);
      }
    }
  };

  return (
    <Input
      id="phone"
      style={styles.phoneContainer}
      disabled={disabled}
      styles={{
        input:
          !phone || pattern.test(phone)
            ? styles.phoneInput
            : { ...styles.phoneInput, ...styles.phoneError },
      }}
      addonBefore={
        <Button
          disabled={disabled}
          style={styles.buttonClose}
          icon={
            <CloseOutlined
              disabled={disabled}
              onClick={() => {
                onRemove && onRemove(phoneData);
              }}
            />
          }
        />
      }
      pattern="[0-9_]{3}-[0-9_]{3}-[0-9_]{4}"
      value={phone}
      onChange={onChange}
      onClick={async (
        event: React.MouseEvent<HTMLInputElement, MouseEvent>
      ) => {
        if (!phone || phone === mask) {
          await setPhone("___-___-____");
          (event.target as HTMLInputElement).value = mask;
          (event.target as HTMLInputElement).setSelectionRange(0, 0);
        }
      }}
      addonAfter={phoneData.phoneType}
      placeholder={phoneData.phoneType}
    />
  );
};

const ContactInfo = (props: {
  contactInfo: ContactInformation;
}): ReactElement => {
  const { contactInfo } = props;

  const toDateString = (obj: any): string => {
    console.log(obj);
    const dateFormats = [
      {
        regex:
          /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(.\d+)?(Z|([+-]\d{2}:\d{2})))?$/,
        format: "YYYY-MM-DDTHH:mm:ss.sssZ",
      },
      {
        regex: /^\d{2}.\d{2}.\d{4}(\s\d{2}:\d{2}(:\d{2})?)?$/,
        format: "DD.MM.YYYY HH:mm:ss",
      },
      {
        regex: /^\d{2}\/\d{2}\/\d{4}(\s\d{2}:\d{2}(:\d{2})?)?$/,
        format: "DD/MM/YYYY HH:mm:ss",
      },
      {
        regex: /^\d{2}\/\d{2}\/\d{4}(\s\d{2}:\d{2}(:\d{2})?)?$/,
        format: "MM/DD/YYYY HH:mm:ss",
      },
      {
        regex: /^[A-Za-z]+ \d{1,2}, \d{4}(\s\d{2}:\d{2}(:\d{2})?)?$/,
        format: "MMMM D, YYYY HH:mm:ss",
      },
      {
        regex: /^\d{1,2} [A-Za-z]+ \d{4}(\s\d{2}:\d{2}(:\d{2})?)?$/,
        format: "D MMMM YYYY HH:mm:ss",
      },
    ];

    for (const { regex, format } of dateFormats) {
      if (regex.test(obj)) {
        const date = new Date(obj);
        if (!isNaN(date.getTime())) {
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          const year = date.getFullYear();
          return `${month}/${day}/${year}`;
        }
      }
    }

    return "";
  };

  return (
    <div style={styles.contactInfoContainer}>
      <div style={{ ...styles.contactInfoCard, flex: "0 1 300px" }}>
        <InlineField
          label="Contact Type:"
          value={contactInfo.type === "Owner" ? "All" : contactInfo.type}
        />
        <InlineField label="Contact ID:" value={contactInfo.contactId} />
        <InlineField
          label="Owner Status:"
          value={contactInfo.primaryOwner === "Yes" ? "Owner" : "Non-Owner"}
        />
      </div>
      <div style={styles.contactInfoCard}>
        <div>
          <ThreeLineField label="" value={contactInfo.contactFullName} />
          <ThreeLineField label="" value={contactInfo.address} />
          <ThreeLineField label="" value={contactInfo.address2} />
        </div>
        {/* <Flex wrap="wrap">
          <div style={styles.contactInfoCardNoFlex}> */}
        <Row align={"middle"} gutter={[16, 8]} style={{ marginTop: "10px" }}>
          {contactInfo.phones &&
            contactInfo.phones?.map((el, index) => (
              <>
                {el?.number && (
                  <Col>
                    <>
                      <b>{el?.phoneType}:</b> &nbsp;
                      {el?.number}
                    </>
                  </Col>
                )}
              </>
            ))}
          {contactInfo.email && (
            <Col>
              <b>Email:</b> &nbsp;
              {contactInfo.email}
            </Col>
          )}
          {contactInfo.birthdate &&
            toDateString(contactInfo.birthdate) !== "" && (
              <Col >
                <b>D.O.B.:</b> &nbsp;
                {moment(contactInfo.birthdate)?.format("MM/DD/YYYY")}
              </Col>
            )}
          {contactInfo.dateReceived && (
            <Col >
              <b>HOPA Date Received:</b> &nbsp;
              {moment(contactInfo.dateReceived)?.format("MM/DD/YYYY")}
            </Col>
          )}

          {contactInfo.expirationDate &&
            toDateString(contactInfo.expirationDate) !== "" && (
              <Col>
                <b>HOPA Expiration Date:</b> &nbsp;
                {moment(contactInfo.expirationDate)?.format("MM/DD/YYYY")}
              </Col>
            )}

          {contactInfo.note && (
            <Col sm={24}>
              <b>Note:</b> &nbsp;
              {contactInfo.note}
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};
export default ContactInfo;
