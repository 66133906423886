import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Card, Flex, message  } from "antd";
import Component from "../../../components";
import Swal from "sweetalert2";
import Spinner from "../../../components/Spinner/Spiner";
import Checkbox from "antd/es/checkbox/Checkbox";
import DateTimePicker from "../../../components/dateTime/DateTime";
import dayjs from "dayjs";
import moment from "moment";
import { useStore } from "../../../root-store-context";
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";
import CommonFuncitons from "../../../utils/CommonFunciton";


const Tenant = () => {
  const navigate = useNavigate();
  const { apiStore, applicationStore } = useStore();
  const {
    TextInput,
    SelectBox,
    UploadButton,
    DynamicTable,
    Modal,
    Radio,
  } = Component;
  const [payaResponse, setPayaResponse] = useState(null);
  const [allStatesAndCities, setAllStatesAndCities] = useState([]);
  const [tenantState, setTenantState] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [temp, setTemp] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState("");
  const [isAchPayment, setIsAchPayment] = useState(false);
  const [isEmergencyContactModalVisible, setIsEmergencyContactModalVisible] =
    useState(false);
  const [isVehicleModalVisible, setIsVehicleModalVisible] = useState(false);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [isPetModalVisible, setIsPetModalVisible] = useState(false);
  const [isEcModalVisible, setIsEcModalVisible] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [tenantData, setTenantData] = useState([]);
  const [tenantId, setTenantId] = useState(1);
  const [toEditId, setToEditId] = useState(0);
  const [toEditVehicleId, setToEditVehicleId] = useState(0);
  const [toEditPetId, setToEditPetId] = useState(0);
  const [contactPage, setContactPage] = useState("");
  const [vehiclePage, setVehiclePage] = useState("");
  const [petPage, setPetPage] = useState("");
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [conatcToEditId, setContactToEditId] = useState(0);
  const [mailingCity, setMailingCity] = useState([]);
  const [petId, setPetId] = useState(1);
  const [mailingState, setMailingState] = useState("");
  const [contactId, setContactId] = useState(1);
  const [vehicleId, setVehicleId] = useState(1);
  const [vehicleData, setVehicleData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [petData, setPetData] = useState([]);
  const [state, setState] = useState([]);
  const [form] = Form.useForm();
  const [propertyForm] = Form.useForm();
  const [tenantForm] = Form.useForm();
  const [vehicleForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [paymentForm] = Form.useForm();
  const [petForm] = Form.useForm();
  const [maintenance, setMaintenance] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [contact, setContact] = useState({});
  const [pet, setPet] = useState({});
  const [tenant, setTenant] = useState({});
  const [city, setCity] = useState([]);
  const [tenantCity, setTenantCity] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [fee, setFee] = useState(null);
  const [stateDisclaimer, setStateDisclaimer] = useState("");
  const [propertyDisclaimer, setPropertyDisclaimer] = useState("");
  const [stateDisclaimerId, setStateDisclaimerId] = useState(null);
  const [propertyDisclaimerId, setPropertyDisclaimerId] = useState(null);
  const [isLoadingStatesAndCities, setIsLoadingStatesAndCities] =
    useState(true);
  const [isLoadingDisclaimer, setIsLoadingDisclaimer] = useState(true);
  const [isLoadingTenantResponse, setIsLoadingTenantResponse] = useState(false);
  const [isLoadingPaymentResponse, setIsLoadingPaymentResponse] = useState(false);
  const [registrationId, setRegistrationId] = useState(null);
  const [isNewRegistration, setIsNewRegistration] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState("");
  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [formatted, setFormatted] = useState({});
  const [newStates, setNewStates] = useState([]);
  const [newCities, setNewCities] = useState([]);
  const [hasVehicle, setHasVehicle] = useState(false);
  const [hasPet, setHasPet] = useState(false);
  const [property, setProperty] = useState({});
  const [user, setUser] = useState({});
  const [propertyInformation, setPropertyInformation] = useState({});

  let modifiedCitys = [];
  const handleEdit = (tenantToEdit) => {
    setToEditId(tenantToEdit.id);
    let tempTenant = {};
    tempTenant["tenantId"] = tenantToEdit.id;
    tempTenant["tenantFirstName"] = tenantToEdit.FirstName;
    tempTenant["tenantLastName"] = tenantToEdit.LastName;
    tempTenant["tenantDesignation"] = tenantToEdit.Designation;
    tempTenant["tenantPhone"] = tenantToEdit.Phone;
    tempTenant["tenantEmail"] = tenantToEdit.Email;
    tempTenant["violation"] = tenantToEdit.Voilation;
    tempTenant["sendCopyToTenant"] = tenantToEdit.SendCopyToTenant;
    tempTenant["contactData"] = tenantToEdit.EmergencyContacts;
    setContactData(tenantToEdit.EmergencyContacts);
    setTemp(tempTenant);
    tenantForm.setFieldsValue(tempTenant);
    showModal("edit");
  };
  const handleEditContact = (contactToEdit) => {
    setContactToEditId(contactToEdit.id);
    let tempContact = {};
    tempContact["contactFirstName"] = contactToEdit.FirstName;
    tempContact["contactLastName"] = contactToEdit.LastName;
    tempContact["contactEmail"] = contactToEdit.Email;
    tempContact["contactPhone"] = contactToEdit.Phone;
    tempContact["contactRelationship"] = contactToEdit.Relationship;
    contactForm.setFieldsValue(tempContact);
    showContactModal("edit");
  };
  const handleEditVehicle = (vehicleToEdit) => {
    setToEditVehicleId(vehicleToEdit.id);
    let tempVehicle = {};
    tempVehicle["vehicleYear"] = vehicleToEdit.Year;
    tempVehicle["vehicleMake"] = vehicleToEdit.Make;
    tempVehicle["vehicleModel"] = vehicleToEdit.Model;
    tempVehicle["vehicleColor"] = vehicleToEdit.Color;
    tempVehicle["vehicleLicensePlate"] = vehicleToEdit.License;
    tempVehicle["vehicleState"] = vehicleToEdit.State;
    tempVehicle["vehicleSpot"] = vehicleToEdit.Spot;
    vehicleForm.setFieldsValue(tempVehicle);
    showVehicleModal("edit");
  };
  const handleEditPet = (petToEdit) => {
    setToEditPetId(petToEdit.id);
    let tempPet = {};
    tempPet["petName"] = petToEdit.Name;
    tempPet["petType"] = petToEdit.Type;
    tempPet["petBreed"] = petToEdit.Breed;
    tempPet["petDueDate"] = dayjs(dueDate, "MM/DD/YYYY");
    tempPet["petLicense"] = petToEdit.License;
    tempPet["petAge"] = petToEdit.Age;
    petForm.setFieldsValue(tempPet);
    showPetModal("edit");
  };


  let states;
  const showModal = (mode) => {
    setIsModalVisible(true);
    setPage(mode);
  };
  const showVehicleModal = (mode) => {
    setIsVehicleModalVisible(true);
    setVehiclePage(mode);
  };
  const showContactModal = (mode) => {
    setIsContactModalVisible(true);
    setContactPage(mode);
  };
  const showPetModal = (mode) => {
    setIsPetModalVisible(true);
    setPetPage(mode);
  };
  const openEmergencyContactModal = () => {
    setIsEmergencyContactModalVisible(true);
  };
  const handlePetDelete = (id) => {
    const updatedData = petData.filter((pet) => pet.id !== id);
    setPetData(updatedData);
  };
  function handleTenantDelete(id) {
    const updatedData = tenantData.filter((tenant) => tenant.id !== id);
    setTenantData(updatedData);
  }
  const handleContactDelete = (id) => {
    const updatedData = contactData.filter((contact) => contact.id !== id);
    setContactData(updatedData);
  };
  const handleVehicleDelete = (id) => {
    const updatedData = vehicleData.filter((vehicle) => vehicle.id !== id);
    setVehicleData(updatedData);
  };
  const closeEmergencyContactModal = () => {
    setIsEmergencyContactModalVisible(false);
  };
  const handleCancel = () => {
    tenantForm.resetFields();
    setIsModalVisible(false);
  };
  const handleVehicleCancel = () => {
    vehicleForm.resetFields();
    setIsVehicleModalVisible(false);
  };
  const handleContactCancel = () => {
    contactForm.resetFields();
    setIsContactModalVisible(false);
  };
  const handlePaymentCancel = () => {
    Swal.fire({
      text: "Request Submitted Successfully.",
      icon: "success",
      confirmButtonColor: "#5A7890",
      customClass: {
        title: "text-danger",
        content: "text-danger",
      },
    });
    navigate(`/Forms/TenantRegistrations/`)
    paymentForm.resetFields(); 
    setIsPaymentModalVisible(false);
  };
  const handleShowConfirmation = () => {
    setIsConfirmVisible(true);
  };

  const handleConfirmCancel = () => {
    setIsConfirmVisible(false);
    handlePaymentCancel(); 
  };

  const handleCancelConfirmation = () => {
    setIsConfirmVisible(false);
  };
  const handlePetCancel = () => {
    petForm.resetFields();
    setIsPetModalVisible(false);
  };

  const handleFormSubmit = (values) => {
    if (!isContactModalVisible) {
      const newTenant = {
        id: tenantId,
        FirstName: tenant.tenantFirstName,
        LastName: tenant.tenantLastName,
        Phone: tenant.tenantPhone,
        Email: tenant.tenantEmail,
        Voilation: tenant.violation == true ? true : false,
        SendCopyToTenant: tenant.sendCopyToTenant == true ? true : false,
        EmergencyContacts: contactData,
      };
      if (page === "edit") {
        newTenant.id = toEditId;
        const updatedTenants = tenantData.map((t) =>
          t.id === newTenant.id ? newTenant : t
        );
        setTenantData(updatedTenants);
      } else {
        setTenantId(tenantId + 1);
        setTenantData([...tenantData, newTenant]);
      }
      setContactData([]);
      tenantForm.resetFields();
      setIsModalVisible(false);
    }
  };
  const handleVehicleFormSubmit = (values) => {
    const newVehicle = {
      id: vehicleId,
      Year: vehicle.vehicleYear,
      Make: vehicle.vehicleMake,
      Model: vehicle.vehicleModel,
      Color: vehicle.vehicleColor,
      License: vehicle.vehicleLicensePlate,
      State: vehicle.vehicleState,
      Spot: vehicle.vehicleSpot,
    };
    if (vehiclePage === "edit") {
      newVehicle.id = toEditVehicleId;
      const updatedVehicles = vehicleData.map((v) =>
        v.id === newVehicle.id ? newVehicle : v
      );
      setVehicleData(updatedVehicles);
    } else {
      setVehicleId(vehicleId + 1);
      setVehicleData([...vehicleData, newVehicle]);
    }

    vehicleForm.resetFields();
    setIsVehicleModalVisible(false);
  };
  const handleContactFormSubmit = (values) => {
    const newContact = {
      id: contactId,
      FirstName: contact.contactFirstName,
      LastName: contact.contactLastName,
      Email: contact.contactEmail,
      Phone: contact.contactPhone,
      Relationship: contact.contactRelationship,
    };

    if (contactPage === "edit") {
      newContact.id = conatcToEditId;
      const updatedContacts = contactData.map((c) =>
        c.id === newContact.id ? newContact : c
      );
      setContactData(updatedContacts);
    } else {
      setContactId(contactId + 1);
      setContactData([...contactData, newContact]);
    }

    contactForm.resetFields();
    setIsContactModalVisible(false);
  };
  const handlePetFormSubmit = (values) => {
    const newPet = {
      id: petId,
      Name: pet.petName,
      Type: pet.petType,
      Breed: pet.petBreed,
      RabiesDueDate: dueDate,
      License: pet.petLicense,
      Age: pet.petAge,
    };
    if (petPage === "edit") {
      newPet.id = toEditPetId;
      const updatedPets = petData.map((p) => (p.id === newPet.id ? newPet : p));
      setPetData(updatedPets);
    } else {
      setPetId(petId + 1);
      setPetData([...petData, newPet]);
    }

    petForm.resetFields();
    setIsPetModalVisible(false);
  };
  const handleViewContacts = (tenant) => {
    setSelectedTenant(tenant);
    setIsEcModalVisible(true);
  };

  const handleModalOk = () => {
    setIsEcModalVisible(false);
    setSelectedTenant(null);
  };

  const handleModalCancel = () => {
    setIsEcModalVisible(false);
    setSelectedTenant(null);
  };

  // const handlePaymentFormSubmit = async (values) => {
  //   const req = {
  //     "tenantId": registrationId,
  //     "cardNumber": isAchPayment ? values?.accountNumber : values?.cardNumber?.replace(/\s+/g, ''),
  //     "cvv": values?.cvv,
  //     "cardExpiry": values?.cardExpiry,
  //     "cardHolderName": values?.cardHolderName,
  //     "routingNumber": values?.routingNumber,
  //     "isAchPayment": isAchPayment,
  //     "paymentMethod": isAchPayment ? "ACH" : "CC",
  //     "amount": fee
  //   }
  //   setIsLoadingPaymentResponse(true);
  //   const res = await apiStore.FormsApiClient.ProcessTenantPayment(req);
  //   console.log(res?.data, "SANDHYA Payment");
  //   setIsLoadingPaymentResponse(false);
  //   if (res?.data?.status) {
  //     Swal.fire({
  //       text: "Request Submitted Successfully.",
  //       icon: "success",
  //       confirmButtonColor: "#5A7890",
  //       customClass: {
  //         title: "text-danger",
  //         content: "text-danger",
  //       },
  //     });
  //     handlePaymentCancel();
  //   }
  //   else if (!res?.data?.status) {
  //     Swal.fire({
  //       text: res?.data?.message,
  //       icon: "error",
  //       confirmButtonColor: "#5A7890",
  //       customClass: {
  //         title: "text-danger",
  //         content: "text-danger",
  //       },
  //     });
  //   }
  // };
  const options = [
    {
      value: true,
      name: "New",
    },
    {
      value: false,
      name: "Renewal",
    },
  ];
  const paymentOptions = [
    { name: "Pay with Credit Card", value: "CC" },
    { name: "Pay with eCheck/ACH", value: "ACH" },
  ];

  const asctohex = (asc) => {
      return Array.from(asc)
        .map((char) => ("0" + char.charCodeAt(0).toString(16)).slice(-2))
        .join("");
    };
  
    const generateUrl = () => {
      // Required parameters
      const params = {
        "developer-id": process.env.REACT_APP_DEVELOPER_ID,
        "hash-key": process.env.REACT_APP_HASH_KEY,
        "user-id": process.env.REACT_APP_USER_ID,
        "timestamp": Math.floor((Date.now() - 60000) / 1000), // Current timestamp minus 1 minute
        "data": {
          "transaction": {
            "payment_method": isAchPayment ? "ach" : "cc",
            "action": isAchPayment ? "debit" : "sale",
            "transaction_amount": fee,
            "location_id": process.env.REACT_APP_LOCATION_ID,
            "transaction_api_id": uuidv4(),
            "show_cvv": "1",
            "parent_send_message": 1,
            "parent_close": true,
            "display_close_button": false,
            "order_num": `${registrationId}`,
            "stylesheet_url":`${process.env.REACT_APP_REDIRECT_URI}/style/style.css`
          },
        },
      };
  
      // [1] Generate the secure hash
      const userHashKey = process.env.REACT_APP_HASH_KEY; // Replace with your actual user hash key
      params["hash-key"] = CryptoJS.HmacSHA256(
        params["user-id"] + params["timestamp"],
        userHashKey
      ).toString(CryptoJS.enc.Hex);
  
      // [2] Convert the data to hex
      params["data"] = asctohex(JSON.stringify(params["data"]));
  
      // [3] Build the URL to retrieve the form
      const urlParams = Object.entries(params)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
  
      const url = `https://api.payaconnect.com/v2/payform?${urlParams}`;
  
      // Log the generated URL for debugging
      console.log("Generated URL:", url);
  
      // Optionally, open the form in a new tab or window
      // window.open(url, "_blank");
      return (
        <>
          <div className={`${!isAchPayment ? "first-frame" : "iframe"}`}>
            <iframe src={url} title="Example iframe"></iframe>
          </div>
        </>
      );
    };
  
    window.addEventListener("message", receiveMessage, false);
    async function receiveMessage(event) {
      // Make sure the value for allowed matches the domain of the iFrame you are embedding.
      var allowed = "https://api.payaconnect.com";
      // Verify sender's identity
      if (event.origin !== allowed) return;
      setIsPaymentModalVisible(false);
      setIsLoadingPaymentResponse(true);
  
      // Add logic here for doing something in response to the message
      const payaResponse = JSON.parse(event.data);
      setPayaResponse(JSON.parse(event.data))
      console.log(payaResponse);
      
    }
  const handleRadioChange = (e) => {
    setIsAchPayment(e.target.value === "ACH");
  };
  const hasVehiclesOptions = [
    {
      value: true,
      name: "Yes",
    },
    {
      value: false,
      name: "No",
    },
  ];
  const hasPetOptions = [
    {
      value: true,
      name: "Yes",
    },
    {
      value: false,
      name: "No",
    },
  ];

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleChange = ({ fileList: newFileList }) => {
    let fileNames = [];
    let i = 0;
    newFileList.forEach((file) => {
      if (i == 0) {
        fileNames = [...fileNames, file.name];
        i = i + 1;
      } else {
        if (fileNames.includes(file.name)) {
          Swal.fire({
            title: "Warning",
            text: "File name already exists.(" + file.name + ")",
            icon: "warning",
            confirmButtonColor: "#44637e",
          });
          newFileList.splice(newFileList.indexOf(file), 1);
        } else {
          fileNames = [...fileNames, file.name];
        }
      }
      setFileList(fileNames);
    });
    const maxSize = 5 * 1024 * 1024 * 1024;
    let totalSize = 0;
    let filesChecked = [];
    newFileList.forEach((file) => {
      totalSize = totalSize + file?.originFileObj?.size;
      if (totalSize > maxSize) {
        Swal.fire({
          title: "Warning",
          text: "Can not upload files of size more than 5 GB.",
          icon: "warning",
          confirmButtonColor: "#44637e",
        });
      } else {
        filesChecked.push(file);
      }
    });
    setFileList(filesChecked);
  };

  const onFinish = async (values) => {
    if (
      !isContactModalVisible &&
      !isVehicleModalVisible &&
      !isPetModalVisible &&
      !isModalVisible
    ) {
      if (tenantData.length == 0) {
        Swal.fire({
          text: "Please provide atleast one tenant info.",
          icon: "warning",
          confirmButtonColor: "#5A7890",
          customClass: {
            title: "text-danger",
            content: "text-danger",
          },
        });
        return;
      }

      try {
        const formData = new FormData();
        formData.append(`leaseInfo.newOrRenew`, form.getFieldValue("newOrRenew") ? "New" : "Renewal");
        formData.append(`leaseInfo.startDay`, startDate);
        formData.append(`leaseInfo.endDay`, endDate);
        if(fee){
        formData.append(`amount`, fee);
        }
        formData.append(`property`, applicationStore?.SelectedAddress?.communityId);
        formData.append(`userId`, applicationStore?.SelectedAddress?.userId?.toString());
        if(propertyDisclaimer){
          formData.append(`PropertyDisclaimer`, propertyDisclaimer);
          formData.append(`propertyDisclaimerId`, propertyDisclaimerId);
        }
        if(stateDisclaimer){
          formData.append(`StateDisclaimer`, stateDisclaimer);
          formData.append(`stateDisclaimerId`, stateDisclaimerId);
        }

        formData.append(`residentKey`, applicationStore?.SelectedAddress?.residentKey);
        formData.append(`residentContactKey`, applicationStore?.SelectedAddress?.residentContactKey);
        if(form.getFieldValue("sendCopyToEmail")){
          formData.append(`SendCopyToEmail`, form.getFieldValue("sendCopyToEmail"));
        }
        formData.append(`SubmitterFirstName`, propertyForm.getFieldValue("submitterFirstName"));
        formData.append(`SubmitterLastName`, propertyForm.getFieldValue("submitterLastName"));
        formData.append(`SubmitterEmail`, propertyForm.getFieldValue("submitterEmail"));
        if(propertyForm.getFieldValue("submitterTelephone")){
          formData.append(`SubmitterTelephone`, propertyForm.getFieldValue("submitterTelephone"));
        }
        formData.append(`PropertyFullAddress`, propertyForm.getFieldValue("propertyFullAddress"));
        formData.append(`PropertyZip`, propertyForm.getFieldValue("propertyZip"));
        if(propertyForm.getFieldValue("mailingAddress")){
          formData.append(`MailingAddress`, propertyForm.getFieldValue("mailingAddress"));

        }
      if(propertyForm.getFieldValue("mailingCity")){
        formData.append(`MailingCity`, propertyForm.getFieldValue("mailingCity"));
      }
        formData.append(`PropertyCity`, propertyForm.getFieldValue("propertyCity"));
        formData.append(`PropertyState`, propertyForm.getFieldValue("propertyState"));
        if(propertyForm.getFieldValue("mailingState")){
          formData.append(`MailingState`, propertyForm.getFieldValue("mailingState"));

        }
        if(propertyForm.getFieldValue("mailingZip")){
          formData.append(`MailingZip`, propertyForm.getFieldValue("mailingZip"));
        }
       
        fileList.forEach((file, index) => { formData.append(`supportingDocuments`, file.originFileObj, file.name); });

        tenantData.forEach((obj, index) => {
          formData.append(`tenants[${index}].firstName`, obj.FirstName);
          formData.append(`tenants[${index}].lastName`, obj.LastName);
          formData.append(`tenants[${index}].phone`, obj.Phone);
          formData.append(`tenants[${index}].email`, obj.Email);
          formData.append(`tenants[${index}].voilation`, obj.Voilation ? "true" : "false");
          formData.append(`tenants[${index}].sendCopyToTenant`, obj.SendCopyToTenant ? "true" : "false");
          obj.EmergencyContacts.forEach((contact, i) => {
            formData.append(`tenants[${index}].emergencyContacts[${i}].firstName`, contact.FirstName);
            formData.append(`tenants[${index}].emergencyContacts[${i}].lastName`, contact.LastName);
            if(contact.Email){
              formData.append(`tenants[${index}].emergencyContacts[${i}].email`, contact.Email);
            }
            formData.append(`tenants[${index}].emergencyContacts[${i}].phone`, contact.Phone);
            if(contact.Relationship){
              formData.append(`tenants[${index}].emergencyContacts[${i}].relationship`, contact.Relationship);
            }
          });
        });
        vehicleData.forEach((obj, index) => {
          formData.append(`vehicles[${index}].make`, obj.Make);
          formData.append(`vehicles[${index}].model`, obj.Model);
          formData.append(`vehicles[${index}].year`, obj.Year);
          formData.append(`vehicles[${index}].color`, obj.Color);
          formData.append(`vehicles[${index}].license`, obj.License);
          formData.append(`vehicles[${index}].state`, obj.State);
          if(obj.Spot){
            formData.append(`vehicles[${index}].spot`, obj.Spot);
          }
        });
        petData.forEach((obj, index) => {
          formData.append(`pets[${index}].name`, obj.Name);
          if(obj.Type){
            formData.append(`pets[${index}].type`, obj.Type);
          }
          formData.append(`pets[${index}].breed`, obj.Breed);
          if(obj.RabiesDueDate){
            formData.append(`pets[${index}].rabiesDueDate`, obj.RabiesDueDate);
          }
          if(obj.License){
            formData.append(`pets[${index}].license`, obj.License);
          }
          formData.append(`pets[${index}].age`, obj.Age);
        });

        if (formData) {
          setIsLoadingTenantResponse(true);
          const res = await apiStore.FormsApiClient.PostTenantRegistrationForms(formData);
          console.log(res?.data, "SANDHYA");
          setIsLoadingTenantResponse(false);

          if (res?.data?.status) {
            debugger;
            setRegistrationId(res?.data?.data);
            handleClearButton();
            if (isNewRegistration && fee) {
              setIsPaymentModalVisible(true);
            }
            else if (!isNewRegistration) {
              Swal.fire({
                text: "Request Successfully Submitted.",
                icon: "success",
                confirmButtonColor: "#5A7890",
                customClass: {
                  title: "text-danger", 
                  content: "text-danger",
                },
              });
              navigate(`/Forms/TenantRegistrations/`)
            }
          }
          else {
            Swal.fire({
              text: "Submission unsuccessful. Please try again or contact us.",
              icon: "error",
              confirmButtonColor: "#5A7890",
              customClass: {
                title: "text-danger", 
                content: "text-danger", 
              },
            });
          }
        }
      } catch (error) {
        Swal.fire({
          text: "Submission unsuccessful. Please try again or contact us.",
          icon: "error",
          confirmButtonColor: "#5A7890",
          customClass: {
            title: "text-danger", 
            content: "text-danger", 
          },
        });
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    form.validateFields()
    .then((values) => {
      
      console.log('Form Values:', values);
      
    })
    .catch((errorInfo) => {
     
   message.error({content:"Please provide all required data!",style:{textAlign:'right'}})

    });
   };

  const HandleTenantOnChange = async (e) => {
    const { name, id, value } = e?.target;
    let updatedTenant;
    if (name == "violation" || name == "age" || name == "sendCopyToTenant") {
      updatedTenant = { ...tenant, [name]: e.target.checked };
    } else {
      updatedTenant = { ...tenant, [id]: value };
    }

    if (id === "tenantState" && modifiedCitys) {
      setTenantState(value);
      const cities = newCities?.[value];
      updatedTenant = { ...tenant, ["tenantCity"]: null };
      let newC = [];
      if (cities?.length > 0) {
        for (let items of cities) {
          newC.push({ name: items, value: items });
        }
      }
      setTenantCity(newC);
    }
    setTenant(updatedTenant);
    tenantForm.setFieldsValue(updatedTenant);
  };
  const HandleVehicleOnChange = async (e) => {
    const { id, value } = e?.target;
    const updatedVehicle = { ...vehicle, [id]: value };
    setVehicle(updatedVehicle);
    vehicleForm.setFieldsValue(updatedVehicle);
  };
  const HandleContactOnChange = async (e) => {
    const { id, value } = e?.target;
    const updatedContact = { ...contact, [id]: value };
    setContact(updatedContact);
    contactForm.setFieldsValue(updatedContact);
  };
  const HandlePetOnChange = async (e) => {
    const { id, value } = e?.target;
    const updatedPet = { ...pet, [id]: value };
    setPet(updatedPet);
    petForm.setFieldsValue(updatedPet);
  };

  const handleCardNumberChange = (e) => {
    const { value } = e.target;

    const cleaned = value.replace(/\D+/g, "");

    const limited = cleaned.slice(0, 16);

    const formattedCard = limited.match(/.{1,4}/g)?.join(" ") || "";

    const formatted = { cardNumber: formattedCard };

    setFormatted(formatted);
    paymentForm.setFieldsValue(formatted);
  };
  const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    const phoneRegex = /^\(\d{3}\)\s\d{3}-\d{4}$/;

    if (!phoneRegex.test(value)) {
      return Promise.reject(
        new Error("Phone Number must be in the format (xxx) xxx-xxxx")
      );
    }

    return Promise.resolve();
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;

    const cleaned = value.replace(/\D+/g, "");

    const limited = cleaned.slice(0, 10);

    const formattedPhone = limited
      .replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3")
      .replace(/^(\d{3})(\d{3})$/, "($1) $2-")
      .replace(/^(\d{3})$/, "($1)");

    tenant["tenantPhone"] = formattedPhone;
    tenantForm.setFieldsValue(tenant);
  };
  const handleContactPhoneChange = (e) => {
    const { value } = e.target;

    const cleaned = value.replace(/\D+/g, "");

    const limited = cleaned.slice(0, 10);

    const formattedPhone = limited
      .replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3")
      .replace(/^(\d{3})(\d{3})$/, "($1) $2-")
      .replace(/^(\d{3})$/, "($1)");

    contact["contactPhone"] = formattedPhone;
    contactForm.setFieldsValue(contact);
  };

  useEffect(() => {
    if (states?.length > 0) {
      const newState = states.map((item) => ({
        name: item,
        value: item,
      }));
      setState(newState);
    }
  }, [states]);


  useEffect(() => {
    if(payaResponse){
      console.log("status_id:",payaResponse?.status_id, "reason_code_id:",payaResponse?.reason_code_id, "type_id:",payaResponse?.type_id, payaResponse?.auth_amount);
      const statusMessage = CommonFuncitons.getStatusMessage(payaResponse?.status_id);
      const reasonCodeMessage = CommonFuncitons.getReasonCodeMessage(payaResponse?.reason_code_id);
      const typeMessage = CommonFuncitons.getTypeMessage(payaResponse?.type_id);
      console.log("reasonCodeMessage:",reasonCodeMessage, "statusMessage:",statusMessage,"typeMessage:",typeMessage)   
    
        apiStore.FormsApiClient.ProcessTenantPayment(payaResponse).then((res)=>{
          navigate(`/Forms/TenantRegistrations/`)
          if (res?.data?.status) {
            if(reasonCodeMessage){
                        Swal.fire({
                              title: `${statusMessage}`,
                              text: `Reason: ${reasonCodeMessage}`,
                              icon: "error",
                              confirmButtonColor: "#5A7890",
                            });
                      }
                      else{
                        if(isNewRegistration == true && payaResponse){
                          Swal.fire({
                            title: isAchPayment?'ACH - Accepted':'CC - Approved',
                            text: isAchPayment?`${statusMessage}`:`Payment Done Successfully.`,
                            icon: "success",
                            confirmButtonColor: "#5A7890",
                          });
                        }
                          else{
                            Swal.fire({
                              text: "Request Submitted Successfully.",
                              icon: "success",
                              confirmButtonColor: "#5A7890",
                              customClass: {
                                title: "text-danger",
                                content: "text-danger",
                              },
                            });
                          }
                      }
        } else {
          Swal.fire({
            text: "Something went wrong at the moment",
            icon: "error",
            confirmButtonColor: "#5A7890",
          });
        }
        })
        .catch(()=>{}).finally(()=>{setIsLoadingPaymentResponse(false);})
    
        
        }
    
  }, [apiStore, payaResponse]);
  const dateFormatRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  const expiryDateFormatRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
  function isValidDate(dateStr) {
    const [month, day, year] = dateStr.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    return (
      date.getMonth() === month - 1 &&
      date.getDate() === day &&
      date.getFullYear() === year
    );
  }


  const handleCvvChange = (e) => {
    debugger;
    const { value } = e.target;

    const cleaned = value.replace(/\D+/g, "");

    const limited = cleaned.slice(0, 4);

    const formattedCard = limited.match(/.{1,4}/g)?.join(" ") || "";

    const formatted = { cvv: formattedCard };

    setFormatted(formatted);
    paymentForm.setFieldsValue(formatted);
  };
  const validateExpiryDate = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const formatRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    if (!formatRegex.test(value)) {
      return Promise.reject(
        new Error("Expiry Date must be in the format MM/YY")
      );
    }

    const month = parseInt(value.slice(0, 2), 10);
    const year = parseInt(value.slice(3, 5), 10);

    const now = moment();
    const currentYear = now.year();
    const currentMonth = now.month() + 1;

    const fullYear = year >= currentYear % 100 ? 2000 + year : 1900 + year;

    if (month < 1 || month > 12) {
      return Promise.reject(
        new Error("Expiry Date month must be between 01 and 12")
      );
    }

    const expiryDate = moment()
      .set({ year: fullYear, month: month - 1, date: 1 })
      .endOf("month");

    if (expiryDate.isBefore(now, "month")) {
      return Promise.reject(new Error("Expiry Date must be in the future"));
    }

    return Promise.resolve();
  };
  const validateCVV = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const cleanedNumber = value.replace(/\s+/g, "");

    if (!/^\d{4}$/.test(cleanedNumber) && !/^\d{3}$/.test(cleanedNumber)) {
      return Promise.reject(
        new Error("CVV can be either 3 or 4 digits long.")
      );
    }

    return Promise.resolve();
  };
  const handleExpiryChange = (e) => {
    debugger;
    const { value } = e.target;

    const cleaned = value.replace(/\D+/g, "");

    const limited = cleaned.slice(0, 4);

    const formattedCard = limited.match(/.{1,2}/g)?.join("/") || "";

    const formatted = { cardExpiry: formattedCard };

    setFormatted(formatted);
    paymentForm.setFieldsValue(formatted);
  };
  const validateCardNumber = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const cleanedNumber = value?.replace(/\s+/g, "");

    if (!/^\d{16}$/.test(cleanedNumber)) {
      return Promise.reject(
        new Error("Credit Card Number must be exactly 16 digits")
      );
    }

    return Promise.resolve();
  };


  const validationRules = {
    relationship: [
      { pattern: /^[a-zA-Z\s]+$/, message: "Relation must contain only letters and spaces" },
    ],
    firstName: [
      { required: true, message: "First Name is required" },
      { pattern: /^[a-zA-Z\s]+$/, message: "First Name must contain only letters and spaces" },
      {
        validator: (rule, value) => {
          if (value && !value.trim()) {
            return Promise.reject("First Name cannot contain only spaces");
          }
          return Promise.resolve();
        },
      },
    ],
    lastName: [
      { required: true, message: "Last Name is required" },
      { pattern: /^[a-zA-Z\s]+$/, message: "Last Name must contain only letters and spaces" },
      {
        validator: (rule, value) => {
          if (value && !value.trim()) {
            return Promise.reject("Last Name cannot contain only spaces");
          }
          return Promise.resolve();
        },
      },
    ],
    address: [
      { required: true, message: "Address is required" },
      {
        validator: (rule, value) => {
          if (value && !value.trim()) {
            return Promise.reject("Address cannot contain only spaces");
          }
          return Promise.resolve();
        },
      },
    ],
    state: [{ required: true, message: "State is required" }],
    city: [{ required: true, message: "City is required" }],
    property: [{ required: true, message: "Association is required" }],
    zip: [
      { required: true, message: "Zip code is required" },
      {
        pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
        message: "Valid Zip code formats are: 12345 and 12345-6789",
      },
      {
        validator: (rule, value) => {
          if (value && value.replace(/[^0-9]/g, "").length > 10) {
            return Promise.reject("Zip code cannot be longer than 10 digits");
          }
          return Promise.resolve();
        },
      },
    ],
    notRequiredZip: [
      {
        pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
        message: "Valid Zip code formats are: 12345 and 12345-6789",
      },
      {
        validator: (rule, value) => {
          if (value && value.replace(/[^0-9]/g, "").length > 10) {
            return Promise.reject("Zip code cannot be longer than 10 digits");
          }
          return Promise.resolve();
        },
      },
    ],
    phone: [
      { required: true, message: "Phone number is required" },
      { validator: validatePhoneNumber },
    ],
    notRequiredPhone: [
      { validator: validatePhoneNumber },
    ],
    email: [
      { required: true, message: "Email is required" },
      { type: "email", message: "Invalid email format" },
    ],
    notRequiredEmail: [
      { type: "email", message: "Invalid email format" },
    ],
    date: [
      {
        required: true,
        message: "Date is required",
      },
    ],
    year: [
      {
        required: true,
        message: "Year is required",
      },
      {
        pattern: /^[0-9]{4}$/,
        message: "Year must be a 4-digit number",
      },
      {
        validator: (_, value) => {
          const currentYear = new Date().getFullYear();
          if (!value || (value >= 1900 && value <= currentYear)) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(`Year must be between 1900 and ${currentYear}`)
          );
        },
      },
    ],
    make: [
      {
        required: true,
        message: "Make is required",
      },
      {
        validator: (rule, value) => {
          if (value && !value.trim()) {
            return Promise.reject("Make cannot contain only spaces");
          }
          return Promise.resolve();
        },
      },
    ],
    model: [
      {
        required: true,
        message: "Model is required",
      },
      {
        validator: (rule, value) => {
          if (value && !value.trim()) {
            return Promise.reject("Model cannot contain only spaces");
          }
          return Promise.resolve();
        },
      },
    ],
    color: [
      {
        required: true,
        message: "Color is required",
      },
      {
        validator: (rule, value) => {
          if (value && !value.trim()) {
            return Promise.reject("Color cannot contain only spaces");
          }
          return Promise.resolve();
        },
      },
      {
        pattern: /^[a-zA-Z\s]+$/,
        message: "Color must contain only letters and spaces",
      },
    ],
    license: [
      {
        required: true,
        message: "License is required",
      },
      {
        validator: (rule, value) => {
          if (value && !value.trim()) {
            return Promise.reject("License cannot contain only spaces");
          }
          return Promise.resolve();
        },
      },
    ],
    name: [
      { required: true, message: "Name is required" },
      {
        validator: (rule, value) => {
          if (value && !value.trim()) {
            return Promise.reject("Name cannot contain only spaces");
          }
          return Promise.resolve();
        },
      },
      {
        pattern: /^[a-zA-Z\s]+$/,
        message: "Name must contain only letters and spaces",
      },
    ],
    breed: [
      { required: true, message: "Breed is required" },
      {
        validator: (rule, value) => {
          if (value && !value.trim()) {
            return Promise.reject("Breed cannot contain only spaces");
          }
          return Promise.resolve();
        },
      },
    ],
    age: [
      {
        required: true,
        message: "Age is required",
      },
      { pattern: /^\d*$/, message: "Age must be in digit(s)." },
    ],
    routingNumber: [
      {
        required: true,
        message: "Routing Number is required",
      },
      { pattern: /^\d*$/, message: "Routing Number must be in digit(s)." },
    ],
    newOrRenew: [
      {
        required: true,
        message: "Type of Lease is required.",
      },
    ],
    cardNumber: [
      {
        required: true,
        message: "Card Number is required",
      },
      { validator: validateCardNumber },
    ],
    accountNumber: [
      {
        required: true,
        message: "Account Number is required",
      },
      { pattern: /^\d*$/, message: "Account Number must be in digit(s)." },
    ],
    accountHolderName: [
      {
        required: true,
        message: "Account Holder Name is required",
      },
      {
        pattern: /^[a-zA-Z\s]+$/,
        message: "Card Holder Name must contain only letters and spaces",
      },
    ],
    cvv: [
      {
        required: true,
        message: "CVV is required",
      },
      { validator: validateCVV },
    ],
    cardExpiry: [
      {
        required: true,
        message: "Expiry Date is required",
      },
      { validator: validateExpiryDate },
    ],
    cardHolderName: [
      {
        required: true,
        message: "Card Holder Name is required",
      },
      {
        pattern: /^[a-zA-Z\s]+$/,
        message: "Card Holder Name must contain only letters and spaces",
      },
    ],
    endDate: [
      { required: true, message: 'Please select an end date' },
      { type: 'object', message: 'End date must be a valid date' },
      {
        validator: async (_, value) => {
          const startDate = form.getFieldValue('startDay'); 

          if (!startDate || !value) {
            return;
          }

          if (value.isBefore(startDate) || value.isSame(startDate)) {
            return Promise.reject('End date must be greater than start date');
          }

          return Promise.resolve();
        }
      }
    ],
  };

  const handleDateChange = (date, dateString) => {
    setDueDate(dateString); 
  };
 
  const handleStartDateChange = (date, dateString) => {
    setStartDate(dateString); 
  };
  const handleEndDateChange = (date, dateString) => {
    setEndDate(dateString); 
  };
  function handleClearButton() {
    form.resetFields(); 
    petForm.resetFields(); 
    tenantForm.resetFields(); 
    contactForm.resetFields(); 
    vehicleForm.resetFields(); 
    setCity([]); 
    setCommunities([]); 
    setTenantData([]); 
    setContactData([]); 
    setPetData([]); 
    setVehicleData([]); 
    setFileList([]); 
    setDueDate(null); 
    setStartDate(null); 
    setEndDate(null); 
  }
  useEffect(() => {
    setIsLoadingStatesAndCities(true);

    apiStore.FormsApiClient.GetAllStatesCitiesAndCommunities().then((res) => {
      setAllStatesAndCities(res?.data);
      states =
        res?.data["item1"] &&
        Object.keys(res?.data["item1"])?.length > 0 &&
        Object.entries(res?.data["item1"]).map(([key, value]) => key);

      let newSt = [];
      states &&
        states.forEach((element) => {
          element = element.substring(1, element.length - 1);
          const name = element.split(",")[0].trim();
          let value = element.split(",")[1].trim();
          newSt.push({ name: name, value: value });
        });
      setNewStates(newSt);
      let citys = res?.data["item1"];
      modifiedCitys =
        citys &&
        Object.entries(citys).reduce((acc, [key, value]) => {
          key = key.substring(1, key.length - 1);
          const newKey = key.split(",")[1].trim();
          acc[newKey] = value;
          return acc;
        }, {});
      setNewCities(modifiedCitys);
    });
    setIsLoadingStatesAndCities(false);
  }, []);

  useEffect(() => {
    setIsLoadingDisclaimer(true);

    apiStore.FormsApiClient.GetDisclaimersAndFee(
      applicationStore?.SelectedAddress?.residentKey
    ).then((res) => {
      if(res?.status){
      console.log(res,"Registration fee");

        setPropertyDisclaimer(res?.data?.data?.propertyDisclaimer);
        setStateDisclaimer(res?.data?.data?.stateDisclaimer);
        setFee(res?.data?.data?.propertyFee);
        setPropertyDisclaimerId(res?.data?.data?.propertyDisclaimerId);
        setStateDisclaimerId(res?.data?.data?.stateDisclaimerId);
      }
      console.log(res,"Registration fee");
    });

    apiStore.FormsApiClient.GetPropertyAndUserInformation().then((res) => {
      setProperty(res?.data?.property);
      setUser(res?.data?.user);
     
      propertyInformation["submitterFirstName"] = res?.data?.user?.firstName;
      propertyInformation["submitterLastName"] = res?.data?.user?.lastName;
      propertyInformation["submitterEmail"] = res?.data?.user?.email;
      propertyInformation["mailingAddress"] = res?.data?.user?.streetAndUnitNumber;
      propertyInformation["mailingState"] = res?.data?.user?.state;
      propertyInformation["mailingCity"] = res?.data?.user?.city;
      propertyInformation["mailingZip"] = res?.data?.user?.zip;
      propertyInformation["propertyFullAddress"] = res?.data?.property?.streetNumber + ' ' + res?.data?.property?.streetName;
      propertyInformation["propertyState"] = res?.data?.property?.state;
      propertyInformation["propertyCity"] = res?.data?.property?.city;
      propertyInformation["propertyZip"] = res?.data?.property?.zip;
      propertyForm.setFieldsValue(propertyInformation);
    });
    setIsLoadingDisclaimer(false);
  }, [applicationStore?.SelectedAddress?.residentKey]);
  
  const columns = [
    { title: "First Name", dataIndex: "FirstName", key: "FirstName" },
    { title: "Last Name", dataIndex: "LastName", key: "LastName" },
    { title: "Phone", dataIndex: "Phone", key: "Phone" },
    { title: "Email", dataIndex: "Email", key: "Email" },
    {
      title: "Violation",
      dataIndex: "Voilation",
      key: "Voilation",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Email To Tenant",
      dataIndex: "SendCopyToTenant",
      key: "SendCopyToTenant",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Emergency Contacts",
      dataIndex: "emergencyContacts",
      key: "emergencyContacts",
      render: (emergencyContacts, record) => (
        <Button
          style={{
            borderRadius: 19,
            border: "1px solid #5A7890",
            color: "#fff",
            backgroundColor: "#5A7890",
            height: "2rem",
            width: "7rem",
          }}
          onClick={() => handleViewContacts(record)}
        >
          View Contacts
        </Button>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <>
            <div style={{ display: "flex", gap: "4px" }}>
              <Button
                style={{
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: 19,
                  color: "#5b5b5b",
                  backgroundColor: "#fff",
                }}
                onClick={() => handleEdit(record)}
                icon={<EditOutlined />}
              >
              </Button>
              <Button
                style={{
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: 19,
                  color: "#d11a2a",
                  backgroundColor: "#fff",
                }}
                onClick={() => handleTenantDelete(record.id)}
                icon={<DeleteOutlined />}
              >
              </Button>
            </div>
          </>
        </div>
      ),
    },
  ];
  const vehicleColumns = [
    { title: "Year", dataIndex: "Year", key: "Year" },
    { title: "Make", dataIndex: "Make", key: "Make" },
    { title: "Model", dataIndex: "Model", key: "Model" },
    { title: "Color", dataIndex: "Color", key: "Color" },
    {
      title: "License Plate",
      dataIndex: "License",
      key: "License",
    },
    { title: "State", dataIndex: "State", key: "State" },
    { title: "Spot", dataIndex: "Spot", key: "Spot" },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <>
            <div style={{ display: "flex", gap: "4px" }}>
              <Button
                style={{
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: 19,
                  color: "#5b5b5b",
                  backgroundColor: "#fff",
                }}
                onClick={() => handleEditVehicle(record)}
                icon={<EditOutlined />}
              >
              </Button>
              <Button
                style={{
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: 19,
                  color: "#d11a2a",
                  backgroundColor: "#fff",
                }}
                onClick={() => handleVehicleDelete(record.id)}
                icon={<DeleteOutlined />}
              >
              </Button>
            </div>
          </>
        </div>
      ),
    },
  ];
  const petColumns = [
    { title: "Name", dataIndex: "Name", key: "Name" },
    { title: "Type", dataIndex: "Type", key: "Type" },
    { title: "Breed", dataIndex: "Breed", key: "Breed" },
    {
      title: "Rabies Due Date",
      dataIndex: "RabiesDueDate",
      key: "RabiesDueDate",
    },
    { title: "License", dataIndex: "License", key: "License" },
    { title: "Age", dataIndex: "Age", key: "Age" },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <>
            <div style={{ display: "flex", gap: "4px" }}>
              <Button
                style={{
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: 19,
                  color: "#5b5b5b",
                  backgroundColor: "#fff",
                }}
                onClick={() => handleEditPet(record)}
                icon={<EditOutlined />}
              >
              </Button>
              <Button
                style={{
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: 19,
                  color: "#d11a2a",
                  backgroundColor: "#fff",
                }}
                onClick={() => handlePetDelete(record.id)}
                icon={<DeleteOutlined />}
              >
              </Button>
            </div>
          </>
        </div>
      ),
    },
  ];
  const contactColumns = [
    {
      title: "First Name",
      dataIndex: "FirstName",
      key: "FirstName",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      key: "LastName",
    },
    { title: "Email", dataIndex: "Email", key: "Email" },
    { title: "Phone", dataIndex: "Phone", key: "Phone" },
    { title: "Relationship", dataIndex: "Relationship", key: "Relationship" },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <>
            <div style={{ display: "flex", gap: "4px" }}>
              <Button
                style={{
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: 19,
                  color: "#5b5b5b",
                  backgroundColor: "#fff",
                }}
                onClick={() => handleEditContact(record)}
                icon={<EditOutlined />}
              >
              </Button>
              <Button
                style={{
                  boxShadow: 'none',
                  border: 'none',
                  borderRadius: 19,
                  color: "#d11a2a",
                  backgroundColor: "#fff",
                }}
                onClick={() => handleContactDelete(record.id)}
                icon={<DeleteOutlined />}
              >
              </Button>
            </div>
          </>
        </div>
      ),
    },
  ];

  const handleGoToSubmissions = () => {
    navigate(`/Forms/TenantRegistrations`);
  };

  return (
    <>
      <Row>
        <Col sm={16} style={{ margin: "0 auto" }}>
          {(isLoadingStatesAndCities ||
            isLoadingDisclaimer ||
            isLoadingTenantResponse || isLoadingPaymentResponse) && <Spinner />}
          <Card >
          <Flex justify="space-between" wrap="wrap" align="center" gap="16px" style={{ paddingBottom: 24 }}>
            <Flex wrap="wrap" align="center" gap="16px" className="w-mo-100">
                <h1 className="page-title">Tenant Registration</h1>
            </Flex>
            <Flex wrap="wrap" align="center" gap="16px" className="w-mo-100">
                
          <Button 
            type="primary" 
            onClick={handleGoToSubmissions}
          >
            Back To List
          </Button>

            </Flex>
        </Flex >
        {stateDisclaimer && (
                      <div
                        style={{
                          marginTop: "4rem",
                          fontSize: "18px",
                          marginBottom: "2.5rem",
                          border: "1px solid #5A7890",
                          paddingLeft: "20px",
                          paddingTop: "0.2rem",
                          paddingRight: "20px",
                          paddingBottom: "1rem",
                        }}
                      >
                        <p style={{ fontSize: "20px", color: "#5A7890" }}>
                          <b>State Disclaimers</b>
                        </p>
                        <div
                          style={{ fontSize: "16px" }}
                          dangerouslySetInnerHTML={{ __html: stateDisclaimer }}
                        />
                      </div>
                    )}
                    {propertyDisclaimer && (
                      <div
                        style={{
                          fontSize: "18px",
                          marginBottom: "3rem",
                          border: "1px solid #5A7890",
                          paddingLeft: "20px",
                          paddingTop: "0.2rem",
                          paddingRight: "20px",
                          paddingBottom: "1rem",
                        }}
                      >
                        <p style={{ fontSize: "20px", color: "#5A7890" }}>
                          <b>Property Disclaimers</b>
                        </p>
                        <div
                          style={{ fontSize: "16px" }}
                          dangerouslySetInnerHTML={{ __html: propertyDisclaimer }}
                        />
                      </div>
                    )}
              <Form form={propertyForm}>
              <p
                      style={{
                        color: "#5A7890",
                        fontSize: "22px",
                        marginBottom: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Property Information
                    </p>
                    <Row gutter={16}>
                            <Col span={12} className="custom-input-box-wrap">
                              <TextInput
                                name="submitterFirstName"
                                size="large"
                                label="First Name"
                                disabled = {true}
                              />
                            </Col>
                            <Col span={12} className="custom-input-box-wrap">
                              <TextInput
                                name="submitterLastName"
                                size="large"
                                label="Last Name"
                                disabled = {true}
                              />
                            </Col>
                          </Row>
                    <Row gutter={16}>
                    <Col span={12} className="custom-input-box-wrap">
                              <TextInput
                                name="submitterEmail"
                                size="large"
                                label="Email"
                                disabled = {true}
                              />
                            </Col>
                            <Col span={12} className="custom-input-box-wrap">
                              <TextInput
                                name="submitterTelephone"
                                size="large"
                                label="Telephone Number"
                                disabled = {true}
                              />
                            </Col>
                          </Row>
                          <p
                      style={{
                        color: "#5A7890",
                        fontSize: "16px",
                        marginBottom: "5px",
                        marginTop: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Property Address
                    </p>
                    <Row gutter={16}>
                    <Col span={24} className="custom-input-box-wrap">
                    <TextInput
                                name="propertyFullAddress"
                                size="large"
                                label="Address"
                                disabled = {true}
                              />                  
                </Col>
                </Row>
                <Row gutter={16}>
                            <Col span={8} className="custom-input-box-wrap">
                            <TextInput
                                name="propertyState"
                                size="large"
                                label="State"
                                disabled = {true}
                              />  
                            </Col>
                            <Col span={8} className="custom-input-box-wrap">
                            <TextInput
                                name="propertyCity"
                                size="large"
                                label="City"
                                disabled = {true}
                              />  
                          </Col>
                          <Col span={8} className="custom-input-box-wrap">
                              <TextInput
                                name="propertyZip"
                                size="large"
                                label="Zip"
                                disabled = {true}
                              />
                            </Col>
                          </Row> 
                          <p
                      style={{
                        color: "#5A7890",
                        fontSize: "16px",
                        marginBottom: "5px",
                        marginTop: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Mailing Address
                    </p>
                    <Row gutter={16}>
                    <Col span={24} className="custom-input-box-wrap">
                    <TextInput
                                name="mailingAddress"
                                size="large"
                                label="Address"
                                disabled = {true}
                              />   
                            </Col>
                          </Row>
                    <Row gutter={16}>
                            <Col span={8} className="custom-input-box-wrap">
                            <TextInput
                                name="mailingState"
                                size="large"
                                label="State"
                                disabled = {true}
                              />  
                            </Col>
                            <Col span={8} className="custom-input-box-wrap">
                            <TextInput
                                name="mailingCity"
                                size="large"
                                label="City"
                                disabled = {true}
                              />  
                          </Col>
                          <Col span={8} className="custom-input-box-wrap">
                              <TextInput
                                name="mailingZip"
                                size="large"
                                label="Zip"
                                disabled = {true}
                              />
                            </Col>
                          </Row>
              </Form>
            <Row justify={"center"}>


              <Col sm={24}>

                <Form
                  style={{ marginBottom: "2rem" }}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  initialValues={{}}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  form={form}
                  className="custom-fields-form"
                >
                  <>
                    <section style={{ marginBottom: "2rem" }}>
                      <p
                        style={{
                          color: "#5A7890",
                          fontSize: "22px",
                          marginBottom: "5px",
                          marginTop: "5px",
                          fontWeight: "bold",
                          textAlign: "start"
                        }}
                      >
                        Lease Info
                      </p>
                      <Radio
                        label="Choose Type of Lease."
                        rules={validationRules.newOrRenew}
                        name="newOrRenew"
                        options={options}
                        onChange={(e) => setIsNewRegistration(e?.target?.value)}
                      />
                      <Row gutter={16}>
                        <Col span={12}>
                          <DateTimePicker
                            label="Start Date"
                            name="startDay"
                            rules={validationRules.date}
                            size="large"
                            formate="MM/DD/YYYY"
                            placeHolder="Enter Start Date"
                            onChange={handleStartDateChange}
                          />
                        </Col>
                        <Col span={12}>
                          <DateTimePicker
                            label="End Date"
                            name="endDay"
                            rules={validationRules.endDate}
                            size="large"
                            formate="MM/DD/YYYY"
                            placeHolder="Enter End Date"
                            onChange={handleEndDateChange}
                          />
                        </Col>
                      </Row>
                    </section>
                    <section style={{ marginBottom: "1rem" }}>
                      <div className="form-add-field-header">
                        <p
                          style={{
                            color: "#5A7890",
                            fontSize: "22px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            fontWeight: "bold",
                            width: '100%'
                          }}
                        >
                          Tenant Info
                        </p>
                        <Button
                          style={{
                            borderRadius: 19,
                            border: "1px solid #5A7890",
                            color: "#5A7890",
                            backgroundColor: "#fff",
                            height: "2.5rem",
                            width: "8rem",
                          }}
                          onClick={() => {
                            showModal("add");
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add Tenant
                        </Button>
                      </div>
                      <Modal
                        title="Add Tenant"
                        centered
                        footer=""
                        open={isModalVisible}
                        Ok={handleFormSubmit}
                        Cancel={handleCancel}
                        width={900} 
                      >
                        {(pageName) => (
                          <Form form={tenantForm} onFinish={handleFormSubmit}>
                            <Row gutter={16}>
                              <Col span={12} className="custom-input-box-wrap">
                                <TextInput
                                  rules={validationRules.firstName}
                                  name="tenantFirstName"
                                  size="large"
                                  label="First Name"
                                  placeholder="Enter First Name"
                                  onChange={(e) => HandleTenantOnChange(e)}
                                />
                              </Col>
                              <Col span={12} className="custom-input-box-wrap">
                                <TextInput
                                  rules={validationRules.lastName}
                                  name="tenantLastName"
                                  size="large"
                                  label="Last Name"
                                  placeholder="Enter Last Name"
                                  onChange={(e) => HandleTenantOnChange(e)}
                                />
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12} className="custom-input-box-wrap">
                                <TextInput
                                  rules={validationRules.phone}
                                  name="tenantPhone"
                                  size="large"
                                  label="Telephone Number"
                                  placeholder="Enter Phone"
                                  onChange={(e) => {
                                    handlePhoneChange(e);
                                  }}
                                />
                              </Col>
                              <Col span={12} className="custom-input-box-wrap">
                                <TextInput
                                  rules={validationRules.email}
                                  name="tenantEmail"
                                  size="large"
                                  label="Email"
                                  placeholder="Enter Email"
                                  onChange={(e) => HandleTenantOnChange(e)}
                                />
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Checkbox
                                  name="violation"
                                  value={true}
                                  style={{
                                    lineHeight: "32px",
                                  }}
                                  onChange={(e) => HandleTenantOnChange(e)}
                                >
                                  Receive Copy of Violation
                                </Checkbox>
                              </Col>
                              <Col span={12}>
                              <Checkbox
                                name="sendCopyToTenant"
                                value={true}
                                style={{
                                  lineHeight: "32px",
                                }}
                                onChange={(e) => HandleTenantOnChange(e)}
                              >
                                Do you want to send a copy of the form to this tenant?
                              </Checkbox>
                            </Col>
                            </Row>
                            <div className="form-add-field-header">
                              <p
                                style={{
                                  color: "#5A7890",
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  width: "100%",
                                  margin: "0"
                                }}
                              >
                                Emergency Contact
                              </p>
                              <Button
                                style={{
                                  borderRadius: 19,
                                  border: "1px solid #5A7890",
                                  color: "#5A7890",
                                  backgroundColor: "#fff",
                                  height: "2.5rem",
                                  width: "8rem",
                                }}
                                onClick={() => {
                                  showContactModal("add");
                                }}
                                icon={<PlusOutlined />}
                              >
                                Add Contact
                              </Button>
                            </div>
                            <Modal
                              title="Add Contact"
                              centered
                              footer=""
                              open={isContactModalVisible}
                              Ok={handleContactFormSubmit}
                              Cancel={handleContactCancel}
                              width={900} 
                            >
                              {(pageName) => (
                                <Form
                                  form={contactForm}
                                  onFinish={handleContactFormSubmit}
                                >
                                  <Row gutter={16}>
                                    <Col span={12} className="custom-input-box-wrap">
                                      <TextInput
                                        rules={validationRules.firstName}
                                        name="contactFirstName"
                                        size="large"
                                        label="First Name"
                                        placeholder="Enter First Name"
                                        onChange={(e) => HandleContactOnChange(e)}
                                      />
                                    </Col>
                                    <Col span={12} className="custom-input-box-wrap">
                                      <TextInput
                                        rules={validationRules.lastName}
                                        name="contactLastName"
                                        size="large"
                                        label="Last Name"
                                        placeholder="Enter Last Name"
                                        onChange={(e) => HandleContactOnChange(e)}
                                      />
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                    <Col span={12} className="custom-input-box-wrap">
                                      <TextInput
                                        rules={validationRules.email}
                                        name="contactEmail"
                                        size="large"
                                        label="Email"
                                        placeholder="Enter Email"
                                        onChange={(e) => HandleContactOnChange(e)}
                                      />
                                    </Col>
                                    <Col span={12} className="custom-input-box-wrap">
                                      <TextInput
                                        rules={validationRules.notRequiredPhone}
                                        name="contactPhone"
                                        size="large"
                                        label="Telephone Number"
                                        placeholder="Enter Phone" onChange={(e) => {
                                          handleContactPhoneChange(e);
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row gutter={16}>
                                  <Col span={12} className="custom-input-box-wrap">
                                    <TextInput
                                      rules={validationRules.relationship}
                                      name="contactRelationship"
                                      size="large"
                                      label="Relation"
                                      placeholder="Enter Relation"
                                      onChange={(e) => HandleContactOnChange(e)}
                                    />
                                  </Col>
                                </Row>
                                  <div style={{ textAlign: 'right', paddingTop: "16px" }}>
                                    <Button
                                      htmlType="submit"
                                      class="submit-button"
                                      style={{
                                        marginLeft: 8,
                                        borderRadius: 19,
                                        color: "white",
                                        backgroundColor: "#5A7890",
                                        height: "2.5rem",
                                        width: "8rem",
                                      }}
                                    >
                                      Add
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </Modal>
                            {contactData.length > 0 && (
                              <DynamicTable
                                columns={contactColumns}
                                data={contactData}
                              />
                            )}
                            <div style={{ textAlign: 'right', paddingTop: "16px" }}>
                              <Button
                                htmlType="submit"
                                class="submit-button"
                                style={{
                                  marginLeft: 8,
                                  borderRadius: 19,
                                  color: "white",
                                  backgroundColor: "#5A7890",
                                  height: "2.5rem",
                                  width: "8rem",
                                }}
                              >
                                Submit
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Modal>

                      <DynamicTable columns={columns} data={tenantData} />
                      {selectedTenant && (
                        <Modal
                          title="Emergency Contacts"
                          centered
                          open={isEcModalVisible}
                          Ok={handleModalOk}
                          Cancel={handleModalCancel}
                          width={800}
                          footer=""
                          children={(pageName) => (
                            <DynamicTable
                              columns={[
                                { title: "Name", dataIndex: "name", key: "name" },
                                {
                                  title: "Email",
                                  dataIndex: "email",
                                  key: "email",
                                },
                                {
                                  title: "Phone",
                                  dataIndex: "phone",
                                  key: "phone",
                                },
                                {
                                  title: "Relationship",
                                  dataIndex: "relationship",
                                  key: "relationship",
                                },
                              ]}
                              data={selectedTenant.EmergencyContacts.map(
                                (contact) => ({
                                  key: contact.email,
                                  name: `${contact.FirstName} ${contact.FirstName}`,
                                  email: contact.Email,
                                  phone: contact.Phone,
                                  relationship: contact.Relationship,
                                })
                              )}
                            />
                          )}
                        />
                      )}
                    </section>
                    <Radio
                      label="Is this Tenant registering any vehicles?"
                      name="hasVehicle"
                      options={hasVehiclesOptions}
                      value={hasVehicle}
                      onChange={(e) => setHasVehicle(e.target.value === true)}
                    />
                    {hasVehicle && (
                      <section style={{ marginBottom: "2rem" }}>
                        <div className="form-add-field-header">
                          <p
                            style={{
                              color: "#5A7890",
                              fontSize: "22px",
                              fontWeight: "bold",
                              width: "100%",
                              margin: "0"
                            }}
                          >
                            Vehicle Info
                          </p>
                          <Button
                            style={{
                              borderRadius: 19,
                              border: "1px solid #5A7890",
                              color: "#5A7890",
                              backgroundColor: "#fff",
                              height: "2.5rem",
                              width: "8rem",
                            }}
                            onClick={() => {
                              showVehicleModal("add");
                            }}
                            icon={<PlusOutlined />}
                          >
                            Add Vehicle
                          </Button>
                        </div>
                        <Modal
                          title="Add Vehicle"
                          centered
                          footer=""
                          open={isVehicleModalVisible}
                          Ok={handleVehicleFormSubmit}
                          Cancel={handleVehicleCancel}
                          width={900} 
                        >
                          {(pageName) => (
                            <Form form={vehicleForm} onFinish={handleVehicleFormSubmit}>
                              <Row gutter={16}>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    rules={validationRules.year}
                                    name="vehicleYear"
                                    size="large"
                                    label="Year"
                                    placeholder="Enter Year"
                                    onChange={(e) => HandleVehicleOnChange(e)}
                                  />
                                </Col>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    rules={validationRules.make}
                                    name="vehicleMake"
                                    size="large"
                                    label="Make"
                                    placeholder="Enter Make"
                                    onChange={(e) => HandleVehicleOnChange(e)}
                                  />
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    rules={validationRules.model}
                                    name="vehicleModel"
                                    size="large"
                                    label="Model"
                                    placeholder="Enter Model"
                                    onChange={(e) => HandleVehicleOnChange(e)}
                                  />
                                </Col>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    rules={validationRules.color}
                                    name="vehicleColor"
                                    size="large"
                                    label="Color"
                                    placeholder="Enter Color"
                                    onChange={(e) => HandleVehicleOnChange(e)}
                                  />
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    rules={validationRules.license}
                                    name="vehicleLicensePlate"
                                    size="large"
                                    label="License Plate"
                                    placeholder="Enter License Plate"
                                    onChange={(e) => HandleVehicleOnChange(e)}
                                  />
                                </Col>
                                <Col span={12} className="custom-input-box-wrap">
                                  <SelectBox
                                    options={newStates}
                                    rules={validationRules.state}
                                    name="vehicleState"
                                    size="large"
                                    label="State"
                                    placeholder="Enter State"
                                    onChange={(e) => HandleVehicleOnChange(e)}
                                  />
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    name="vehicleSpot"
                                    size="large"
                                    label="Spot / Garage"
                                    placeholder="Enter Spot / Garage"
                                    onChange={(e) => HandleVehicleOnChange(e)}
                                  />
                                </Col>
                              </Row>
                              <div style={{ textAlign: 'right', paddingTop: "16px" }}>
                                <Button
                                  htmlType="submit"
                                  class="submit-button"
                                  style={{
                                    marginLeft: 8,
                                    borderRadius: 19,
                                    color: "white",
                                    backgroundColor: "#5A7890",
                                    height: "2.5rem",
                                    width: "8rem",
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            </Form>
                          )}
                        </Modal>
                        <DynamicTable columns={vehicleColumns} data={vehicleData} />
                      </section>
                    )}
                    <Radio
                      label="Is this Tenant registering any pets?"
                      name="hasPet"
                      options={hasPetOptions}
                      value={hasPet}
                      onChange={(e) => setHasPet(e.target.value === true)}
                    />
                    {hasPet && (
                      <section>
                        <div className="form-add-field-header">
                          <p
                            style={{
                              color: "#5A7890",
                              fontSize: "22px",
                              fontWeight: "bold",
                              width: "100%",
                              margin: "0"
                            }}
                          >
                            Pets Info
                          </p>
                          <Button
                            style={{
                              borderRadius: 19,
                              border: "1px solid #5A7890",
                              color: "#5A7890",
                              backgroundColor: "#fff",
                              height: "2.5rem",
                              width: "8rem",
                            }}
                            onClick={() => {
                              showPetModal("add");
                            }}
                            icon={<PlusOutlined />}
                          >
                            Add Pet
                          </Button>
                        </div>
                        <Modal
                          title="Add Pet"
                          centered
                          footer=""
                          open={isPetModalVisible}
                          Ok={handlePetFormSubmit}
                          Cancel={handlePetCancel}
                          width={900} 
                        >
                          {(pageName) => (
                            <Form form={petForm} onFinish={handlePetFormSubmit}>
                              <Row gutter={16}>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    rules={validationRules.name}
                                    name="petName"
                                    size="large"
                                    label="Name"
                                    placeholder="Enter Name"
                                    onChange={(e) => HandlePetOnChange(e)}
                                  />
                                </Col>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    name="petType"
                                    size="large"
                                    label="Type"
                                    placeholder="Enter Type"
                                    onChange={(e) => HandlePetOnChange(e)}
                                  />
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    rules={validationRules.breed}
                                    name="petBreed"
                                    size="large"
                                    label="Breed"
                                    placeholder="Enter Breed"
                                    onChange={(e) => HandlePetOnChange(e)}
                                  />
                                </Col>
                                <Col span={12} className="custom-input-box-wrap">
                                  <DateTimePicker
                                    label="Rabies Due Date"
                                    name="petDueDate"
                                    size="large"
                                    formate="MM/DD/YYYY"
                                    placeHolder="Enter Due Date (MM/DD/YYYY)"
                                    onChange={handleDateChange} 
                                  />
                                </Col>
                              </Row>
                              <Row gutter={16} justify={'end'}>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    name="petLicense"
                                    size="large"
                                    label="License#"
                                    placeholder="Enter License"
                                    onChange={(e) => HandlePetOnChange(e)}
                                  />
                                </Col>
                                <Col span={12} className="custom-input-box-wrap">
                                  <TextInput
                                    rules={validationRules.age}
                                    name="petAge"
                                    size="large"
                                    label="Age"
                                    placeholder="Enter Age"
                                    onChange={(e) => HandlePetOnChange(e)}
                                  />
                                </Col>
                                <div style={{ textAlign: 'right', paddingTop: "16px" }}>
                                  <Button
                                    htmlType="submit"
                                    class="submit-button"
                                    style={{
                                      marginLeft: 8,
                                      borderRadius: 19,
                                      color: "white",
                                      backgroundColor: "#5A7890",
                                      height: "2.5rem",
                                      width: "8rem",
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Row>
                            </Form>
                          )}
                        </Modal>
                        <DynamicTable columns={petColumns} data={petData} />
                      </section>
                    )}
                    <p
                      style={{
                        color: "#5A7890",
                        fontSize: "22px",
                        marginBottom: "1.5rem",
                        marginTop: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      Supporting Documents
                    </p>
                    <UploadButton
                      handlePreview={handlePreview}
                      fileList={fileList}
                      setFileList={setFileList}
                      setPreviewImage={setPreviewImage}
                      previewImages={previewImage}
                      setPreviewOpen={setPreviewOpen}
                      previewOpen={previewOpen}
                      name="files"
                      handleChange={handleChange}
                      number={10}
                    />
                    <Row gutter={16}>
                            <Col span={24} className="custom-input-box-wrap">
                            <p>Please enter email addresses separated by a semicolon, if you want to send a copy of this form to somebody else (Optional).</p>
                              <TextInput
                                name="sendCopyToEmail"
                                size="large"
                                placeholder="Enter email address to receive email"
                              />
                            </Col>
                          </Row>
                    

                                              
<Modal
                title="Are you sure to skip the payment at this time?"
                centered
                footer = ""
                open={isConfirmVisible}
                Ok={handleConfirmCancel}
                Cancel={()=>{setIsConfirmVisible(false)}}
                width={500}
              >
                {(pageName) => (
                  <>
        
                          <div className="mt-2" style={{ textAlign: 'right', marginTop:"1rem" }}>
                          <Button
                          htmlType="submit"
                          class="submit-button"
                          style={{
                            marginLeft: 8,
                            borderRadius: 19,
                            color: "white",
                            backgroundColor: "#5A7890",
                            height: "2rem",
                            width: "6rem",
                          }}
                          onClick={handleCancelConfirmation}
                        >
                          No
                        </Button>
                        <Button
                          htmlType="submit"
                          class="submit-button"
                          style={{
                            marginLeft: 8,
                            borderRadius: 19,
                            color: "white",
                            backgroundColor: "#5A7890",
                            height: "2rem",
                            width: "6rem",
                          }}
                          onClick={handleConfirmCancel}
                        >
                          Yes
                        </Button>
                      </div>
                  
        </>)}
      </Modal>
                    <Modal
                      title="Payment Option"
                      centered
                      footer=""
                      open={isPaymentModalVisible}
                      Cancel={handleShowConfirmation}
                      width={400}
                      height={!isAchPayment ? 300 : 700}
                    >
                      {(pageName) => (
                        <>
                          {(isLoadingPaymentResponse) && <Spinner />}
                          <Form form={paymentForm}>
                                    <Radio
                                      label=""
                                      name="paymentMethod"
                                      options={paymentOptions}
                                      value={isAchPayment ? "ACH" : "CC"}
                                      onChange={handleRadioChange}
                                    />
                                             {isAchPayment && <>
    <p>By authorizing this transaction, customer agrees that merchant may convert this transaction into an Electronic Funds Transfer (EFT) transaction or paper draft, and to debit this account for the amount of the transaction. Additionally, in the event this draft or EFT is returned unpaid, a service fee, as allowable by law, will be charged to this account via EFT or draft. In the event you choose to revoke this authorization, please do so by contacting the merchant directly. Please note that processing times may not allow for revocation of this authorization.</p>
      </>}
                                    {generateUrl()}
                                  </Form>
                            
                        </>

                      )}
                    </Modal>
                  </>
                  <div style={{ display: "flex", gap: "16px", justifyContent: "flex-end" }}>
                    <Button
                      onClick={(e) => handleClearButton(e)}
                      style={{
                        borderRadius: 19,
                        border: "1px solid #5A7890",
                        color: "#5A7890",
                        backgroundColor: "#fff",
                        height: "2.5rem",
                        width: "8rem",
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      htmlType="submit"
                      class="submit-button"
                      style={{
                        marginLeft: 8,
                        borderRadius: 19,
                        color: "white",
                        backgroundColor: "#5A7890",
                        height: "2.5rem",
                        width: "8rem",
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Tenant;
