import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import ComplianceConcernForm from "./ComplianceConcernForm";
import { Button, Col, Divider, Flex, Row, Space, Spin, Image } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Title from "antd/es/typography/Title";
import complianceConcern from "../../../images/pageIcons/Forms/ComplianceConcern.png";
import Paragraph from "antd/es/skeleton/Paragraph";

const ComplianceConcern = observer((): ReactElement => {
  const { complianceConcernStore, applicationStore, contactInfoChangeStore } = useStore();

  const navigate = useNavigate();
  const [livingState, setlivingState] = useState("");
  useEffect(() => {
    complianceConcernStore.getHistory();
    contactInfoChangeStore.getOwnerContactInfo().then((result) => {
      if (result !== null) {
        console.log(result.contactAddress.mailingRegion); 
        setlivingState(result.contactAddress.mailingRegion);

      } else {
          
      }
      contactInfoChangeStore.editLoading(false);
  })
  }, []);
 
  return (
    <>
      <Spin spinning={complianceConcernStore.isLoading}>
        <Row>
          <Col
            xxl={{ span: 12, offset: 6 }}
            xl={{ span: 16, offset: 4 }}
            lg={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
          >
            <Flex align="center" wrap="wrap" justify="space-between">
              <Space style={{ flex: "1 1 400px" }}>
                <Title level={2} className='page-title'>
                  Compliance Concern
                </Title>
                
              </Space>
              
              <Space style={{ flex: "1 1", justifyContent: "flex-end" }}>
                {applicationStore.isFeatures(23) &&
                  complianceConcernStore.complianceHistory?.length >
                    0 && (
                    <Button
                      size="middle"
                      type="primary"
                      onClick={() => {
                        navigate("/Forms/ComplianceConcern/History");
                      }}
                    >
                      HISTORY
                    </Button>
                  )}
              </Space>
              <Space style={{ width:"100%" }}>
              <p className='description-text' style={{ width: "100%" }}>
                When you report a compliance concern, you help us maintain the appearance of your community. This report will be kept on file and a letter will be generated, pending confirmation of your compliance concern by the Community Manager.
                </p>
              </Space>
              
              <Space style={{ width:"100%" }}>
                      <p className='description-text' style={{ width: "100%" }}>
                        For all Arizona submissions, the person complaining of the alleged violation must state their first and last name. “Per Arizona law (A.R.S. 33-1242 and A.R.S. 33-1803), any complaint lodged with the Association will remain anonymous unless the alleged violator affirmatively follows certain statutory procedures.”
                      </p>
                </Space>
                {(livingState === 'AZ' || livingState === 'Arizona' || livingState === 'Az') && (
                    <Space style={{ width:"100%" }}>
                      <p className='description-text' style={{ width: "100%" }}>
                        For all Arizona submissions, the person complaining of the alleged violation must state their first and last name. “Per Arizona law (A.R.S. 33-1242 and A.R.S. 33-1803), any complaint lodged with the Association will remain anonymous unless the alleged violator affirmatively follows certain statutory procedures.”
                      </p>
                    </Space>
                  )}
            </Flex>
            <ComplianceConcernForm />
          </Col>
        </Row>
      </Spin>
    </>
  );
});

export default ComplianceConcern;
