import ApiStore from "./appStores/ApiStore";
import ApplicationStore from "./appStores/ApplicationStore";
import AssociationDocumentsStore from "./appStores/BoardVue/AssociationDocumentsStore";
import AnnouncementStore from "./appStores/BoardVue/AnnouncementStore";
import BoardDocumentsStore from "./appStores/BoardVue/BoardDocumentsStore";
import ModalViewerStore from "./appStores/ModalViewerStore";
import ReportStore from "./appStores/BoardVue/ReportStore";
import RicStore from "./appStores/BoardVue/RicStore";
import BoardTaskStore from "./appStores/BoardVue/BoardTasks";
import VendorAgreementsStore from "./appStores/BoardVue/VendorAgreementsStore";
import RecordArchiveStore from "./appStores/BoardVue/RecordArchiveStore";
import CalendarStore from "./appStores/BoardVue/CalendarStore";
import DashBoardStore from "./appStores/BoardVue/DashBoardStore";
import MailArchiveStore from "./appStores/AccountSummaryStore/MailArchiveStore";
import ComplianceStore from "./appStores/AccountSummaryStore/ComplianceStore";
import ArchitecturalRequestsStore from "./appStores/AccountSummaryStore/ArchitecturalRequestsStore";
import PayAndViewBalanceStore from "./appStores/AccountSummaryStore/PayAndViewBalanceStore";
import ArchitecturalRequestStore from "./appStores/AccountSummaryStore/ArchitecturalRequestStore";
import ContactsAndLinksStore from "./appStores/Association/ContactsAndLinksStore";
import ResidentDirectoryStore from "./appStores/Association/ResidentDirectoryStore";
import FAQStore from "./appStores/Association/FAQStore";
import GuestPassesStore from "./appStores/Association/GuestPassesStore";
import ComplianceConcernStore from "./appStores/Forms/ComplianceConcernStore";
import ComplianceAppealStore from "./appStores/AccountSummaryStore/ComplianceAppealStore";
import ElectionsStore from "./appStores/Association/ElectionsStore";
import MaintenanceRequestStore from "./appStores/Forms/MaintenanceRequestStore";
import ContactInfoChangeStore from "./appStores/Forms/ContactInfoChangeStore";
import ABDIGatePassesStore from "./appStores/Association/ABDIGatePassesStore";
import FeeWaiverStore from "./appStores/Forms/FeeWaiverStore";
import PropertyDocumentsStore from "./appStores/Association/PropertyDocumentsStore";
import EntryAccessRequestStore from "./appStores/Forms/EntryAccessRequestStore";
import TicketsAndFitnessStore from "./appStores/Association/TicketsAndFitnessStore";
import RecreationAndTicketsStore from "./appStores/Association/RecreationAndTicketsStore";
import AccountContactsStore from "./appStores/AccountSummaryStore/AccountContactsStore";
import BoardMemberApplicationStore from "./appStores/Forms/BoardMemberApplicationStore";

class RootStore {
  apiStore: ApiStore;
  applicationStore: ApplicationStore;
  modalViewerStore: ModalViewerStore;
  //BoardVue
  associationDocumentsStore!: AssociationDocumentsStore;
  announcementStore!: AnnouncementStore;
  vendorAgreementsStore!: VendorAgreementsStore;
  boardDocumentsStore!: BoardDocumentsStore;
  boardTaskStore!: BoardTaskStore;
  recordArchiveStore!: RecordArchiveStore;
  calendarStore!: CalendarStore;
  ricStore!: RicStore;
  reportStore!: ReportStore;
  dashBoardStore!: DashBoardStore;

  //AccountSummary
  architecturalRequestsStore!: ArchitecturalRequestsStore;
  architecturalRequestStore!: ArchitecturalRequestStore;
  complianceAppealStore!: ComplianceAppealStore;
  complianceStore!: ComplianceStore;
  mailArchiveStore!: MailArchiveStore;
  payAndViewBalanceStore!: PayAndViewBalanceStore;
  accountContactsStore!: AccountContactsStore;

  //Association
  contactsAndLinksStore!: ContactsAndLinksStore;
  residentDirectoryStore!: ResidentDirectoryStore;
  faqStore!: FAQStore;
  complianceConcernStore!: ComplianceConcernStore;
  guestPassesStore!: GuestPassesStore;
  electionsStore!: ElectionsStore;
  abdiGatePassesStore!: ABDIGatePassesStore;
  propertyDocumentsStore!: PropertyDocumentsStore;
  ticketsAndFitnessStore!: TicketsAndFitnessStore;
  recreationAndTicketsStore!: RecreationAndTicketsStore;
  //form
  maintenanceRequestStore!: MaintenanceRequestStore;
  contactInfoChangeStore!: ContactInfoChangeStore;
  feeWaiverStore!: FeeWaiverStore;
  entryAccessRequestStore!: EntryAccessRequestStore;
  boardMemberApplicationStore!: BoardMemberApplicationStore;


  constructor() {
    this.apiStore = new ApiStore();
    this.applicationStore = new ApplicationStore(this.apiStore);
    this.modalViewerStore = new ModalViewerStore(this.apiStore);

    this.ctor()
  }

  refreshStores = async (): Promise<void> => {
    this.applicationStore.isLoadingCommunity = true;

    this.ctor()

    try {
      await this.applicationStore.updateDropdowns();
    } catch (error) {
      console.error(error);
    }
    this.applicationStore.isLoadingCommunity = false;
  };

  ctor = () => {
    //BoardVueGroup
    this.associationDocumentsStore = new AssociationDocumentsStore(this.apiStore);
    this.announcementStore = new AnnouncementStore(this.apiStore);
    this.vendorAgreementsStore = new VendorAgreementsStore(this.apiStore);
    this.boardDocumentsStore = new BoardDocumentsStore(this.apiStore);
    this.boardTaskStore = new BoardTaskStore(this.apiStore);
    this.recordArchiveStore = new RecordArchiveStore(this.apiStore);
    this.ricStore = new RicStore(this.apiStore);
    this.reportStore = new ReportStore(this.apiStore);
    this.dashBoardStore = new DashBoardStore(this.apiStore);
    this.calendarStore = new CalendarStore(this.apiStore);

    //AccountSummary
    this.architecturalRequestsStore = new ArchitecturalRequestsStore(this.apiStore);
    this.architecturalRequestStore = new ArchitecturalRequestStore(this.apiStore);
    this.complianceAppealStore = new ComplianceAppealStore(this.apiStore);
    this.complianceStore = new ComplianceStore(this.apiStore);
    this.mailArchiveStore = new MailArchiveStore(this.apiStore);
    this.payAndViewBalanceStore = new PayAndViewBalanceStore(this.apiStore);
    this.accountContactsStore = new AccountContactsStore(this.apiStore);

    //Association
    this.contactsAndLinksStore = new ContactsAndLinksStore(this.apiStore);
    this.residentDirectoryStore = new ResidentDirectoryStore(this.apiStore);
    this.faqStore = new FAQStore(this.apiStore);
    this.guestPassesStore = new GuestPassesStore(this.apiStore);
    this.complianceConcernStore = new ComplianceConcernStore(this.apiStore);
    this.electionsStore = new ElectionsStore(this.apiStore);
    this.abdiGatePassesStore = new ABDIGatePassesStore(this.apiStore);
    this.propertyDocumentsStore = new PropertyDocumentsStore(this.apiStore);
    this.ticketsAndFitnessStore = new TicketsAndFitnessStore(this.apiStore);
    this.recreationAndTicketsStore = new RecreationAndTicketsStore(this.apiStore);


    //Forms
    this.maintenanceRequestStore = new MaintenanceRequestStore(this.apiStore);
    this.contactInfoChangeStore = new ContactInfoChangeStore(this.apiStore);
    this.feeWaiverStore = new FeeWaiverStore(this.apiStore);
    this.entryAccessRequestStore = new EntryAccessRequestStore(this.apiStore);
    this.boardMemberApplicationStore = new BoardMemberApplicationStore(this.apiStore);
  }

  clearLocalStorages = () => {
    this.applicationStore.clearDropdownsLocalStorages();
  };
}

export default RootStore;
