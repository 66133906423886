import React, { ReactElement, useState } from 'react';
import { App, Button, Col, Divider, Form, Input, Row, Image, Space, UploadFile, UploadProps, Flex, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import maintenance from "../../../images/pageIcons/Forms/MaintenanceRequest.png";
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../root-store-context';
import Title from 'antd/es/typography/Title';
import { getPhoneFormat } from '../../../helpers/phoneFormat';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import CustomUploadItem from '../../BoardVueGroup/BoardTasks/CustomUploadItem';
import FileHelper from '../../../helpers/FileHelper';
import { validateMessages } from '../../../helpers/validationMessages';
import { Attachment } from '../../../api/Common/CommonTypes';
import { MaintenanceRequestItem, ResidentDirectoryProfile, ResidentDirectoryProfileTransfer } from '../../../api/Forms/FormsTypes';

const MaintenanceRequest = observer(() => {

    const [form] = Form.useForm();
    const { apiStore, applicationStore, modalViewerStore, maintenanceRequestStore } = useStore();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const success = () => {
        message.open({
            type: 'success',
            content: 'New Maintenance Request added successfully!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during creation. Please check the entered data.',
        });
    };

    const renderPhone = (text: any): ReactElement => {
        return (
            <a href={"tel:" + text}>
                {text}
            </a>
        );
    };

    const values = Form.useWatch([], form);
    const [fileListAttachments, setFileListAttachments] = useState<UploadFile[]>([]);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                maintenanceRequestStore.setSubmittableForm(true);
            },
            () => {
                maintenanceRequestStore.setSubmittableForm(false);
            },
        );
    }, [values]);

    React.useEffect(() => {
        if (maintenanceRequestStore.shouldResetForm) {
            form.resetFields();
            maintenanceRequestStore.shouldResetForm = false;
            setFileListAttachments([]);
        }
    }, [maintenanceRequestStore.shouldResetForm]);

    React.useEffect(() => {
        maintenanceRequestStore.editLoading(true);
        maintenanceRequestStore.getCommunityResidentDirectoryProfile().then(async (result: ResidentDirectoryProfile) => {
            await maintenanceRequestStore.getHistory();
            if(result !== undefined && result !== null){
                maintenanceRequestStore.setNewPhone(result.DaytimePhone);
                maintenanceRequestStore.setNewEmail(result.EmailAddress);
                form.setFieldsValue({
                    ...maintenanceRequestStore.maintenanceRequestItem,
                    DaytimePhone: getPhoneFormat(maintenanceRequestStore.maintenanceRequestItem.DaytimePhone)
                });
            }
            maintenanceRequestStore.editLoading(false);
        }).catch(() => {
            maintenanceRequestStore.editLoading(false);
            message.open({
                type: 'error',
                content: 'Don`t load profile phone/email',
            });
        })
    }, []);

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );

    const uploadPropsAttachments: UploadProps = {
        onRemove: (file) => {
            if (file.status === "done") {
                return false;
            }
            const updatedFileList = fileListAttachments.filter(
                (attachment) => attachment.uid !== file.uid
            );
            setFileListAttachments(updatedFileList);
            const updatedAttachments = maintenanceRequestStore.Attachments.filter(
                (attachment) => attachment.Guid !== file.uid
            );
            maintenanceRequestStore.Attachments = updatedAttachments;
        },

        onDownload: (file) => {
            if (file.status === "done") {
                maintenanceRequestStore.isLoading = true;
                apiStore.FormsApiClient.getFile(file.uid)
                    .then(res => {
                        modalViewerStore.openFileByData(res.data, file.name);
                        maintenanceRequestStore.isLoading = false;
                    })
                    .catch(error => {
                        maintenanceRequestStore.isLoading = false;
                        console.log(error)
                    });
            }
        },
        beforeUpload: (file, allFiles) => {
            if (allFiles.length + fileListAttachments.length > 10) {
                if (allFiles[0].uid === file.uid) {
                    message.error('You can attach not more than 10 files.');
                }
                return false;
            }
            var isExist = fileListAttachments.some(i => i.name === file.name);
            if (isExist) {
                message.error('File with the same name already exists.');
                return false;
            }
            if (allFiles.some((i: File) => i.size > 10 * 1024 * 1024)) {
                if (file.size > 10 * 1024 * 1024) {
                    message.error('Unacceptable file size. File upload are limited to 10MB.');
                }
                return false;
            }
            setFileListAttachments((prev) => [...prev, file]);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = e => {
                const arrayBuffer = e.target?.result as ArrayBuffer;
                const byteArray = new Uint8Array(arrayBuffer);
                const newAttachment: Attachment = {
                    Name: file.name,
                    Data: FileHelper.ArrayBufferToBase64(Array.from(byteArray)),
                    Guid: file.uid,
                    Url: ""
                };
                maintenanceRequestStore.Attachments = [...maintenanceRequestStore.Attachments, newAttachment];
            };
            return false;
        },
        showUploadList: {
            showDownloadIcon: false,
            showPreviewIcon: false,
            showRemoveIcon: false,
        },
    };

    const onSubmit = async () => {
        maintenanceRequestStore.editLoading(true);
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'Saving'
        });

        maintenanceRequestStore.maintenanceRequestItem.Attachments = maintenanceRequestStore.Attachments;
        maintenanceRequestStore.setNewPhone(maintenanceRequestStore.maintenanceRequestItem.DaytimePhone.replace(/\D/g, ''))
        const allPromisesAttach = maintenanceRequestStore.maintenanceRequestItem.Attachments?.map(async item => {
            const attachGUID = await maintenanceRequestStore.addAttachmentToBuffer(item);
            if (typeof attachGUID === "string") {
                item.Guid = attachGUID;
                item.Data = null as any;
                item.Url = null as any;
            }
        });
        if (allPromisesAttach) {
            await Promise.all(allPromisesAttach);
        }
        maintenanceRequestStore.maintenanceRequestItem.UserId = applicationStore.SelectedAddress.userId.toString();
        maintenanceRequestStore.addMaintenanceRequestItem(applicationStore.SelectedAddress, applicationStore.SelectedAddress.userId.toString()).then((res) => {
            maintenanceRequestStore.resetValue();
            if (res) {
                message.destroy("Saving");
                success()
                maintenanceRequestStore.resetValue();
            }
            else {
                message.destroy("Saving");
                error()
            }
        }).catch(() => {
            message.destroy("Saving");
            error()
            maintenanceRequestStore.editLoading(false);
        })
    }

    return (
        <Spin spinning={maintenanceRequestStore.isLoading}>
            <Row>
                <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Flex align='center' wrap='wrap' justify='space-between'>
                        <Space style={{ flex: "1 1 400px" }}>
                            <Image src={maintenance} preview={false} height={25}/>
                            <Title level={2} className='page-title'>Maintenance Request</Title>
                        </Space>
                        <Space style={{ flex: "1 1", justifyContent: "flex-end" }}>
                            {applicationStore.AllFeaturesForCommunityObject.MaintenanceRequestSP?.isActive &&
                                maintenanceRequestStore.maintenanceHistory?.length > 0 &&
                                <Button size="middle" type="primary" onClick={() => {
                                    console.log("Open History");
                                    navigate("/Forms/MaintenanceRequest/History")
                                }}>HISTORY</Button>
                            }
                        </Space>
                    </Flex>
                    <Form
                        form={form}
                        variant='outlined'
                        layout="vertical"
                        initialValues={{
                            ...maintenanceRequestStore.maintenanceRequestItem
                        }}
                        labelCol={{ span: 24 }}
                        style={{ maxWidth: 900 }}
                        validateMessages={validateMessages}
                        onValuesChange={(changedValues, allValues) => maintenanceRequestStore.maintenanceRequestItem = { ...allValues }}
                        requiredMark={customizeRequiredMark}
                        autoComplete="off"
                        onFinish={onSubmit}
                        scrollToFirstError={{ block: 'center', inline: 'nearest' }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item<MaintenanceRequestItem> name="UserId" hidden>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <p className='description-text'>
                                    Please complete this form to request maintenance on any common areas in your community. Please note - AAM Maintenance Requests do not cover areas owned by municipalities or individual owners. If you have any questions, please contact your Community Manager. If the designated item is identified outside of normal business hours, please call our after-hours Emergency line to speak to a representative at {renderPhone("602-647-3034")}
                                </p>
                                <Form.Item<MaintenanceRequestItem>
                                    label="Daytime Phone"
                                    name={"DaytimePhone"}
                                    style={{ width: "100%" }}
                                    rules={[{ required: true, message: 'Please input your daytime phone!', min: 12 }]}>
                                    <Input onChange={(event) => {
                                        const { value } = event.target;
                                        const formattedValue = getPhoneFormat(value);
                                        form.setFieldsValue({
                                            DaytimePhone: formattedValue,
                                        });

                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<MaintenanceRequestItem> label="Email Address" name="EmailAddress" style={{ width: "100%" }}
                                    rules={[{ required: true, type: "email" }]} >
                                    <Input pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<MaintenanceRequestItem> label="Maintenance Location" name="MaintenanceLocation" rules={[{ required: true }]}>
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<MaintenanceRequestItem> label="Maintenance Concern" name="Description" rules={[{ required: true }]}>
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Divider orientation="left" orientationMargin="0">
                                File Upload
                            </Divider>
                        </Row>
                        <Row>
                            <Col span={24} style={{ paddingBottom: 15 }}>
                                <div>
                                    <Dragger fileList={fileListAttachments}
                                        multiple
                                        {...uploadPropsAttachments}
                                        itemRender={CustomUploadItem}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined style={{color: "#5a7890"}}/>
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Support for a single or bulk upload.
                                        </p>
                                    </Dragger>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right', marginTop: "16px" }}>
                                <Space>
                                    <Button size="middle" onClick={() => {
                                        maintenanceRequestStore.resetValue();
                                    }}>
                                        Clear
                                    </Button>
                                    <Button size="middle" type="primary" htmlType="submit">Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Spin>
    )
})

export default MaintenanceRequest;