import { useEffect, useRef, useState } from "react";
import { useStore } from "../../../root-store-context";
import { observer } from "mobx-react-lite";
import CustomForms from "../../../components/CustomForm/CustomForm";
import Swal from "sweetalert2";
import Spinner from "../../../components/Spinner/Spiner";
import { Card, Col, Form, Row, Button, Image } from "antd";
import Payment from "../../../components/CustomForm/Payment";
import Title from "antd/es/typography/Title";
import { useNavigate } from "react-router-dom";
import "./ViewForm.css";
import maintenance from "../../../images/pageIcons/AccountSummary/ArchitecturalRequests.png";

const ArchitecturalRequest = observer(() => {
  const { apiStore, applicationStore } = useStore();
  const navigate = useNavigate();
  const [forms] = Form.useForm();
  const formRef = useRef(null);
  const [form, setForm] = useState({});
  const [showPaymentSection, setShowPaymentSection] = useState(false);
  const [issubmitting, setIssubmitting] = useState(false);
  const [getArchFormResponse, setGetArchFormResponse] = useState({});
  const [isFeeNotRequired, setIsFeeNotRequired] = useState(false);
  const [submissionFee, setSubmissionFee] = useState(0);
  const [ccFee, setCcFee] = useState(0);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: "",
    cardHolderName: "",
    cardExpiry: "",
    cvv: "",
  });
  let residents = {
    103096: "145011",
    1200782: "5012102",
    112192: "155269",
    1195639: "445445",
  };
  console.log(applicationStore, "applicationStore?.submissionDetails", apiStore.submissionDetail)
  const GetSubmissionForm = () => {
    setLoading(true);
    let residentKey = apiStore?.configApi?.community?.residentKey;
    let ResidentContactKey = apiStore?.configApi?.community?.residentContactKey ? apiStore?.configApi?.community?.residentContactKey?.toString() : residents[residentKey?.toString()]; //146314
    apiStore.FormsApiClient.GetArchForm(apiStore?.configApi?.community?.residentKey?.toString(), ResidentContactKey)
      .then((res) => {
        if (res?.data?.formData && typeof (res?.data?.formData) === "string") {

          setGetArchFormResponse(res?.data);
          apiStore.setSubmission(res?.data?.userId)
          applicationStore.setSubmissionDetail(res?.data)
          applicationStore.setForm(JSON.parse(res?.data?.formData));
          applicationStore.setIsSupportedDocRequired(res?.data?.isSupportedDocRequired)
          setForm(JSON.parse(res?.data?.formData));
          setSubmissionFee(res?.data?.submissionFee || 0);
          setCcFee(res?.data?.ccFee || 0);
          setIsFeeNotRequired(res?.data?.isFeeNotRequired);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        console.log("Error in GetArchForm");
      });
  }
  const onSubmitPayment = async ({ formData, payment, submissionValue }) => {
    setIssubmitting(true);
    delete formData?.display;
    let residentKey = apiStore?.configApi?.community?.residentKey?.toString();
    const submissionData = {
      HOAId: getArchFormResponse?.hoaId,
      CommunityId: getArchFormResponse?.communityId,
      ResidentKey: residentKey,
      //ResidentContactKey: apiStore?.configApi?.community?.residentContactKey ? apiStore?.configApi?.community?.residentContactKey?.toString() : residents[residentKey?.toString()],
      SubmissionData: JSON.stringify(submissionValue),
      FormData: JSON.stringify(formData),
      ResidentKey: apiStore?.configApi?.community?.residentKey?.toString(),
      ResidentContactKey: apiStore?.configApi?.community?.residentContactKey
        ? apiStore?.configApi?.community?.residentContactKey?.toString()
        : residents[residentKey?.toString()], //"146314",
      // FormData: formData,
      CCFee: ccFee,
      SourceId: 1,
      FormId: getArchFormResponse?.id,
      SubmissionFee: submissionFee,
      CardDetails: {
        CardNumber: payment.cardNumber,
        CardHolderName: payment.cardHolderName,
        Cvv: payment.cvv,
        CardExpiry: payment.cardExpiry,
      },
      Files: applicationStore?.fileList
    };

    try {
      const response = await apiStore.FormsApiClient.MakeNewRequest(
        JSON.stringify(submissionData)
      );

      const result = await response;
      setIssubmitting(false);
      if (response?.data?.status) {
        applicationStore.setFileList([])
        Swal.fire({
          title: "Success",
          text: "Request Created Successfully.",
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "Go to Request List",
          cancelButtonText: "Stay Here",
          confirmButtonColor: "#44637e",
          cancelButtonColor: "#d33",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            applicationStore?.setRequestList([])
            navigate("/AccountInfo/ArchitecturalRequestsNew");
          } else {
            if (formRef?.current) {
              formRef?.current?.formio?.resetValue();
              forms?.resetFields();
              setPaymentDetails({
                cardNumber: "",
                cardHolderName: "",
                cardExpiry: "",
                cvv: "",
              });
            }
          }
        });
      } else {
        Swal.fire({
          title: "Error",
          text: `${response?.data?.message} and updated SubmissionFee :  ${response?.data?.data?.submissionFee} and CcFee : ${response?.data?.data?.ccFee}`,
          icon: "error",
          confirmButtonColor: "#44637e",
          allowOutsideClick: false, // Disable outside click
          allowEscapeKey: false,    // Disable ESC key
        }).then((result) => {
          if (result.isConfirmed) {
            setSubmissionFee(response?.data?.data?.submissionFee || 0);
            setCcFee(response?.data?.data?.ccFee);
          }
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setIssubmitting(false);
      Swal.fire({
        title: "Error",
        text: "An error occurred during submission.",
        icon: "error",
        confirmButtonColor: "#44637e",
      });
    }
  };

  useEffect(() => {
    applicationStore.setFileList([])
    GetSubmissionForm();
  }, []);

  const feeSummary = {
    submissionFee: submissionFee,
    ccFee: ccFee,
    totalAmount: (submissionFee + ccFee).toFixed(2), // Calculate total amount
  };
  const handleCancel = () => {
    forms.resetFields();
    navigate("/AccountInfo/ArchitecturalRequestsNew");
  };
  const handlePaymentSubmit = async (formData) => {
    setIsSubmit(true);
    const invalidFiles = applicationStore?.fileList?.filter((file) => !file.docTypeId);
    if (invalidFiles.length > 0) {
    } else {
      if (formRef.current) {
        const submissionData = formRef.current.formio.submission.data;
        submissionData.isSupportedDocRequired = applicationStore?.documentRequired
        const isValid = await formRef.current.formio.checkValidity(
          submissionData.data,
          true
        );
        if (isValid) {
          const updateComponent = (component) => {
            return {
              ...component,
              defaultValue:
                submissionData[component.key] || component.defaultValue || "",
              components: component.components
                ? component.components.map(updateComponent)
                : undefined,
              columns: component.columns
                ? component.columns.map((column) => ({
                  ...column,
                  components: column.components.map(updateComponent),
                }))
                : undefined,
            };
          };

          const updatedForm = {
            ...form,
            components: form.components.map(updateComponent),
            isSupportedDocRequired: applicationStore?.documentRequired
          };
          const req = {
            formData: updatedForm,
            payment: formData,
            submissionValue: submissionData,
            feeSummary,
          };
          onSubmitPayment(req);
        } else {
          const errors = formRef.current;
          console.log("Validation errors:", errors);
        }
      }

    }
  };
  const HandleAddToCart = async () => {

  }
  // const HandleAddToCart = async () => {
  //   if (formRef.current) {
  //     const submissionData = formRef.current.formio.submission.data;
  //     const isValid = await formRef.current.formio.checkValidity(
  //       submissionData.data,
  //       true
  //     );
  //     if (isValid) {
  //       setIssubmitting(true);
  //       const updateComponent = (component) => ({
  //         ...component,
  //         defaultValue: submissionData[component.key] || "",
  //         columns: component.columns
  //           ? component.columns.map((column) => ({
  //             ...column,
  //             components: column.components?.map((subComponent) =>
  //               updateComponent(subComponent)
  //             ),
  //           }))
  //             ...column,
  //             components: column.components?.map((subComponent) =>
  //               updateComponent(subComponent)
  //             ),
  //           }))
  //           : undefined,
  //       });

  //       const updatedForm = {
  //         ...form,
  //         components: form?.components.map((component) =>
  //           updateComponent(component)
  //         ),
  //       };
  //       let residentKey = apiStore?.configApi?.community?.residentKey?.toString();
  //       let residentContactKey = apiStore?.configApi?.community?.residentContactKey ? apiStore?.configApi?.community?.residentContactKey?.toString() : residents[residentKey?.toString()]; //146314
  //       const req = {
  //         HOAId: getArchFormResponse?.hoaId,
  //         CommunityId: getArchFormResponse?.communityId,
  //         ResidentKey: residentKey,
  //         ResidentContactKey: residentContactKey,
  //         SubmissionData: JSON.stringify(submissionData),
  //         FormData: JSON.stringify(updatedForm),
  //         SourceId: 1,
  //         FormId: getArchFormResponse?.id,
  //       };
  //       apiStore.FormsApiClient.addToCartArch(req)
  //         .then((res) => {
  //           if (res?.status == 200 && res?.data?.status) {
  //             setIssubmitting(false);
  //             apiStore.FormsApiClient.GetCountAddToCartArch(residents[residentKey?.toString()])
  //               .then((res) => {
  //                 let count = res?.data;
  //                 if (res?.data?.status) {
  //                   applicationStore.setCount(count?.totalCount);
  //                   Swal.fire({
  //                     title: "Success",
  //                     text: "Item added successfully",
  //                     icon: "success",
  //                     showCancelButton: false,
  //                     confirmButtonText: "Add More",

  //                     confirmButtonColor: "#44637e",

  //                     allowOutsideClick: false,
  //                   }).then((result) => {
  //                     if (result.isConfirmed) {
  //                       if (formRef?.current) {
  //                         formRef?.current?.formio?.resetValue();
  //                       }
  //                     }
  //                   });
  //                 }
  //               })
  //               .catch(() => {
  //                 setIssubmitting(false);
  //                 Swal.fire({
  //                   title: "Error",
  //                   text: res?.data?.message,
  //                   icon: "error",
  //                   confirmButtonColor: "#44637e",
  //                 });
  //               });
  //           } else {
  //             setIssubmitting(false);
  //             Swal.fire({
  //               title: "Error",
  //               text: res?.data?.message,
  //               icon: "error",
  //               confirmButtonColor: "#44637e",
  //             });
  //           }
  //         })
  //         .catch((error) => {
  //           setIssubmitting(false);
  //           Swal.fire({
  //             title: "Error",
  //             text: error,
  //             icon: "error",
  //             confirmButtonColor: "#44637e",
  //           });
  //         });
  //     } else {
  //       const errors = formRef.current;
  //       console.log("Validation errors:", errors);
  //     }
  //   }
  // };
  const onFinish = () => {
    setIsSubmit(true);
    paymentDetails["paymentStatus"] = true;
    handlePaymentSubmit(paymentDetails);
  };
  return (
    <>
      {(issubmitting || loading) && <Spinner />}
      <Row >
        <Col xs={24} sm={24} md={24} lg={16} className="ant-col ant-col-xs-24 ant-col-xs-offset-0 ant-col-lg-24 ant-col-lg-offset-0 ant-col-xl-16 ant-col-xl-offset-4 ant-col-xxl-12 ant-col-xxl-offset-6 ">
          <div>
            <Card>
              <Row justify={"space-between"} align={"middle"}>
                <Col xs={24} sm={4}>
                  <Button className="req-back-button" onClick={handleCancel}>
                    Back
                  </Button>
                </Col>
                <Col xs={24} sm={20}>
                  <div className="new-arch-request-header">
                    <Image src={maintenance} preview={false} height={25} />
                    <Title
                      level={3}
                      style={{ marginTop: "10px" }}>
                      New Architectural Request
                    </Title>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} style={{ marginTop: "8px" }}><CustomForms
                  forms={form}
                  formRef={formRef}
                  setShowPaymentSection={setShowPaymentSection}
                  showPaymentSection={showPaymentSection}
                  HandleAddToCart={HandleAddToCart}
                  isFeeNotRequired={isFeeNotRequired}
                  getArchFormResponse={getArchFormResponse}
                  fileList={applicationStore?.fileList}
                  setFileList={setFileList}
                  isSubmit={isSubmit}
                />
                  {isFeeNotRequired && (
                    <>
                      <div className="pyament-button">
                        {/* <Button className="primary-button" onClick={handlePaymentSubmit}>
                    Submit
                  </Button> */}
                        {/* <Button className="secondary-button" onClick={HandleAddToCart}>Add To Cart</Button> */}
                      </div>
                    </>
                  )}</Col>
              </Row>

            </Card>
          </div>
          <Form
            layout="vertical"
            className="ant-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{}}
            onFinish={() => {
              onFinish(handlePaymentSubmit);
            }}
            form={forms}
          >

            <div>
              {(!isFeeNotRequired && form?.components?.length > 0) && (
                <Card
                  className="card-space"
                  title={
                    <Title level={4} style={{ marginTop: "10px" }}>
                      Card Details
                    </Title>
                  }
                >
                  <Payment
                    handle={handlePaymentSubmit}
                    form={forms}
                    data={paymentDetails}
                    feeDetails={feeSummary}
                    setData={setPaymentDetails}
                    handleCancel={handleCancel}
                  />
                </Card>
              )}
            </div>
            <div className="payment-submit" style={{ width: "100%" }}>
              {true && (
                <Button
                  htmlType="button"
                  className="cancel"
                  aria-label="Cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              )}
              <Button
                className="submit-button"
                htmlType="submit"
                aria-label="Submit and Pay"
              >
                Submit {!isFeeNotRequired && "& Pay"}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </>
  );
});

export default ArchitecturalRequest;
