import { Button, Flex, Input, Space, Table, Image } from "antd";
import { ReactElement, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../root-store-context";
import { SearchOutlined } from '@ant-design/icons';
import useBreakpoint from "use-breakpoint";
import Column from "antd/es/table/Column";
import { FileNaturalCompare } from "../../../helpers/SorterHelper";
import { useLocation, useNavigate } from "react-router-dom";
import mapsIcon from "../../../images/google_maps.png";
import { ResidentDirectoryItem } from "../../../api/Association/AssociationTypes";

const ResidentDirectory = observer((): ReactElement => {
    const { residentDirectoryStore, applicationStore } = useStore();
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [residentDirectoryItems, setResidentDirectoryItems] = useState<Array<ResidentDirectoryItem>>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
            return;
        }
        if (searchText != "") {
            setIsLoading(true);
            setResidentDirectoryItems([]);
            setCurrentPage(1);
            const timeout = setTimeout(() => {
                searchResidentDirectory();
            }, 750)
            return () => clearTimeout(timeout)
        } else {
            loadResidentDirectory();
        }
    }, [searchText])

    useEffect(() => {
        if (searchText != "") {
            searchResidentDirectory();
        } else {
            loadResidentDirectory();
        }
    }, [currentPage, pageSize])

    const loadResidentDirectory = async () => {
        setIsLoading(true);
        setResidentDirectoryItems([]);
        var response = await residentDirectoryStore.getCommunityResidentDirectoryByPageNum(currentPage, pageSize);
        setResidentDirectoryItems(response.item1);
        setTotal(response.item2)
        setIsLoading(false);
    };

    const searchResidentDirectory = async () => {
        setIsLoading(true);
        setResidentDirectoryItems([]);
        var response = await residentDirectoryStore.searchCommunityResidentDirectoryPage(searchText.trim(), currentPage, pageSize);
        setResidentDirectoryItems(response.item1);
        setTotal(response.item2)
        setIsLoading(false);
    };

    return (
        <>
            <Flex justify="space-between" wrap="wrap" align="center" gap="16px" style={{ paddingBottom: 24 }}>
                <Flex wrap="wrap" align="center" gap="16px" className="w-mo-100">
                    <h1 className="page-title">Resident Directory</h1>
                    <Input value={searchText} className="serach-bar"
                        onChange={(e) => { setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear/>
                </Flex>
                <Button size="middle" type="primary" onClick={() => navigate(location.pathname + "/MyProfile")}>My Profile</Button>
            </Flex>
            <Table className="table-wrapper" dataSource={residentDirectoryItems.map((el, index) => { return { ...el, key: index } })}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                pagination={{
                    defaultPageSize: pageSize, showSizeChanger: true, responsive: true, current: currentPage, onChange: (pageNum: number, pageSize: number) => {
                        setCurrentPage(pageNum)
                        setPageSize(pageSize)
                    }, total: total
                }}
                loading={isLoading}
                size={breakpoint === "mobile" ? "small" : "large"}
                onRow={(record, rowIndex) => {
                    return {
                        // onClick: (event) => { onRowClck(record) },
                    };
                }}>
                <Column title="Title" dataIndex="name" key="name"
                //sorter={(a: ResidentDirectoryItem, b: ResidentDirectoryItem, direction) => FileNaturalCompare(a.name, b.name, direction)}
                />
                <Column title="Address" dataIndex="address" key="address"
                //sorter={(a: ResidentDirectoryItem, b: ResidentDirectoryItem, direction) => FileNaturalCompare(a.address, b.address, direction)}
                />
                <Column responsive={["md"]} title="Nickname" dataIndex="nickname" key="nickname"
                //sorter={(a: ResidentDirectoryItem, b: ResidentDirectoryItem, direction) => FileNaturalCompare(a.nickname, b.nickname, direction)}
                />
                <Column responsive={["md"]} title="Email" dataIndex="email" key="email"
                    //sorter={(a: ResidentDirectoryItem, b: ResidentDirectoryItem, direction) => FileNaturalCompare(a.email, b.email, direction)}
                    render={(email) => (<a href={"mailto:" + email} target="_blank" rel="noopener noreferrer">{email}</a>)}
                />
                <Column title="Cell" dataIndex="cellPhone" key="cellPhone"
                    render={(email) => (<a href={"tel:" + email} target="_blank" rel="noopener noreferrer">{email}</a>)}
                //sorter={(a: ResidentDirectoryItem, b: ResidentDirectoryItem, direction) => FileNaturalCompare(a.cellPhone, b.cellPhone, direction)}
                />
                <Column title="Home" dataIndex="homePhone" key="homePhone"
                    render={(email) => (<a href={"tel:" + email} target="_blank" rel="noopener noreferrer">{email}</a>)}
                //sorter={(a: ResidentDirectoryItem, b: ResidentDirectoryItem, direction) => FileNaturalCompare(a.homePhone, b.homePhone, direction)}
                />
            </Table>
        </>
    )
})

export default ResidentDirectory;