
import { observer } from "mobx-react-lite";
import SearchForm, { RicFilterList } from "./components/SearchForm";
import { useStore } from "../../../root-store-context";
import { useEffect } from "react";
import { Col, ConfigProvider, Flex, Row, Space, Spin } from "antd";
import TableAccountInfo from "./components/TableAccountInfo";
import AccountInfoBlock from "./components/AccountInfoBlock";
import "./ric.css";

const RIC = observer(() => {

    const { ricStore } = useStore();
    useEffect(() => {
        ricStore.getFilterList()
    }, [])
    useEffect(() => () => {
        ricStore.setAccount(undefined);
    }, []);
    useEffect(() => {
        if (ricStore.SelectedAccount) {
            ricStore.getRicInfo()
        } else {
            ricStore.setShowRicInfo(false)
        }
    }, [ricStore.SelectedAccount])

    return <div className="ric">
        <ConfigProvider
            key="RicConfigProvider"
            theme={{
                token: {
                },
                components: {
                    Tabs: {
                        cardBg: "#5a7890",
                        colorBgContainer: "#dedede",
                        itemColor: "#ffffff",
                        itemSelectedColor: "#000000",
                        itemHoverColor: "#000000",
                        itemActiveColor: "#000000",
                    },
                    Button: {
                        primaryColor: "#ffffff",
                        defaultColor: "#ffffff",
                        colorBgContainer: "#5a7890",
                        controlHeight: 38,
                        borderRadius: 10,

                        defaultHoverBg: "#dedede",
                        defaultHoverColor: "#000000",
                        defaultBorderColor: "#dedede",
                        defaultActiveColor: "#ffffff",
                        defaultHoverBorderColor: "#5a7890"
                    },
                    Table: {
                        rowHoverBg: "rgb(218, 221, 225)"
                    }
                }
            }}>
            <Spin spinning={ricStore.IsLoadingApp}>
                <Flex justify="space-between" wrap="wrap" align="center" gap="middle" style={{ paddingBottom: 24 }}>
                    <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
                        <Space style={{ flexWrap: "wrap" }}>
                            <h1 className="page-title">Resident Info Console</h1>
                        </Space>
                    </div>
                </Flex>
                <SearchForm item={ricStore.FilterList} selectedAccount={ricStore.SelectedAccount} selectCallBack={(item) => { ricStore.setAccount(item) }} resetCallBack={() => { ricStore.setAccount(undefined) }} />
                {ricStore.IsShowRicInfo === true ? <div>
                    <Row>
                        <Col span={24}>
                            <AccountInfoBlock />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <TableAccountInfo />
                        </Col>
                    </Row>
                </div> : <></>}
            </Spin>
        </ConfigProvider>
    </div>
})

export default RIC