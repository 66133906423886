import React from "react";
import GetForms from "./GetForms";
const Form = ({ Edit,isSupportedDocument, components, unitId, formRef, GetForm, id ,setShowFileViewer,setIsSuccess,setFilePath,setFileName,setEditFormOpen,showPaymentSection,setShowPaymentSection,editFormOpen}) => {
 return (
    <>
      <GetForms
        forms={{
          display: "form",
          components: components,
        }}
        editFormOpen={editFormOpen}
        setEditFormOpen={setEditFormOpen}
        Edit={Edit}
        GetForm={GetForm}
        unitId={unitId}
        ref={formRef}
        setShowFileViewer={setShowFileViewer}
        onSubmit={(submission) => {
        }}
        showPaymentSection={showPaymentSection}
        setShowPaymentSection={setShowPaymentSection}
        setFilePath={setFilePath}
        setFileName={setFileName}
        setIsSuccess={setIsSuccess}
        isSupportedDocument={isSupportedDocument}
      />
    </>
  );
};

export default Form;
