import React, { useEffect, useState, useMemo } from "react";
import { DeleteOutlined, DownloadOutlined, EyeOutlined, InboxOutlined } from "@ant-design/icons";
import { message, Upload, Table, Popover, Select, Typography } from "antd";
import components from "../../components";
import { useStore } from "../../../src/root-store-context";
import { CloudUploadOutlined } from '@ant-design/icons';
import CommonFuncitons from "../../utils/CommonFunciton";
import { ReactComponent as ViewIcon } from "../../../src/svg/ViewIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../src/svg/DownloadIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../src/svg/DeleteIcon.svg";

const { Button, Spinner } = components;
const { Dragger } = Upload;

const App = ({ Edit, appealDoc, handleDownload, handleViewFile, getArchFormResponse, isSubmit, fileList, setFileList }) => {
    const { apiStore } = useStore();
    const [allDocumentTypes, setAllDocumentTypes] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [documentTypes, setDocumentTypes] = useState(null);

    useEffect(() => {
        const fetchDocumentTypes = async () => {
            if (
                (!documentTypes ||
                    documentTypes?.length === 0) && getArchFormResponse?.hoaId
            ) {
                try {
                    const res = await apiStore.FormsApiClient.GetDocumentType(getArchFormResponse?.hoaId);

                    if (res?.data) {
                        // Handle the response data, for example:
                        setDocumentTypes(res.data); // Replace `setDocumentTypes` with your state update logic
                    }
                } catch (error) {
                    console.error("Error fetching document types:", error);
                }
            }
        };

        fetchDocumentTypes();
    }, [getArchFormResponse?.hoaId]);

    const uploadFile = async (file) => {
        setIsUploading(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("Name", file?.name);
        formData.append("dir", null);
        formData.append("UserId", getArchFormResponse?.userId);

        try {
            const response = await fetch(`${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/add`, {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                if (result.status) {
                    message.success(`${file.name} file uploaded successfully.`);
                    setIsUploading(false);
                    return { status: "done", response: result.data };
                } else {
                    message.error(`${file.name} file upload failed.`);
                    setIsUploading(false);
                    return { status: "error" };
                }

            } else {
                message.error(`${file.name} file upload failed.`);
                setIsUploading(false);
                return { status: "error" };
            }
        } catch (error) {
            console.error("Upload error:", error);
            message.error(`${file.name} file upload failed.`);
            setIsUploading(false);
            return { status: "error" };
        }
    };

    useEffect(() => {
        if (documentTypes?.length > 0) {
            const docTypesList = documentTypes
                .filter((docType) => docType?.status === 1)
                .map((docType) => ({
                    name: docType?.docTypeName,
                    value: docType?.id,
                }))
                .sort((a, b) => a?.name?.localeCompare(b?.name)); // Sort alphabetically by name
            setAllDocumentTypes(docTypesList);
        }
    }, [documentTypes]);

    const props = {
        name: "file",
        multiple: true,
        showUploadList: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            const uploadResult = await uploadFile(file);

            if (uploadResult.status === "done") {
                setFileList((prevList) => [
                    ...prevList,
                    {
                        name: uploadResult.response.originalFileName || file.name,
                        fileId: uploadResult.response.fileId, // Store file ID from API response
                    },
                ]);
                onSuccess(uploadResult.response);
            } else {
                onError(uploadResult);
            }
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };

    const handleDelete = async (index) => {
        const fileToDelete = fileList[index];

        const queryParams = new URLSearchParams({
            fileId: fileToDelete.fileId, // Use file ID returned by the add API
            submissionId: "",
            userId: getArchFormResponse?.userId,
        });

        try {
            const response = await fetch(
                `${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/delete?${queryParams.toString()}`,
                {
                    method: "DELETE",
                }
            );

            if (response.ok) {
                setFileList((prevList) =>
                    prevList.filter((_, idx) => idx !== index)
                );
                message.success("File deleted successfully.");
            } else {
                message.error("Failed to delete the file.");
            }
        } catch (error) {
            console.error("Delete error:", error);
            message.error("An error occurred while deleting the file.");
        }
    };

    const handleDocumentTypeChange = (value, index) => {
        // Update the document type for the specific file in the fileList
        const filteredValues = allDocumentTypes?.find(item => item.value === value);
        setFileList((prevList) =>
            prevList.map((file, idx) =>
                idx === index ? { ...file, docTypeId: value, documentType: filteredValues?.name } : file
            )
        );
    };

    const onSearch = (value) => {
        console.log("search:", value);
    };

    const columns = useMemo(() => {
        return [
            {
                title: "File Name",
                dataIndex: "name",
                key: "name",
                render: (value, record) => {

                    const fileExtension = record?.name?.split('.').pop()?.toLowerCase();
                    const isViewable = CommonFuncitons?.viewableExtensions.includes(fileExtension);
                    const fileIcon = CommonFuncitons.getFileIcon(
                        record?.name
                    );
                    return (
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '20px' }}> <img
                                style={{ width: '100%' }}
                                src={fileIcon}
                                onClick={() => { isViewable ? handleViewFile(record?.fileId) : handleDownload(record) }}
                            /></div>{record?.name}
                        </div>
                    )
                }
            },
            !appealDoc ? {
                title: "Document Type",
                key: "documentType",
                render: (_, record, index) => (
                    <>
                        {Edit == 'view' ? <Typography>{record?.documentType}</Typography> : <>
                            <Select
                                onSearch={onSearch}
                                className="user-form-input"
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select Document Type"
                                value={record.docTypeId} // This binds the selected value
                                options={allDocumentTypes.map((docType) => ({
                                    label: docType.name,
                                    value: docType.value,
                                }))} // Ensure options are correctly formatted
                                onChange={(value) => handleDocumentTypeChange(value, index)} // Pass value and index
                            // disabled={Edit === "view"} // Disable if in "view" mode
                            />
                            {isSubmit && !record.docTypeId && (
                                <div style={{ color: "red", fontSize: "12px" }}>
                                    Please select a document type.
                                </div>
                            )}
                        </>}
                        {isSubmit && !record.docTypeId && (
                            <div style={{ color: "red", fontSize: "12px" }}>
                                Please select a document type.
                            </div>
                        )}
                    </>
                ),
            } : '',
            {

                title: "Action",
                key: "action",
                render: (value, record, index) => ActionRender(record, index)
            },
        ].filter(Boolean); // Remove null columns (e.g., when in "view" mode)
    }, [allDocumentTypes, fileList]);
    const ActionRender = (record, index) => {
        console.log(record, "recordrecordrecordrecord")
        const fileExtension = record?.name?.split('.').pop()?.toLowerCase();
        const isViewable = CommonFuncitons?.viewableExtensions.includes(fileExtension);
        return (<>
            <div style={{display:'flex',gap:'4px'}}>
                {Edit == "view" && <span style={{display:'flex',gap:'4px'}}

                >
                    {isViewable && (
                        <Button className="icon-wrap p-0" icon={<ViewIcon />} onClick={() => handleViewFile(record?.fileId)} />

                    )}
                    <Button className="icon-wrap p-0" icon={<DownloadIcon />} onClick={() => handleDownload(record)} />
                </span>}
                {Edit != "view" && <Popover placement="topLeft" title={"Delete"}>
                    <Button
                        onClick={() => handleDelete(index)}
                        className="border-0 upload-field-delete-btn"
                        shape={"circle"}
                        icon={<DeleteIcon />}
                    />
                </Popover>}
            </div>
        </>)
    }
    return (
        <>
            {isUploading && <Spinner />}
            <div>
                {Edit !== "view" && <>  <div className="d-flex justify-content-between custom-additional-upload-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p className="additional-upload-header-name extra-width">File Name</p>

                        <p className="additional-upload-header-name small-width">Type</p>
                    </div>
                </div>
                    <Dragger {...props}>
                        <div className="additional-upload-content-wrap">
                            <p className="ant-upload-drag-icon">
                                <CloudUploadOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Drop files to attach, or <u>browse</u>
                            </p>
                        </div>
                        <p className="ant-upload-hint d-none">
                            Support for a single or bulk upload. Strictly prohibited from
                            uploading company data or other banned files.
                        </p>
                    </Dragger> </>}

                {fileList?.length > 0 ? (
                    <Table
                        dataSource={fileList?.map((file, index) => ({
                            key: index,
                            name: file.name,
                            size: file.size,
                            status: file.status,
                            documentType: file.docTypeName,
                            docTypeId: file.docTypeId,
                            fileId: file?.fileId
                        }))}
                        columns={columns}
                        pagination={false}
                        style={{ marginTop: 20 }}
                    />
                ) : "No Files"}
            </div>
        </>

    );
};

export default App;
