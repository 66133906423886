import React, { useState, useEffect } from "react";
import { Button, Upload, message } from "antd";
import Swal from "sweetalert2";
import { PaperClipOutlined, DeleteOutlined } from "@ant-design/icons";

const FileUploader = ({
  fileList,
  handlePreview,
  SubmissionInfo,
  setFileList,
  fileData,
  setFileData,setDocumentLoading,setDocumentLoadingStates
}) => {
  const [loading, setLoading] = useState(false); 
  const allowedExtensions = [
    "jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp", 
    "mp4", "avi", "mov", "mkv", "flv", "wmv", "webm", 
    "zip", "txt", "pdf", "csv", "docx","doc", "xlsx", "pptx", "rtf", "xml", 
  ];

  const validateFileType = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  const handleUpload = async (file) => {
    console.log(file,"filefilefilefilefile")
    setLoading(true);
    setDocumentLoadingStates((prevStates) => ({
      ...prevStates,
      [file.uid]: true, 
    }));
    setDocumentLoading(true) 
    if (!validateFileType(file)) {
      message.error({
        content: `File type not allowed: ${file.name}`,
        style: { textAlign: "right" }
      });
      setLoading(false);
      setDocumentLoading(false) 
      setDocumentLoadingStates((prevStates) => ({
        ...prevStates,
        [file.uid]: false, 
      }));
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("Name", file?.name);
    formData.append("dir", null);
    formData.append("UserId", SubmissionInfo?.userId);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/add`,
        {
          method: "POST",
          body: formData,
        }
      );

      const resultData = await response.json();
      if (response.ok && resultData.status) {
        const newFileId = resultData.data.fileId;

        setFileList((prevFileIds) => [...prevFileIds, newFileId]);
        setFileData((prevFileList) =>
          prevFileList.map((item) =>
            item.uid === file.uid
              ? { ...item, uid: newFileId, status: "done" }
              : item
          )
        );

        // message.success({
        //   content: `${file.name} uploaded successfully.`,
        //   style: { textAlign: "right" },
        // });
      } else {
        Swal.fire({
          title: "Error",
          text: resultData.message || "Unable to upload the file.",
          icon: "error",
          confirmButtonColor: "#44637e",
        });
      }
    } catch (error) {
      console.error("Upload error:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while uploading the file.",
        icon: "error",
        confirmButtonColor: "#44637e",
      });
      message.error({
        content: `${file.name} upload failed.`,
        style: { textAlign: "right" },
      });
    } finally {
      setLoading(false); 
      setDocumentLoading(false) 
      setDocumentLoadingStates((prevStates) => ({
        ...prevStates,
        [file.uid]: false, 
      }));
    }
  };

  const customRequest = ({ file, onSuccess }) => {
  
    if (!validateFileType(file)) {
      message.error({
        content: `File type not allowed: ${file.name}`,
        style: { textAlign: "right" },
      });
      return;
    }

    setFileData((prevFileList) => [
      ...prevFileList,
      {
        uid: file.uid,
        name: file.name || "Untitled",
        status: "uploading",
        originFileObj: file,
      },
    ]);

    handleUpload(file).then(() => onSuccess("ok"));
  };

  useEffect(() => {
    if (fileList?.length === 0) {
      setFileData([]);
    }
  }, [fileList]);

  return (
    <div>
      <Upload
        customRequest={customRequest}
        listType="picture"
        showUploadList={false} 
        multiple
      >
        <Button
          className="icon-wrap additional-icon"
          loading={loading} 
          style={{ marginTop: "16px" }}
        >
         {!loading && <svg
            width="22"
            height="23"
            viewBox="0 0 22 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9075 6.91665L7.87032 12.9538C7.69521 13.1229 7.55555 13.3252 7.45946 13.5489C7.36338 13.7726 7.3128 14.0132 7.31069 14.2566C7.30857 14.5 7.35496 14.7414 7.44714 14.9667C7.53932 15.192 7.67545 15.3967 7.84759 15.5689C8.01973 15.741 8.22442 15.8771 8.44973 15.9693C8.67504 16.0615 8.91646 16.1079 9.15988 16.1058C9.40331 16.1037 9.64388 16.0531 9.86756 15.957C10.0912 15.8609 10.2935 15.7213 10.4626 15.5462L16.3421 9.50899C17.0101 8.81745 17.3796 7.89124 17.3713 6.92985C17.3629 5.96846 16.9773 5.04882 16.2975 4.36899C15.6177 3.68916 14.698 3.30354 13.7366 3.29518C12.7752 3.28683 11.849 3.65641 11.1575 4.32432L5.27706 10.3606C4.24553 11.3921 3.66602 12.7912 3.66602 14.25C3.66602 15.7088 4.24553 17.1079 5.27706 18.1394C6.3086 19.1709 7.70767 19.7505 9.16648 19.7505C10.6253 19.7505 12.0244 19.1709 13.0559 18.1394L18.7915 12.4167"
              stroke="#606060"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>}
        </Button>
      </Upload>
    </div>
  );
};

export default FileUploader;
