import { Table } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useCallback, useEffect, useState } from "react";
import DraggableBox, { DraggableBoxTag } from "./DraggableBox";
import useBreakpoint from "use-breakpoint";
import { ColumnsType } from "antd/es/table";

export type FileItem = {
    key?: string,
    isFolder: boolean,
    serverRelativePath?: string,
}

export type FileExplorerColumns<T>= {
    large: ColumnsType<T>;
    small: ColumnsType<T>;
}

export interface FileExplorerProps {
    data: (any & FileItem)[];
    isLoading: boolean;
    onRowFunc: any; readOnly?: boolean;
    columns: FileExplorerColumns<any & FileItem>;
    onLoadRowFiles?: (files: File[], serverRelativePath: string) => void;
    onLoadFiles?: (files: File[]) => void;
}

const FileExplorerList = (props:FileExplorerProps ) => {
    // const isValidData = props.data != null && props.data.length > 0;
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setCurrentPage(1);
    }, [props?.data])


    // const textStyleNoSelect: React.CSSProperties | undefined = {
    //     WebkitUserSelect: "none",
    //     MozUserSelect: "none",
    //     msUserSelect: "none",
    //     userSelect: "none"
    // }


    const DraggableRowBody: React.FC<{ children: React.ReactNode }> = useCallback(({ children, ...rowProps }) => {
        const rowKey = (rowProps as any)["data-row-key"];
        let disabled: boolean = true;
        let serverRelativePath: string = "";
        if (rowKey !== undefined) {
            const data = JSON.parse(rowKey)
            disabled = !data.isFolder;
            serverRelativePath = data.serverRelativePath;
        }
        return <DraggableBox tag={DraggableBoxTag.tr} disable={disabled} onLoadFiles={(files) => { props.onLoadRowFiles && props.onLoadRowFiles(files, serverRelativePath) }} {...rowProps}>{children}</DraggableBox>
    }, []);

    const components = {
        body: {
            row: props.readOnly || !props.onLoadRowFiles ? undefined : DraggableRowBody
        },
    };

    const rowKey = (item: FileItem): string => {
        return JSON.stringify(
            {
                key: item.key,
                isFolder: item.isFolder,
                serverRelativePath: item.serverRelativePath,
            });
    }

    const data:FileItem[] = props.data.map((d,i)=>({...d,key:i}));
    return <>
        <DndProvider backend={HTML5Backend}>
            <DraggableBox tag={DraggableBoxTag.div} disable={props.readOnly || !props.onLoadFiles} onLoadFiles={(files) => { props.onLoadFiles && props.onLoadFiles(files) }}>
                <Table className="table-wrapper" dataSource={data} loading={props.isLoading}
                    scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                    pagination={{ defaultPageSize: 10, showSizeChanger: false, responsive: true, current: currentPage, onChange: (pageNum) => setCurrentPage(pageNum) }}
                    onRow={props.onRowFunc} components={components} rowKey={i => rowKey(i)}
                    size={breakpoint === "mobile" ? "small" : "large"} columns={breakpoint === "mobile" ? props.columns.small : props.columns.large}
                >
                </Table>
            </DraggableBox>
        </DndProvider>
    </>
}


export default FileExplorerList