import React, { useEffect, useState, useMemo } from "react";
import { message, Upload, Table, Popover, Select, Typography } from "antd";
import { useStore } from "../../root-store-context";
import { CloudUploadOutlined, DeleteOutlined, DownloadOutlined, FundViewOutlined } from '@ant-design/icons';
import CommonFuncitons from '../../utils/CommonFunciton';
import Button from '../Button/Button';
import Spinner from "../Spinner/Spiner";
import { observer } from "mobx-react-lite";
import { ReactComponent as ViewIcon } from "../../svg/ViewIcon.svg";
import { ReactComponent as DownloadIcon } from "../../svg/DownloadIcon.svg";

const { Dragger } = Upload;
const UploadButton= observer(() => {
    const location = window.location;
    console.log(location?.pathname,'sdfljsdlfjlsdjflkjsdlkfjlsdjflksd')
const {apiStore,applicationStore} = useStore();
console.log(applicationStore.submissionDetails,"applicationStore?.submissionDetails",applicationStore.submissionDetails?.isSupportedDocRequired)
    const [isGetAllDocumentType,setIsGetAllDocumentType] = useState(false);
    const [allDocumentTypes, setAllDocumentTypes] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [documentTypes, setDocumentTypes] = useState(null);
    const uploadFile = async (file) => {
        if (!applicationStore.submissionDetails?.userId) {
            message.error('User ID is required for upload');
            return { status: "error" };
        }

        setIsUploading(true);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("Name", file?.name);
        formData.append("dir", null);
        formData.append("UserId", applicationStore.submissionDetails?.userId);

        try {
            const response = await fetch(`${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/add`, {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                if (result.status) {
                    message.success({ content: `${file.name} file uploaded successfully.`, style: { textAlign: 'right' } });
                    
                    // Safely update fileList
                    const currentFileList = applicationStore.fileList || [];
                    applicationStore.setFileList([
                        ...currentFileList,
                        {
                            name: result.data?.originalFileName || file?.name,
                            fileId: result.data?.fileId,
                        }
                    ]);
               
                    setIsUploading(false);
                    return { status: "done", response: result.data };
                }
            }
            
            message.error({ content: `${file.name} file upload failed.`, style: { textAlign: 'right' } });
            setIsUploading(false);
            return { status: "error" };
            
        } catch (error) {
            console.error("Upload error:", error);
            message.error({ content: `${file.name} file upload failed.`, style: { textAlign: 'right' } });
            setIsUploading(false);
            return { status: "error" };
        }}

    useEffect(() => {
        if (documentTypes?.length > 0) {
            const docTypesList = documentTypes
                .filter((docType) => docType?.status === 1)
                .map((docType) => ({
                    name: docType?.docTypeName,
                    value: docType?.id,
                }))
                .sort((a, b) => a?.name?.localeCompare(b?.name)); // Sort alphabetically by name
            setAllDocumentTypes(docTypesList);
        }
     
    }, [documentTypes]);

    const props = {
        name: "file",
        multiple: true,
        showUploadList: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            const uploadResult = await uploadFile(file);

            if (uploadResult.status === "done") {
                // if (applicationStore.submissionDetails?.hoaId && !isGetAllDocumentType) {
                //     let req = {
                //         hoaId: applicationStore.submissionDetails?.hoaId,
                //     };
                //     setIsGetAllDocumentType(true)
                //     dispatch(DocumentTypeAction.GetDocumentTypeByHoaIdRequest(req));
                // }
                // if (
                //     (!documentTypes ||
                //         documentTypes?.length === 0) && applicationStore?.submissionDetails?.hoaId
                // ) {
                //     try {
                //         // const res = await apiStore.FormsApiClient.GetDocumentType(applicationStore?.submissionDetails?.hoaId);
    
                //         // if (res?.data) {
                //         //     // Handle the response data, for example:
                //         //     setDocumentTypes(res.data); // Replace `setDocumentTypes` with your state update logic
                //         // }
                //     } catch (error) {
                //         console.error("Error fetching document types:", error);
                //     }
                // }
                if (
                    (!documentTypes ||
                        documentTypes?.length === 0) && applicationStore.submissionDetails?.hoaId
                ) {
                    try {
                        const res = await apiStore.FormsApiClient.GetDocumentType(applicationStore.submissionDetails?.hoaId);
    
                        if (res?.data) {
                            // Handle the response data, for example:
                            setDocumentTypes(res.data); // Replace `setDocumentTypes` with your state update logic
                        }
                    } catch (error) {
                        console.error("Error fetching document types:", error);
                    }
                }
                onSuccess(uploadResult.response);
            } else {
                onError(uploadResult);
            }
        },
        onDrop(e) {
            console.log("Dropped files", e?.dataTransfer?.files);
        },
    };
console.log(applicationStore.fileList,"applicationStore.fileList")
const handleDelete = async (index) => {
    const fileToDelete = applicationStore?.fileList[index];
    console.log(applicationStore.fileList, "Before Deletion: applicationStore.fileList");

    const queryParams = new URLSearchParams({
        fileId: fileToDelete.fileId,
        submissionId: "",
        userId: applicationStore.submissionDetails?.userId,
    });

    try {
        const response = await fetch(
            `${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/delete?${queryParams.toString()}`,
            {
                method: "DELETE",
            }
        );

        if (response.ok) {
            let filterData = applicationStore.fileList?.filter((data) => data?.fileId !== fileToDelete.fileId);
            applicationStore.setFileList([...filterData]);
            setTimeout(() => {
                applicationStore.setFileList([...filterData]);
            }, 0);

            console.log(applicationStore.fileList, "After Deletion: applicationStore.fileList");
            message.success({content:"File deleted successfully.",style:{textAlign:"right"}});
        } else {
            message.error({content:"Failed to delete the file.",style:{textAlign:'right'}});
        }
    } catch (error) {
        console.error("Delete error:", error);
        message.error({content:"An error occurred while deleting the file.",style:{textAlign:'right'}});
    }
};


    const handleDocumentTypeChange = (value, index) => {
        const filteredValues = allDocumentTypes?.find(item => item.value === value);
        const currentFileList = [...applicationStore.fileList];
        currentFileList[index] = {
            ...currentFileList[index],
            docTypeId: value,
            documentType: filteredValues?.name
        };
        
        applicationStore.setFileList(currentFileList);
    };
    const onSearch = (value) => {
        console.log("search:", value);
    };
     const handleViewFile = (fileId) => {
        setTimeout(() => {
          window.open(
            `${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/view/?fileId=${fileId?.trim()}`,
            "_blank"
          );
        }, 100);
      };
  const handleDownload = (data) => {
    const link = document.createElement("a");
    link.href = `${process.env.REACT_APP_ARCH_API_ENDPOINT.toString()}api/Media/download?fileId=${data?.fileId}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

    const columns = useMemo(() => {
        return [
            {
                title: "File Name",
                dataIndex: "name",
                key: "name",
                render: (value, record) => {
                    const fileExtension = record?.name?.split('.').pop()?.toLowerCase();
                    const isViewable = CommonFuncitons?.viewableExtensions.includes(fileExtension);
                    const fileIcon = CommonFuncitons?.getFileIcon(record?.name);
                    return (
                        <>
                            <div className="d-flex align-items-center gap-1 ps-1">
                                <div style={{ width: '20px' }}> <img
                                    style={{ width: '100%' }}
                                    src={fileIcon}
                                    onClick={() => { isViewable ? handleViewFile(record?.fileId) : handleDownload(record) }}
                                />
                                </div>
                                <span>{record?.name}</span>
                            </div>
                        </>)
                }
            },
            {
                title: "Document Type",
                key: "documentType",
                render: (_, record, index) => (
                    <>
                        {location.pathname.includes("/AccountInfo/ArchitecturalRequestsNew/View/") ? <Typography>{record?.documentType}</Typography> : <>
                            <Select
                                onSearch={onSearch}
                                className="user-form-input"
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select Document Type"
                                value={record.docTypeId} // This binds the selected value
                                options={allDocumentTypes.map((docType) => ({
                                    label: docType.name,
                                    value: docType.value,
                                }))} // Ensure options are correctly formatted
                                onChange={(value) => handleDocumentTypeChange(value, index)} // Pass value and index
                                disabled={location.pathname.includes("/AccountInfo/ArchitecturalRequestsNew/View/")} // Disable if in "view" mode
                            />
                            { !record.docTypeId && (
                                <div style={{ color: "red", fontSize: "12px" }}>
                                    Please select a document type.
                                </div>
                            )}
                        </>}

                    </>
                ),
            },
            {
                title: "Action",
                key: "action",
                render: (value, record, index) => ActionRender(record, index)
            },
        ].filter(Boolean); // Remove null columns (e.g., when in "view" mode)
    }, [allDocumentTypes, applicationStore.fileList]);

    const ActionRender = (record, index) => {
        const fileExtension = record?.name?.split('.').pop()?.toLowerCase();
        const isViewable = CommonFuncitons?.viewableExtensions.includes(fileExtension);
        return (<>
            {location.pathname.includes("/AccountInfo/ArchitecturalRequestsNew/View/") && <span>
                <div className="d-flex">
                    {isViewable && (
                        <Button
                            onClick={() => handleViewFile(record?.fileId)}
                            className="border-0 document-button"
                            shape={"circle"}
                            icon={<ViewIcon/>}
                        ></Button>
                    )}
                    <Button
                        onClick={() => handleDownload(record)}
                        className="border-0 document-button ms-3"
                        shape={"circle"}
                        icon={<DownloadIcon/>}
                    ></Button>
                </div>
            </span>}
            {!location.pathname.includes("/AccountInfo/ArchitecturalRequestsNew/View/") && <Popover placement="topLeft" title={"Delete"}>
                <Button
                    onClick={() => handleDelete(index)}
                    className="border-0 upload-field-delete-btn"
                    shape={"circle"}
                    icon={<DeleteOutlined />}
                />
            </Popover>}
        </>)
    }
    const beforeUpload = (file) => {
        const isValidType = [
          ...CommonFuncitons?.allowedTypes.image,
          ...CommonFuncitons?.allowedTypes.video,
          ...CommonFuncitons?.allowedTypes.document
        ].includes(file.type);
      
        if (!isValidType) {
          message.error({content:`Invalid file type: ${file.name}`,style:{textAlign:'right'}});
          return Upload.LIST_IGNORE;
        }
      
        return true;
      };
      
    return (
        <>
     
            {isUploading && <Spinner />}

           { ((location.pathname.includes("/AccountInfo/ArchitecturalRequestsNew/View/") && applicationStore.documentRequired) || (location.pathname.includes("/AccountInfo/ArchitecturalRequestsNew/Add") && applicationStore.submissionDetails?.isSupportedDocRequired)) && <div>
            <Typography>Supported Documents</Typography>
                {!location.pathname.includes("/AccountInfo/ArchitecturalRequestsNew/View/") && <>
                
                 <div className="d-flex justify-content-between custom-additional-upload-header">
                    <p className="additional-upload-header-name extra-width">File Name</p>
                    <p className="additional-upload-header-name small-width">Size</p>
                    <p className="additional-upload-header-name small-width">Type</p>
                </div>

                    <Dragger beforeUpload={beforeUpload} {...props}>
                        <div className="additional-upload-content-wrap">
                            <p className="ant-upload-drag-icon">
                                <CloudUploadOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Drop files to attach, or <span><u>browse</u></span>
                            </p>
                        </div>
                        <p className="ant-upload-hint d-none">
                            Support for a single or bulk upload. Strictly prohibited from
                            uploading company data or other banned files.
                        </p>
                    </Dragger></>
                }
                
                {applicationStore.fileList?.length > 0 ? (
                    <Table
                        dataSource={applicationStore.fileList?.map((file, index) => ({
                            key: index,
                            name: file?.originalFileName ?? file.name,
                            size: file.size,
                            status: file.status,
                            documentType: file.docTypeName,
                            docTypeId: file.docTypeId,
                            fileId: file?.fileId
                        }))}
                        columns={columns}
                        pagination={false}
                        style={{ marginTop: 20 }}
                        className="custom-upload-table-wrap"
                    />
                ) : location?.pathname != "/AccountInfo/ArchitecturalRequestsNew/Add" ? "No Files" : ""}
            </div>}
        </>
    );
});

export default UploadButton;