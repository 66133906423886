import moment from "moment";

const cardNumberValidator = (_, value) => {
  // Remove non-digit characters
  const cleanValue = value?.replace(/\D/g, "");

  // Check if the card number is exactly 16 digits
  if (cleanValue != undefined && cleanValue?.trim()?.length != 0 && cleanValue?.length !== 16) {
    return Promise.reject(new Error("Card number must be 16 digits"));
  }

  return Promise.resolve();
};

class Rules {
  static rules = {
    cardHolderName: [
      {
        required: true,
        message: "Cardholder name is required",
      },
      {
        pattern: /^[a-zA-Z\s]*$/,
        message: "Cardholder name should only contain letters and spaces",
      },
    ],
    cardNumber: [
      {
        required: true,
        message: "Card number is required",
      },
      {
        validator: cardNumberValidator,
      },
    ],
    cvv: [
      {
        required: true,
        message: "Input your CVV",
      },
      {
        pattern: /^\d{3,4}$/,
        message: "CVV must be 3 or 4 digits",
      },
    ],
    cardExpiry: [
      {
        required: true,
        message: "Input your expiration date",
      },
      {
        validator: (_, value) => {
          const [month, year] = value ? value.split("/") : ["", ""];
          const currentYear = moment().year() % 100;
          const expirationMonth = parseInt(month, 10);
          const expirationYear = parseInt(year, 10);
          if (
            value != undefined && value?.trim()?.length != 0 &&
            (value?.trim()?.length !== 5 ||
              isNaN(expirationMonth) ||
              expirationMonth < 1 ||
              expirationMonth > 12 ||
              isNaN(expirationYear) ||
              expirationYear < currentYear ||
              (expirationYear === currentYear &&
                expirationMonth < moment().month() + 1))
          ) {
            return Promise.reject(
              new Error("Invalid expiration date or date in the past"),
            );
          }
          return Promise.resolve();
        },
      },
    ],
    reason: [
      { required: true, message: "" },
      {
        validator: (_, value) =>
          value && value.trim().length > 0
            ? Promise.resolve()
            : Promise.reject(new Error("Please input reason" )),
      },
    ],
    dayTimePhone: [
      {
        validator: (_, value) => {
          if (!value || value.replace(/\D/g, "").length === 10) {
            return Promise.resolve();
          }
          return Promise.reject(new Error("Phone number must be exactly 10 digits."));
        },
      },
    ],

  };
}
export default Rules;
