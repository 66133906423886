import React, { useState } from 'react';
import { App, Button, Col, DatePicker, Divider, Empty, Form, Input, Row, Select, Space, UploadFile, UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useStore } from '../../../root-store-context';
import TextArea from 'antd/es/input/TextArea';
import { observer } from 'mobx-react-lite';
import FileHelper from '../../../helpers/FileHelper';
import CustomUploadItem from './CustomUploadItem';
import { useNavigate } from 'react-router-dom';
import Dragger from 'antd/es/upload/Dragger';
import { validateMessages } from '../../../helpers/validationMessages';
import { LocalTask } from '../../../api/BoardVue';
import { Attachment } from '../../../api/Common/CommonTypes';
import moment from 'moment';

const TaskForm = observer((props: { submitBtnText: string, submit: () => void }) => {

    const { apiStore } = useStore();

    const [form] = Form.useForm();
    const { boardTaskStore: boardTaskStore } = useStore();
    const { applicationStore, modalViewerStore } = useStore();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const values = Form.useWatch([], form);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const noteHistoryContent = boardTaskStore?.taskModel?.noteHistory;
    const lastModified = boardTaskStore?.taskModel?.modified;
    const created = boardTaskStore?.taskModel?.created;
    const author = boardTaskStore?.taskModel?.author;
    const editor = boardTaskStore?.taskModel?.editor;

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                boardTaskStore.setSubmittableForm(true);
            },
            () => {
                boardTaskStore.setSubmittableForm(false);
            },
        );
    }, [values]);

    React.useEffect(() => {
        if (boardTaskStore.shouldResetForm) {
            form.resetFields();
            boardTaskStore.shouldResetForm = false;
        }

    }, [boardTaskStore.taskModel]);


    React.useEffect(() => {
        if (boardTaskStore.attachments !== null && boardTaskStore.attachments !== undefined && boardTaskStore.shouldInitAttachments) {
            var updatedFileList = boardTaskStore.attachments.map((attach) => {
                return {
                    name: attach.Name,
                    uid: attach.Url,
                    status: 'done',
                } as UploadFile;
            });
            setFileList(updatedFileList);
            boardTaskStore.shouldInitAttachments = false;
        }
    }, [boardTaskStore.attachments]);


    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );



    const uploadProps: UploadProps = {
        onRemove: (file) => {
            if (file.status === "done") {
                return false;
            }

            const updatedFileList = fileList.filter(
                (attachment) => attachment.uid !== file.uid
            );
            setFileList(updatedFileList);
            const updatedAttachments = boardTaskStore.attachments.filter(
                (attachment) => attachment.Guid !== file.uid
            );
            boardTaskStore.attachments = updatedAttachments;
        },

        onDownload: (file) => {
            if (file.status === "done") {
                boardTaskStore.isLoading = true;
                apiStore.BoardVueApiClient.getFile(file.uid)
                    .then(res => {
                        modalViewerStore.openFileByData(res.data, file.name);
                        boardTaskStore.isLoading = false;
                    })
                    .catch(error => {
                        boardTaskStore.isLoading = false;
                        console.log(error)
                    });
            }
        },
        beforeUpload: (file, allFiles) => {
            if (allFiles.length + fileList.length > 10) {
                if (allFiles[0].uid === file.uid) {
                    message.error('You can attach not more than 10 files.');
                }
                return false;
            }
            var isExist = fileList.some(i => i.name === file.name);
            if (isExist) {
                message.error('File with the same name already exists.');
                return false;
            }
            if (allFiles.some((i: File) => i.size > 10 * 1024 * 1024)) {
                if (file.size > 10 * 1024 * 1024) {
                    message.error('Unacceptable file size. File upload are limited to 10MB.');
                }
                return false;
            }
            setFileList((prev) => [...prev, file]);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = e => {
                const arrayBuffer = e.target?.result as ArrayBuffer;
                const byteArray = new Uint8Array(arrayBuffer);
                const newAttachment: Attachment = {
                    Name: file.name,
                    Data: FileHelper.ArrayBufferToBase64(Array.from(byteArray)),
                    Guid: file.uid,
                    Url: ""
                };
                boardTaskStore.attachments = [...boardTaskStore.attachments, newAttachment];
            };
            return false;
        },
        showUploadList: {
            showDownloadIcon: false,
            showPreviewIcon: false,
            showRemoveIcon: false,
        },
    };

    const decodeHtmlEntities = (str: string) => {
        const element = document.createElement('div');
        element.innerHTML = str;
        return element.textContent || element.innerText;
    };

    const splitNotes = (notes: string) => {
        // Decode HTML entities
        const decodedNotes = decodeHtmlEntities(notes);

        // Use regex to split notes based on the timestamp pattern
        const notePattern = /(\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}&#58;\d{1,2}&#58;\d{1,2} [APM]{2})/g;
        const notesArray = decodedNotes.split(notePattern).filter(Boolean);

        // Format the notes
        const formattedNotes = [];
        for (let i = 0; i < notesArray.length; i++) {
            if (i % 2 === 0) {
                // Timestamp part
                formattedNotes.push(<strong key={i}>{notesArray[i]}</strong>);
            } else {
                // Note content part
                formattedNotes.push(<div key={i}>{notesArray[i]}</div>);
            }
        }

        return formattedNotes;
    };

    return (
        <Form
            form={form}
            variant='outlined'
            layout="vertical"
            initialValues={{...boardTaskStore.taskModel, internalTaskStatus:boardTaskStore.taskModel.internalTaskStatus??"Not Started"}}
            labelCol={{ span: 24 }}
            style={{ maxWidth: 900 }}
            onValuesChange={(changedValues, allValues) => applicationStore.onTaskModelChange(allValues, boardTaskStore)}
            validateMessages={validateMessages}
            requiredMark={customizeRequiredMark}
            autoComplete="off"
            onFinish={props.submit}
            scrollToFirstError={{ block: 'center', inline: 'nearest' }}>

            <Row>
                <Col span={24}>
                    <Form.Item<LocalTask> name="id" hidden >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item<LocalTask> label="Title" name="title" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col md={{ span: 7, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<LocalTask>
                        label="Start Date"
                        name="startDate"
                        rules={[{ required: true }]}>
                        <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                    </Form.Item>
                </Col>

                <Col md={{ span: 7, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<LocalTask>
                        label="Due Date"
                        name="dueDate"
                        dependencies={["startDate"]}
                        rules={[{ required: true }, ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value && getFieldValue('startDate') && value.startOf('day').diff(getFieldValue('startDate').startOf('day')) >= 0) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The Due Date must be greater than the Start Date'));
                            },
                        }),]}>
                        <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                    </Form.Item>
                </Col>

                <Col md={{ span: 8, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<LocalTask> label="Recurrence" name="reoccurrence" rules={[{ required: true }]}>
                        <Select options={applicationStore.reoccurrences?.map((text) => ({ value: text, label: text }))} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
            </Row>

            {/* <Row>
                <Col md={{ span: 15, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<LocalTask> label="Assigned To" name="assignedTo" rules={[{ required: true }]}>
                        <Select
                            options={(() => {
                                const selectedItems = form.getFieldValue("assignedTo");
                                if (searchText.length >= 3) {
                                    const res = applicationStore.users?.filter((item) => {
                                        return item.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) || selectedItems === item.id
                                    }).map((user) => ({ value: user.id, label: user.title }));
                                    return res
                                }
                                return applicationStore.users?.filter((item) => {
                                    return selectedItems?.toString() === item.id?.toString()
                                }).map((user) => ({ value: user.id, label: user.title }));
                            })()}
                            showSearch
                            notFoundContent={(() => {
                                const selectedItems = form.getFieldValue("assignedTo");
                                const res = applicationStore.users?.filter((item) => {
                                    return item.title.includes(searchText) || selectedItems === item.id
                                }).map((user) => ({ value: user.id, label: user.title }));
                                if (res.length > 0) {
                                    return null
                                }
                                return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            })()}
                            onSelect={(value: any, option: any) => {
                                setSearchText("");
                            }}
                            onSearch={(value: string) => {
                                setSearchText(value);
                            }}
                            filterOption={(input: string, option?: { label: string; value: number }) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        />
                    </Form.Item>
                </Col>
            </Row> */}

            <Row>
                {/* <Col md={{ span: 7, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<LocalTask> label="Community Type" name="communityBoardTaskType" rules={[{ required: true }]}>
                        <Select options={applicationStore.communityBoardTaskTypes?.map((type) => ({ value: type.id, label: type.name }))} />
                    </Form.Item>
                </Col> */}

                <Col md={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<LocalTask> label="Requested By" name="requestedBy" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item<LocalTask> label="Task Description" name="taskDescription" >
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item<LocalTask> label="Priority" name="priority">
                        <Select options={applicationStore.priorities?.map((text) => ({ value: text, label: text }))} />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col md={{ span: 7, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<LocalTask> label="Task Status" name="internalTaskStatus">
                        <Select options={applicationStore.taskStatuses?.map((text) => ({ value: text, label: text }))}/>
                    </Form.Item>
                </Col>

                <Col md={{ span: 7, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<LocalTask> label="Completion Date" name="completionDate">
                        <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                    </Form.Item>
                </Col>

                <Col md={{ span: 8, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<LocalTask> label="Community Committee" name="communityCommittee">
                        <Select options={applicationStore.communityCommittees?.map((committee) => ({ value: committee.id, label: committee.name }))} />
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item<LocalTask> label="Note" name="note">
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
           
          {noteHistoryContent && (
            <Row gutter={16}>
              <Col span={24}>
                <div style={{ marginTop: '1rem' }}>
                <Form.Item<LocalTask> label="Note History" name="noteHistory">
                  <div
                    style={{
                      padding: '1rem',
                      backgroundColor: '#f5f5f5',
                      borderRadius: '5px',
                      whiteSpace: 'pre-line',
                    }}
                    dangerouslySetInnerHTML={{ __html: noteHistoryContent }}
                  />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          )}
   

            <Row>
                <Divider orientation="left" orientationMargin="0">
                    Attachments
                </Divider>
            </Row>
            <Row>
                <Col span={24}>
                    <div>
                        <Dragger fileList={fileList}
                            multiple
                            {...uploadProps}
                            itemRender={CustomUploadItem}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined style={{color: "#5a7890"}}/>
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload.
                            </p>
                        </Dragger>
                    </div>
                </Col>
            </Row>
            {lastModified && (
  <Row gutter={16}>
    <Col span={24}>
      <div style={{ marginTop: '1rem' }}>
        <p>
          Created at {moment.utc(created).format("M/D/YYYY hh:mm A")} by {author}
        </p>
        <p>
          Last Modified at {moment.utc(lastModified).format("M/D/YYYY hh:mm A")} by {editor}
        </p>
      </div>
    </Col>
  </Row>
)}
            <Row>
                <Col span={24} style={{ textAlign: 'right', marginTop: "16px" }}>
                    <Space>
                        <Button size="middle" onClick={() => navigate('/BoardVue/BoardTasks')}>
                            Cancel
                        </Button>
                        <Button size="middle" type="primary" htmlType="submit">{props.submitBtnText}</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    )
})

export default TaskForm